import {ChangeDetectionStrategy, Component, HostBinding, Input, Output, OnInit} from '@angular/core';
import {Poll, PollQuestion, PollQuestionAnswer} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-poll-outro',
	templateUrl:'poll-outro.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PollOutroComponent implements OnInit {

	@HostBinding('class') clazz = 'app-poll-outro';

	@Input() poll: Poll;
	@Input() selectedAnswers: PollQuestionAnswer[];
	@Input() seconds: number;
	@Input() alternativeQuestionIndex: number;
	@Output() tempQuestions: PollQuestion[];

	constructor() {
	}

	ngOnInit() {
		/**
		 * clone question and clean up array - resort, if alternateQuestion is Used, remove alternateQuestion
		 */

		this.tempQuestions = [];
		this.poll.questions.forEach(e => this.tempQuestions.push(Object.assign({}, e)));
		const alternativeQuestion = this.poll.questions.find(
			(q) => q.is_alternative_question
		);
		if (this.alternativeQuestionIndex !== null) {
			this.tempQuestions[this.alternativeQuestionIndex] = {...alternativeQuestion};
			this.tempQuestions[this.alternativeQuestionIndex].is_alternative_question = false;
		}
		this.tempQuestions = this.tempQuestions.filter(e => <any>e.is_alternative_question == 0 && e.question_type == 'QUESTION');
	}

	get isHitMissRatioPoll() {
		for (const question of this.tempQuestions) {
			const hasCorrectAnswer = !!question.answers.find(q => q.correct);
			if (!hasCorrectAnswer) {
				return false;
			}
		}

		return true;
	}

	get correctPercentage() {
		return Math.round((this.correctAnswers / this.totalAnswers) * 100);
	}

	get correctAnswers() {
		let count = 0;
		for (const question of this.tempQuestions) {
			if (this.wasQuestionCorrectlyAnswered(question)) {
				count++;
			}
		}

		return count;
	}

	get totalAnswers() {
		return this.tempQuestions.length;
	}

	wasQuestionCorrectlyAnswered(question: PollQuestion) {
		const correctAnswer = question.answers.find(a => a.correct);
		return this.selectedAnswers.indexOf(correctAnswer) >= 0;
	}
}
