import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {GameUtils} from '../core/game-utils';
import {switchMap} from 'rxjs/operators';
import {GameService} from '../services/game.service';
import {SnackBarService} from '../services/snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {AnimationUtils} from '../core/animation-utils';
import {Game, GameSourceType, GameStats, Prediction} from '@inscouts/playersvote-models';
import {TeamNumber} from '../models/game.model';

@Component({
	selector: 'app-mobile-prediction',
	template: `
		<app-mobile-prediction-inputs [game]="game" [disabled]="finished"
									  [small]="finished"></app-mobile-prediction-inputs>

		<div class="app-mobile-bar" *ngIf="finished">
			{{ getTopBarText() }}
		</div>

		<div class="app-mobile__container app-mobile-prediction__button-container" *ngIf="!finished">
			<button class="app-mobile-button" [class.app-mobile-button--gray]="!isPredictionComplete()" (click)="save()"
					[disabled]="saving || !isPredictionComplete()" *ngIf="!finished">
				<span *ngIf="!isPredictionComplete()"
					  class="button__text button__text--small">{{ 'mobile.prediction.missing-prediction' | translate
					}}</span>

				<ng-template [ngIf]="isPredictionComplete()">
					<span *ngIf="!saving" class="button__text">{{ 'mobile.prediction.submit' | translate }}</span>
					<span *ngIf="saving" class="button__text">{{ 'general.saving' | translate }}</span>
				</ng-template>
			</button>
		</div>

		<div *ngIf="finished && stats" [@fadeIn]="'shown'">
			<app-mobile-high-score-prediction-stats [stats]="stats"
													[@fadeIn]="'shown'"
													[isSingleTeamMode]="isSingleTeamMode"
													[singleTeamNumber]="singleTeamNumber"
													[singleTeamName]="singleTeam?.name"
													*ngIf="isGameHighScoring"></app-mobile-high-score-prediction-stats>
			<app-widget-prediction-stats [stats]="stats" [prediction]="game.prediction" [showDraw]="canGameEndInDraw"
										 *ngIf="!isGameHighScoring"></app-widget-prediction-stats>
		</div>

	`,
	animations: [
		AnimationUtils.fadeIn()
	]
})

export class MobilePredictionComponent implements OnInit {

	@HostBinding('class') clazz = 'app-mobile-prediction';

	@HostBinding('class.app-mobile-prediction--stats')
	private get isInStatsView() {
		return this.finished;
	}

	@Input() game: Game;

	saving = false;
	finished = false;
	stats: GameStats;

	constructor(private gameService: GameService, private snackBarService: SnackBarService, private translate: TranslateService) {
	}

	ngOnInit() {
		if (this.isPredictionComplete()) {
			this.finished = true;

			return this.gameService.getStats(this.game.id)
				.subscribe(stats => {
					this.stats = stats;
				});
		}
	}

	getTopBarText() {
		if (this.stats) {
			const prediction = this.game.prediction;

			const tippedTeam1WinLikeMajority = prediction.result_team_1 > prediction.result_team_2 && this.stats.tendency.team_1_win > this.stats.tendency.team_2_win;
			const tippedTeam2WinLikeMajority = prediction.result_team_2 > prediction.result_team_1 && this.stats.tendency.team_2_win > this.stats.tendency.team_1_win;
			if (tippedTeam1WinLikeMajority || tippedTeam2WinLikeMajority) {
				return this.translate.instant('mobile.prediction.vote-majority');
			} else {
				return this.translate.instant('mobile.prediction.vote-minority');
			}
		}

		return '';
	}

	get canGameEndInDraw() {
		return GameUtils.canGameEndInDraw(this.game);
	}

	get singleTeam() {
		switch (this.singleTeamNumber) {
			case TeamNumber.One:
				return this.game.team_1;
			case TeamNumber.Two:
				return this.game.team_2;
		}
	}

	get isSingleTeamMode() {
		return this.gameService.gameSource.type === GameSourceType.TEAM;
	}

	get singleTeamNumber(): TeamNumber {
		if (this.isSingleTeamMode) {
			if (this.gameService.gameSource.team_opta_id === this.game.team_1.opta_id) {
				return TeamNumber.One;
			}

			return TeamNumber.Two;
		}

		return null;
	}

	save() {
		const predictions: Prediction[] = [];

		this.game.prediction.game_id = this.game.id;
		predictions.push(this.game.prediction);

		this.saving = true;
		this.gameService.savePredictions(predictions)
			.pipe(
				switchMap(() => {
					this.finished = true;

					return this.gameService.getStats(this.game.id);
				})
			).subscribe((stats) => {
				this.saving = false;
				this.stats = stats;
			},
			() => {
				this.saving = false;
				this.finished = false;
				this.snackBarService.error(this.translate.instant('prediction.save-error'));
			});
	}

	isPredictionComplete() {
		return GameUtils.isPredictionComplete(this.game);
	}

	get isGameHighScoring() {
		return GameUtils.isGameHighScoringGame(this.game);
	}
}
