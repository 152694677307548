<ng-template [ngIf]="game && gameSource">
	<app-game-header [game]="game" [gameSource]="gameSource">
		<app-countdown [date]="game.meta_data.rating_end" *ngIf="isGameOverButRateable()"></app-countdown>
	</app-game-header>

	<app-rate-team
		*ngIf="isTeamShownForRating(game.team_1)"
		[headerText]="game.team_1.name"
		[players]="team1"
		[(rating)]="game.prediction.rating_team_1"
		[ratingDisabled]="!isGameInRatingPhase()"
		[(mvp)]="game.prediction.mvp_team_1"></app-rate-team>

	<app-rate-team
		*ngIf="isTeamShownForRating(game.team_2)"
		[headerText]="game.team_2.name"
		[players]="team2"
		[(rating)]="game.prediction.rating_team_2"
		[ratingDisabled]="!isGameInRatingPhase()"
		[(mvp)]="game.prediction.mvp_team_2"></app-rate-team>

	<button class="button" (click)="save()" [disabled]="isButtonDisabled()"
			[class.button--red]="!isGameInRatingPhase() || isGameRatingPhaseOver()">

		<ng-template [ngIf]="isGameInRatingPhase()">
			<span *ngIf="!isRatingComplete() && !saving" class="button__text button__text--small">{{ ratingTranslateKey + '.missing-rating' | translate }}</span>
			<span *ngIf="isRatingComplete() && !saving">{{'general.confirm' | translate}}</span>
			<span *ngIf="isRatingComplete() && saving">{{ 'general.saving' | translate }}</span>
		</ng-template>


		<ng-template [ngIf]="!isGameInRatingPhase()">

			<ng-template [ngIf]="isGameRatingPhaseOver()">
				<span class="button__text button__text--medium">{{ 'rating.rating-over-title' | translate }}</span>
				<span class="button__text button__text--small">{{ ratingTranslateKey + '.rating-over-text' | translate }}</span>
			</ng-template>

			<ng-template [ngIf]="!isGameRatingPhaseOver()">
				<span class="button__text button__text--small">{{ 'rating.not-in-rating-phase-title' | translate }} {{ ratingTranslateKey + '.not-in-rating-phase-text' | translate }}</span>
			</ng-template>
		</ng-template>

	</button>
</ng-template>
