<div class="rating box">
	<div class="box__overlay" *ngIf="ratingDisabled"></div>
	<div class="box__header">
		{{ headerText }}
	</div>
	<div class="box__content">
		<div class="rating__scale">
			<app-icon name="thumbs-down"></app-icon>
			<ul>
				<li class="rating__scale-item" *ngFor="let number of ratingNumbers" [class.rating__scale-item--selected]="isNumberSelected(number)" (click)="rateTeam(number)">
					{{ getDisplayTextForNumber(number) }}
				</li>
			</ul>
			<app-icon name="thumbs-up"></app-icon>
		</div>
	</div>
	<div class="rating__mvp box__content" [class.rating__mvp--open]="isListOpen">
		<ul class="rating__player-list">
			<li *ngFor="let player of players" (click)="selectPlayer(player)" [class.rating__player--selected]="isPlayerSelected(player)" class="rating__player">{{ getPlayerName(player) }}</li>
		</ul>
		<div class="rate-player-button" (click)="toggleList()">
			<span *ngIf="!isListOpen">{{ (getPlayerName(mvp) || 'rating.rate-player-title' | translate) }}</span>
			<app-icon name="arrow-down"></app-icon>
		</div>
	</div>
</div>
