import {Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChange} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {Group, GroupMemberPublic} from '@inscouts/playersvote-models';
import { State } from '../core/state';
import {LeaderboardService} from '../services/leaderboard.service';
import { SnackBarService } from '../services/snackbar.service';

@Component({
	selector: 'leaderboard-group',
	template: `
		<div class="leaderboard-group__wrap">
			<div *ngIf="!group" class="leaderboard-group__controls">
				<div *ngIf="groups && groups.length" class="leaderboard-group__switch-group">
					{{ 'leaderboards.switch-group' | translate }}
					<select (change)="changeGroup($event.target.value)" class="leaderboard-group__select leaderboard-group__group-select">
						<option></option>
						<option *ngFor="let groupItem of groups"
							[value]="groupItem.key"
							[selected]="group && groupItem.key === group.key"
						>
							{{ groupItem.name }}
						</option>
					</select>
				</div>
				<button
					(click)="createGroup()"
					class="leaderboard-group__button leaderboard-group__button--create"
				>
					{{ 'leaderboards.new-group-btn' | translate }}
				</button>
			</div>

			<div *ngIf="group" class="leaderboard-group__details">
				<h1 class="leaderboard-group__name">{{ 'leaderboards.group-name' | translate }}: {{ group.name }}</h1>
				<div class="leaderboard-group__actions">
					<button
						(click)="changeGroup(null)"
						class="leaderboard-group__button leaderboard-group__button--back"
					>
						{{ 'leaderboards.show-overall' | translate }}
					</button>
					<button *ngIf="group.join"
						(click)="shareGroup()"
						class="leaderboard-group__button leaderboard-group__button--share"
					>
						{{ 'leaderboards.group-share' | translate }}
					</button>
					<button *ngIf="canJoin"
						(click)="joinGroup()"
						class="leaderboard-group__button leaderboard-group__button--join"
					>
						{{ 'leaderboards.group-join' | translate}}
					</button>
					<button *ngIf="canLeave"
						(click)="leaveGroup()"
						class="leaderboard-group__button leaderboard-group__button--leave"
					>
						{{ 'leaderboards.group-leave' | translate}}
					</button>
				</div>
				<div *ngIf="isAdmin" class="leaderboard-group__admin">
					<details class="leaderboard-group__admin-group">
						<summary class="leaderboard-group__admin-group-title">
							{{ 'leaderboards.group-settings' | translate }}
						</summary>
						<form (submit)="updateGroup()" #groupForm="ngForm">
							<label class="leaderboard-group__input-group">
								{{ 'leaderboards.group-name-label' | translate }}
								<input type="text" required [(ngModel)]="editGroup.name" name="name" class="leaderboard-group__input">
							</label>
							<label class="leaderboard-group__input-group">
								<input type="checkbox" [(ngModel)]="editGroup.join" name="join" class="leaderboard-group__checkbox">
								{{ 'leaderboards.group-join-label' | translate }}
							</label>
							<button type="submit" class="leaderboard-group__button leaderboard-group__button--save">
								{{ 'leaderboards.save' | translate }}
							</button>
							<button (click)="deleteGroup()"  class="leaderboard-group__button leaderboard-group__button--danger leaderboard-group__button--delete">
								{{ 'leaderboards.group-delete' | translate }}
							</button>
						</form>
					</details>
					<details (toggle)="loadMembers()" class="leaderboard-group__admin-group">
						<summary class="leaderboard-group__admin-group-title">
							{{ 'leaderboards.group-members' | translate }}
						</summary>
						<mat-spinner *ngIf="members.length === 0" class="leaderboard-group__spinner"></mat-spinner>
						<ul *ngIf="members.length > 0" class="leaderboard-group__member-list">
							<li *ngFor="let member of members" class="leaderboard-group__member">
								<span class="leaderboard-group__member-name">
									{{ member.name }}
								</span>
								<span *ngIf="member.role === 'admin'"
									class="leaderboard-group__member-role leaderboard-group__member-role--admin"
								>
									{{ 'leaderboards.group-admin' | translate }}
								</span>
								<button
									(click)="removeMember(member)"
									[disabled]="member.id === state.externalUserId"
									class="leaderboard-group__button leaderboard-group__button--danger leaderboard-group__button--remove-member"
								>
									{{ 'leaderboards.group-remove-member' | translate }}
								</button>
							</li>
						</ul>
					</details>
				</div>
			</div>
		</div>
	`
})

export class LeaderboardGroupComponent implements OnChanges {

	@HostBinding('class') clazz = 'leaderboard-group';
	@Input() group: Group;
	@Input() groups: Group[];

	@Output() groupJoined = new EventEmitter<Group>();
	@Output() groupLeft = new EventEmitter<Group>();
	@Output() setGroup = new EventEmitter<Group>();
	@Output() removedMember = new EventEmitter<number>();

	isAuthenticated: boolean;
	isAdmin: boolean;
	canJoin: boolean;
	canLeave: boolean;
	editGroup: Group;
	groupName: string;
	groupJoin: boolean;
	members: GroupMemberPublic[] = [];


	constructor(
		public state: State,
		private translate: TranslateService,
		private leaderboardService: LeaderboardService,
		private snackbar: SnackBarService,
	) {
		this.isAuthenticated = !state.externalAuthRequired;
	}

	ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
		if('group' in changes || 'groups' in changes) {
			const group = changes.group
				? changes.group.currentValue
				: this.group;
			// if current group is open and not in list of groups, user is allowed to join
			if(group) {
				const isMember = this.groups && this.groups.some(({key}) => key === group.key);
				this.canJoin = group.join && !isMember;
				this.isAdmin = !!group.admins && group.admins.some(adminUserId => this.state.externalUserId === adminUserId)
				this.canLeave = isMember && !this.isAdmin;

				this.editGroup = {
					name: group.name,
					join: group.join,
				}
			}
		}
	}

	createGroup() {
		if(this.isAuthenticated) {
			const name = window.prompt(this.translate.instant('leaderboards.group-create-name'));
			if(name) {
				this.leaderboardService.createCustomGroup(name)
					.subscribe(
						group => {
							this.groupJoined.emit(group);
						}, () => {
							this.snackbar.error(this.translate.instant('leaderboards.group-create-error'));
						}
					)
			}
		} else {
			if(window.confirm(this.translate.instant('unauthenticated.login-required'))) {
				window.parent.postMessage({
					type: 'trigger-auth',
				}, '*');
			}
		}
	}

	joinGroup() {
		if(this.isAuthenticated) {
			this.leaderboardService.updateGroupMembership(this.group.key, {add: [this.state.externalUserId]})
				.subscribe(
					() => {
						this.groupJoined.emit(this.group);
					}, () => {
						this.snackbar.error(this.translate.instant('leaderboards.group-membership-error'));
					}
				)
		} else {
			if(window.confirm(this.translate.instant('leaderboards.group-need-to-authenticate'))) {
				window.parent.postMessage({
					type: 'trigger-auth',
				}, '*');
			}
		}
	}

	leaveGroup() {
		this.leaderboardService.updateGroupMembership(this.group.key, {remove: [this.state.externalUserId]})
			.subscribe(
				() => {
					this.groupLeft.emit(this.group);
				}, () => {
					this.snackbar.error(this.translate.instant('leaderboards.group-membership-error'));
				}
			)
	}

	updateGroup() {
		this.leaderboardService.updateCustomGroup(this.group.key, this.editGroup)
			.subscribe(
				group => {
					Object.assign(this.group, group);
				}, () => {
					this.snackbar.error(this.translate.instant('leaderboards.group-update-error'));
				}
			)
	}

	deleteGroup() {
		const code = 'g00dby3'
		if(window.prompt(this.translate.instant('leaderboards.group-delete-confirm', {code})) === code) {
			this.leaderboardService.deleteCustomGroup(this.group.key)
				.subscribe(
					() => {
						this.groupLeft.emit(this.group);
						this.snackbar.success(this.translate.instant('leaderboards.group-delete-success'));
					}, () => {
						this.snackbar.error(this.translate.instant('leaderboards.group-delete-error'));
					}
				)
		} else {
			this.snackbar.error(this.translate.instant('leaderboards.group-delete-wrong-code'));
		}
	}

	loadMembers() {
		if(this.members.length === 0) {
			this.leaderboardService.getGroupMembers(this.group.key)
			.subscribe(
				(members) => {
					this.members = members;
				}, () => {
					this.snackbar.error(this.translate.instant('leaderboards.group-members-load-error'));
				}
			)
		}
	}

	removeMember(member) {
		if(
			member.id !== this.state.externalUserId &&
			window.confirm(this.translate.instant('leaderboards.group-member-remove-confirm', {name: member.name}))
		) {
			this.leaderboardService.updateGroupMembership(this.group.key, {remove: [member.id]})
				.subscribe(
					() => {
						this.members = this.members.filter(({id}) => id !== member.id);
						this.groupLeft.emit(this.group);
					}, () => {
						this.snackbar.error(this.translate.instant('leaderboards.group-membership-error'));
					}
				)
		}
	}

	changeGroup(groupKey: string) {
		let group = null;
		if(groupKey !== null) {
			group = this.groups.find(({key}) => key === groupKey);
		}
		this.setGroup.emit(group);
	}

	shareGroup() {
		if (!window.parent || window == window.parent) {
			// not running in IFrame
			const url = window.location.href.replace(
				/\/leaderboard\/.*\?/,
				`/leaderboard/${this.group.key}?`
			)

			const share = (navigator as any).share;
			if (share && typeof share == 'function') {
				share({
					title: 'Leaderboard ' + this.group.name,
					text: 'Join me in our leaderboard ' + this.group.name,
					url: url
				}).then(
					() => console.log(`Shared: ${url}`),
					(err) => console.error(err)
				);
			} else {
				const clipboard = (navigator as any).clipboard
				if (clipboard && typeof clipboard.writeText == 'function') {
					const perms = (navigator as any).permissions
					if (perms && typeof perms.query == 'function')
						perms.query({name: "clipboard-write"}).then(result => {
							if (result.state == "granted" || result.state == "prompt") {
								/* write to the clipboard now */
								clipboard.writeText(url)
									.then(
										() => console.log(`Wrote URL to clipboard: ${url}`),
										(err) => console.error(err)
									)
								;
							}
						});
				}
			}
		} else {
			window.parent.postMessage({
				type: 'trigger-share',
				payload: {
					groupId: this.group.key,
				}
			}, '*');
		}
	}
}
