import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';

import {PredictionOrigin} from '@inscouts/playersvote-models';

@Injectable()
export class State {
	apiKey: string;
	origin: PredictionOrigin;
	token$ = new ReplaySubject<string>(1);
	authError$ = new BehaviorSubject(false);
	accountId: string;

	// External user authentication
	externalAuthRequired: boolean;
	externalUserId: string;
	externalVerifyToken: string;
}
