import {Injectable} from '@angular/core';
import {ApiService, AuthData} from './api.service';
import {State} from '../core/state';

@Injectable()
export class AuthService {

	constructor(private apiService: ApiService, private state: State) {
	}

	initToken(apiKey: string) {
		if (!apiKey) {
			return;
		}

		let tokenType = TokenType.Anonymous;
		const data: AuthData = {
			api_key: apiKey
		};

		if (this.state.externalUserId && this.state.externalVerifyToken) {
			tokenType = TokenType.External;
			data.external_user_id = this.state.externalUserId;
			data.external_verify_token = this.state.externalVerifyToken;
		}

		const updated = this.updateTokenInStateIfAvailable(tokenType);
		if (updated) {
			return;
		}

		this.apiService.postUnauthenticated<any>('/bot/auth', data)
			.subscribe(tokenData => {
				const updatedAgain = this.updateTokenInStateIfAvailable(tokenType);

				if (!updatedAgain) {
					this.clearToken();
					this.setToken(tokenData.token, tokenType, tokenData.account_id);
				}
				this.state.authError$.next(false);
			}, (err) => {
				console.error(err);
				this.state.authError$.next(true);
			});
	}

	sendAuthTriggerMessage() {
		window.parent.postMessage({
			type: 'trigger-auth'
		}, '*');
	}

	private updateTokenInStateIfAvailable(tokenType: TokenType) {
		const persistedTokenType = this.getItem(StorageKey.TokenType);
		const token = this.getItem(StorageKey.Token);
		const accountId = this.getItem(StorageKey.AccountId);
		const persistedExternalUser = this.getItem(StorageKey.ExternalUser);
		if (persistedTokenType === tokenType
			&& token && accountId
			&& persistedExternalUser === this.getExternalUser()) {
			this.state.token$.next(token);
			this.state.accountId = accountId;
			return true;
		}

		return false;
	}

	private clearToken() {
		this.removeItem(StorageKey.Token);
		this.removeItem(StorageKey.TokenType);
		this.removeItem(StorageKey.AccountId);
	}

	private setToken(token: string, type: TokenType, accountId: string) {
		this.state.accountId = accountId;
		this.state.token$.next(token);
		this.setItem(StorageKey.AccountId, accountId);
		this.setItem(StorageKey.Token, token);
		this.setItem(StorageKey.TokenType, type);
		this.setItem(StorageKey.ExternalUser, this.getExternalUser());
	}

	private setItem(key: StorageKey, data: string) {
		return localStorage.setItem(this.getStorageKey(key), data);
	}

	private getItem<D extends string>(key: StorageKey): D {
		const item = localStorage.getItem(this.getStorageKey(key)) as D;
		if (item === 'undefined' || item === 'null') {
			return null;
		}
		return item;
	}

	private removeItem(key: StorageKey) {
		return localStorage.removeItem(this.getStorageKey(key));
	}

	private getStorageKey(key: StorageKey) {
		return `${this.state.apiKey}_${key}`;
	}

	private getExternalUser() {
		if (this.state.externalVerifyToken && this.state.externalUserId) {
			return `${this.state.externalUserId}:${this.state.externalVerifyToken}`;
		}
		return null;
	}
}

enum StorageKey {
	Token = 'token',
	TokenType = 'tokenType',
	AccountId = 'accountId',
	ExternalUser = 'externalUser'
}

enum TokenType {
	Anonymous = 'anonymous',
	External = 'external'
}
