import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {Router} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TranslateCompiler, TranslateModule} from '@ngx-translate/core';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';

import * as Sentry from '@sentry/angular-ivy';

import {AppRoutingModule} from './app-routing.module';
import {MaterialModules} from './core/material.module';

import {ApiService} from './services/api.service';
import {GameService} from './services/game.service';
import {SnackBarService} from './services/snackbar.service';

import {AppComponent} from './app.component';
import {RatingComponent} from './webviews/rating.component';
import {RoundPredictionComponent} from './webviews/round-prediction.component';
import {VotingComponent} from './webviews/voting.component';
import {PredictionComponent} from './webviews/prediction.component';
import {GameHeaderComponent} from './webviews/game-header.component';
import {IconComponent} from './core/components/icon.component';
import {RoundNavComponent} from './webviews/round-nav.component';
import {WebviewsOverlayComponent} from './webviews/webviews-overlay.component';
import {GameUtils} from './core/game-utils';
import {RateTeamComponent} from './webviews/rate-team.component';
import {PredictTeamComponent} from './webviews/predict-team.component';
import {ModalService} from './services/modal.service';
import {CountdownComponent} from './core/components/countdown.component';
import {SponsorComponent} from './core/components/sponsor.component';
import {HeaderComponent} from './core/components/header.component';
import {GamePollComponent} from './game-polls/game-poll.component';
import {GamePollService} from './services/game-poll.service';
import {GamePollQuestionComponent} from './game-polls/game-poll-question.component';
import {PollUtils} from './core/poll-utils';
import {WidgetComponent} from './widget/widget.component';
import {WebviewsComponent} from './webviews/webviews.component';
import {WidgetPredictionComponent} from './widget/widget-prediction.component';
import {WidgetPredictionStatsComponent} from './widget/widget-prediction-stats.component';
import {BarComponent} from './core/components/bar.component';
import {WidgetRatingComponent} from './widget/widget-rating.component';
import {WidgetRateTeamComponent} from './widget/widget-rate-team.component';
import {AuthService} from './services/auth.service';
import {HttpClientModule} from '@angular/common/http';
import {State} from './core/state';
import {WidgetPostComponent} from './widget/widget-post.component';
import {WidgetRatingControlsComponent} from './widget/widget-rating-controls.component';
import {MobileWidgetComponent} from './mobile-widget/mobile-widget.component';
import {MobileHeaderComponent} from './mobile-widget/mobile-header.component';
import {MobilePredictionComponent} from './mobile-widget/mobile-prediction.component';
import {ResultInputComponent} from './core/components/result-input.component';
import {MobilePredictionInputsComponent} from './mobile-widget/mobile-prediction-inputs.component';
import {MobileHighScorePredictionStatsComponent} from './mobile-widget/mobile-high-score-prediction-stats.component';
import {DialComponent} from './core/components/dial.component';
import {MultiDialComponent} from './core/components/multi-dial.component';
import {MobileRatingComponent} from './mobile-widget/mobile-rating.component';
import {MobileRateTeamComponent} from './mobile-widget/mobile-rate-team.component';
import {RatingScaleComponent} from './core/components/rating-scale.component';
import {MobilePostComponent} from './mobile-widget/mobile-post.component';
import {FakeGameService} from './services/fake-game.service';
import {AnalyticsService} from './services/analytics.service';
import {PollService} from './services/poll.service';
import {FakePollService} from './services/fake-poll.service';
import {PollComponent} from './polls/poll.component';
import {PollRootComponent} from './polls/poll-root.component';
import {PollQuestionComponent} from './polls/poll-question.component';
import {PollBarComponent} from './polls/poll-bar.component';
import {SafePipe} from './core/safe.pipe';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {PollFooterComponent} from './polls/poll-footer.component';

import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeKo from '@angular/common/locales/ko';
import {registerLocaleData} from '@angular/common';

import {RatingLegendComponent} from './core/components/rating-legend.component';

import {PollEmailFormComponent} from './polls/poll-email-form.component';
import {PollAdditionalInfoFormComponent} from "./polls/poll-additional-info-form.component";
import {PollOutroComponent} from './polls/poll-outro.component';
import {PollBadgeComponent} from './polls/poll-badge.component';

import {UnauthenticatedComponent} from './core/components/unauthenticated.component';

import {LeaderboardService} from './services/leaderboard.service'
import {LeaderboardRootComponent} from './leaderboards/leaderboard-root.component';
import {LeaderboardGroupComponent} from './leaderboards/leaderboard-group.component';
import {LeaderboardTableComponent} from './leaderboards/leaderboard-table.component';
import {BattleService} from './services/battle.service'
import {FakeBattleService} from './services/fake-battle.service'
import {BattleRootComponent} from './battles/battle-root.component';
import {BattleRailComponent} from './battles/battle-rail.component';
import {BattlePollComponent} from './battles/battle-poll.component';
import {BattleOverlayComponent} from './battles/battle-overlay.component';

registerLocaleData(localeDe);
registerLocaleData(localeEl);
registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeKo);

@NgModule({
	declarations: [
		AppComponent,
		RatingComponent,
		PredictionComponent,
		RoundPredictionComponent,
		VotingComponent,
		GameHeaderComponent,
		IconComponent,
		RoundNavComponent,
		WebviewsOverlayComponent,
		RateTeamComponent,
		PredictTeamComponent,
		CountdownComponent,
		SponsorComponent,
		HeaderComponent,
		GamePollComponent,
		GamePollQuestionComponent,
		WidgetComponent,
		WebviewsComponent,
		WidgetPredictionComponent,
		WidgetPredictionStatsComponent,
		DialComponent,
		MultiDialComponent,
		BarComponent,
		WidgetRatingComponent,
		WidgetRateTeamComponent,
		WidgetPostComponent,
		WidgetRatingControlsComponent,

		// Core
		ResultInputComponent,
		RatingScaleComponent,
		SafePipe,
		RatingLegendComponent,
		UnauthenticatedComponent,

		// Mobile
		MobileWidgetComponent,
		MobileHeaderComponent,
		MobilePredictionComponent,
		MobilePredictionInputsComponent,
		MobileHighScorePredictionStatsComponent,
		MobileRatingComponent,
		MobileRateTeamComponent,
		MobilePostComponent,

		// Polls
		PollRootComponent,
		PollComponent,
		PollQuestionComponent,
		PollBarComponent,
		PollFooterComponent,
		PollEmailFormComponent,
		PollAdditionalInfoFormComponent,
		PollOutroComponent,
		PollBadgeComponent,

		// Leaderboards
		LeaderboardRootComponent,
		LeaderboardGroupComponent,
		LeaderboardTableComponent,

		// Battles
		BattleRootComponent,
		BattleRailComponent,
		BattlePollComponent,
		BattleOverlayComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MaterialModules,
		TranslateModule.forRoot({
			compiler: {
				provide: TranslateCompiler,
				useClass: TranslateMessageFormatCompiler
			}
		}),
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler(),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{provide: LOCALE_ID, useValue: 'de-AT'},
		ApiService,
		{
			provide: GameService,
			useFactory: (apiService, state) => {
				const urlParams = new URLSearchParams(window.location.search);
				const isDemo = urlParams.get('demo');
				if (isDemo) {
					return new FakeGameService();
				}
				return new GameService(apiService, state);
			},
			deps: [ApiService, State]
		},
		{
			provide: PollService,
			useFactory: (apiService) => {
				const urlParams = new URLSearchParams(window.location.search);
				const isDemo = urlParams.get('demo');
				if (isDemo) {
					return new FakePollService();
				}
				return new PollService(apiService);
			},
			deps: [ApiService]
		},
		{
			provide: LeaderboardService,
			useFactory: (apiService) => new LeaderboardService(apiService),
			deps: [ApiService]
		},
		{
			provide: BattleService,
			useFactory: (apiService) => {
				const urlParams = new URLSearchParams(window.location.search);
				const isDemo = urlParams.get('demo');
				if (isDemo) {
					return new FakeBattleService();
				}
				return new BattleService(apiService)
			},
			deps: [ApiService]
		},
		SnackBarService,
		ModalService,
		GamePollService,
		AnalyticsService,
		GameUtils,
		PollUtils,
		AuthService,
		State,
		{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline', floatLabel: 'always'}},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
