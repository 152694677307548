import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ResultInputComponent} from '../core/components/result-input.component';

@Component({
	selector: 'app-predict-team',
	template: `
		<header class="box__header">{{ headerText ||  'general.team-tba' | translate }}</header>
		<div class="box__content" *ngIf="showInputs">
			<app-result-input [disabled]="disabled" [(result)]="result" (resultChange)="resultChanged($event)" #resultInput></app-result-input>
		</div>
	`,
	host: {
		'class': 'prediction-side box'
	}
})

export class PredictTeamComponent implements OnInit {

	@Input() headerText: string;
	@Input() result: number;
	@Input() disabled = false;
	@Input() showInputs = true;
	@Output() resultChange = new EventEmitter<number>();

	@ViewChild('resultInput') input: ResultInputComponent;


	constructor() {
	}

	ngOnInit() {
	}

	resultChanged(number: number) {
		this.result = number;
		this.resultChange.emit(this.result);
	}

	focusInput() {
		if (this.input) {
			this.input.focusInput();
		}
	}
}
