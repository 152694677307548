import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {UserRank, Group, GroupMemberPublic} from '@inscouts/playersvote-models';
import {ApiService, Paging} from './api.service';

@Injectable()
export class LeaderboardService {

	constructor(private apiService: ApiService) {
	}

	getUserRanks(leaderboardId: string): Observable<UserRank[]> {
		return this.getGroupRankings(leaderboardId);
	}

	getCustomGroups(params?: Paging): Observable<Group[]> {
		return this.apiService.get('/bot/groups', params);
	}

	getCustomGroup(key: string): Observable<Group> {
		return this.apiService.get(`/bot/groups/${key}`);
	}

	createCustomGroup(name: string): Observable<Group> {
		return this.apiService.post('/bot/groups', {name});
	}

	updateCustomGroup(key: string, data: Group): Observable<Group> {
		return this.apiService.patch(`/bot/groups/${key}`, data);
	}

	deleteCustomGroup(key: string): Observable<void> {
		return this.apiService.delete(`/bot/groups/${key}`);
	}

	getGroupMembers(key: string, params?: Paging): Observable<GroupMemberPublic[]> {
		return this.apiService.get(`/bot/groups/${key}/members`, params);
	}

	updateGroupMembership(key: string, data: {add?: string[], remove?: string[]}): Observable<Group> {
		return this.apiService.put(`/bot/groups/${key}/members`, data);
	}

	getGroupRankings(key: string, params?: Paging): Observable<UserRank[]> {
		return this.apiService.get(`/bot/groups/${key}/ranking`, params);
	}

}
