import {Injectable} from '@angular/core';

import {GameSource, PlayersvoteAnalytics, PredictionOrigin} from '@inscouts/playersvote-models';
import * as debugFunc from 'debug';

import {environment} from '../../environments/environment';
import {State} from '../core/state';

declare const ga: any;

@Injectable()
export class AnalyticsService {

	private static readonly debug = debugFunc('pv:analytics');

	static appendGaTrackingCode() {
		try {
			const script = document.createElement('script');
			script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '` + environment.googleAnalyticsTrackingId + `', 'auto');
        if (navigator.sendBeacon) {
            ga('set', 'transport', 'beacon');
        }
      `;
			document.head.appendChild(script);
		} catch (ex) {
			console.error('Error appending google analytics');
			console.error(ex);
		}
	}

	constructor(private state: State) {

	}

	trackPollPageView(pollId: number) {
		const category = PlayersvoteAnalytics.createPollInteractionCategoryString(this.state.accountId as any, PredictionOrigin.WIDGET);
		return this.trackEvent(category, PlayersvoteAnalytics.Action.View, pollId + '');
	}

	trackPollInteraction(pollId: number) {
		const category = PlayersvoteAnalytics.createPollInteractionCategoryString(this.state.accountId as any, PredictionOrigin.WIDGET);
		return this.trackEvent(category, PlayersvoteAnalytics.Action.Click, pollId + '');
	}

	trackGamePageView(source: GameSource, gameId: number, origin: PredictionOrigin) {
		const category = PlayersvoteAnalytics.createGameInteractionCategoryString(source.account_id, origin);
		const label = PlayersvoteAnalytics.createGameEventLabel(source.league, source, gameId);
		console.log('Game View', category, label);
		return this.trackEvent(category, PlayersvoteAnalytics.Action.View, label);
	}

	trackSponsorDelivery(sponsorId: number, origin: PredictionOrigin) {
		const category = PlayersvoteAnalytics.createSponsorInteractionCategoryString(this.state.accountId as any, origin);
		return this.trackEvent(category, PlayersvoteAnalytics.Action.Deliver, sponsorId + '', 1);
	}

	trackSponsorClick(sponsorId: number, origin: PredictionOrigin) {
		const category = PlayersvoteAnalytics.createSponsorInteractionCategoryString(this.state.accountId as any, origin);
		return this.trackEvent(category, PlayersvoteAnalytics.Action.Click, sponsorId + '', 1);
	}

	trackSponsorView(sponsorId: number, origin: PredictionOrigin) {
		const category = PlayersvoteAnalytics.createSponsorInteractionCategoryString(this.state.accountId as any, origin);
		return this.trackEvent(category, PlayersvoteAnalytics.Action.View, sponsorId + '', 1);
	}

	trackGamesPageViews(source: GameSource, gameIds: number[], origin: PredictionOrigin) {
		gameIds.forEach((gameId) => {
			this.trackGamePageView(source, gameId, origin);
		});
	}

	private trackEvent(eventCategory: string, eventAction: string, eventLabel: string, eventValue?: number) {
		const data = {eventCategory, eventLabel, eventAction, eventValue};
		AnalyticsService.debug('Tracking Analytics Event', data);
		ga('send', 'event', data);
	}

}
