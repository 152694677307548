import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {GameUtils} from '../game-utils';
import {AnimationUtils} from '../animation-utils';
import {PlayersvoteGames, RatingValue} from '@inscouts/playersvote-models';
import {GameService} from '../../services/game.service';

@Component({
	selector: 'app-rating-scale',
	template: `
		<ul>
			<li class="app-rating-scale-item" *ngFor="let number of ratingNumbers"
				[class.app-rating-scale-item--selected]="isNumberSelected(number)" (click)="rate(number)">
				<span class="app-rating-scale-item-text">{{ getDisplayTextForNumber(number) }}</span>

				<app-bar *ngIf="finished && barValues" [percentValue]="getValueForNumber(number)" [maxHeight]="50" [@fadeIn]="animationState"></app-bar>
			</li>
		</ul>
	`,
	animations: [
		AnimationUtils.fadeIn()
	]
})
export class RatingScaleComponent implements OnInit {

	@HostBinding('class') clazz = 'app-rating-scale';
	@HostBinding('class.app-rating-scale--finished')
	private get isFinished() {
		return this.finished && this.barValues;
	}

	@Input() finished = false;
	@Input() barValues: RatingValue[];
	@Input() rating: number;
	@Output() ratingChange = new EventEmitter<number>();

	ratingNumbers;

	constructor(private gameService: GameService) {
		this.ratingNumbers = GameUtils.RATING_NUMBERS;
	}

	ngOnInit() {
	}

	getDisplayTextForNumber(number: number) {
		return PlayersvoteGames.getDisplayTextForRatingNumber(number, this.gameService.gameSource);
	}

	get animationState() {
		return this.isFinished ? 'shown' : 'hidden';
	}

	isNumberSelected(number: number) {
		return this.rating === number;
	}

	rate(number: number) {
		if (!this.finished) {
			this.rating = number;
			this.ratingChange.emit(this.rating);
		}
	}

	getValueForNumber(number: number) {
		return GameUtils.getValueForRatingNumber(number, this.barValues);
	}
}
