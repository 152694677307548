import {Component, HostBinding} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';

import 'iframe-resizer/js/iframeResizer.contentWindow';
import {filter, map, take} from 'rxjs/operators';

import {State} from './core/state';

import de from './i18n/de.json';
import en from './i18n/en.json';
import es from './i18n/es.json';
import fr from './i18n/fr.json';
import ko from './i18n/ko.json';
import el from './i18n/el.json';

import {AnalyticsService} from './services/analytics.service';
import {ApiService} from './services/api.service';
import {AuthService} from './services/auth.service';
import {GameService} from './services/game.service';

@Component({
	selector: 'app-root',
	template: `
		<router-outlet></router-outlet>
	`
})
export class AppComponent {

	@HostBinding('class.standalone')
	readonly standalone: boolean;



	constructor(private translate: TranslateService,
				private apiService: ApiService,
				private gameService: GameService,
				private router: Router,
				private state: State,
				private authService: AuthService,
				private activatedRoute: ActivatedRoute) {
		const urlParams = new URLSearchParams(window.location.search);
		this.standalone = window.parent == null || window.parent == window;

		const locale = urlParams.get('locale');
		const stage = urlParams.get('stage');
		const apiKey = urlParams.get('apiKey');
		const series = urlParams.get('series');
		const source = urlParams.get('source');
		const style = urlParams.get('style');

		this.externalAuth(urlParams);

		AnalyticsService.appendGaTrackingCode();


		translate.setDefaultLang('en');

		translate.setTranslation('en', en);
		translate.setTranslation('de', de);
		translate.setTranslation('es', es);
		translate.setTranslation('fr', fr);
		translate.setTranslation('ko', ko);
		translate.setTranslation('el', el);
		translate.use(locale ? locale : this.translate.getBrowserLang());

		this.state.apiKey = apiKey;

		if (stage) {
			gameService.stage = stage;
		}
		if (series) {
			gameService.series = series;
		}

		if (source) {
			this.gameService.loadSourceInfo(source);
		}

		if(style) {
			const frame = document.createElement('link');
			frame.setAttribute('href', style);
			frame.setAttribute('rel', 'stylesheet');
			document.querySelector('head').appendChild(frame);
		}

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map((route) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				filter((route) => route.outlet === 'primary')
			)
			.subscribe((route) => {
				route.params
					.pipe(take(1))
					.subscribe((params: Params) => {
						if (params.source) {
							this.gameService.loadSourceInfo(params.source);
						}
					});
			});
	}

	private externalAuth(urlParams: URLSearchParams) {
		const userId = urlParams.get('userId');
		if (userId === 'required') {
			this.state.externalAuthRequired = true;
		} else {
			this.state.externalUserId = userId;
			this.state.externalVerifyToken = urlParams.get('verify');
		}
	}
}
