import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-countdown',
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'class': 'app-countdown'
	},
	template: `
		<div class="app-countdown__section" *ngFor="let section of sections">
			<span class="app-countdown__section-title">{{ section.titleKey | translate }}</span>
			<span class="app-countdown__section-number"><ng-template
				[ngIf]="section.value < 10">0</ng-template>{{ section.value }}</span>
		</div>
	`,

})

export class CountdownComponent implements OnInit {

	@Input() date: string;
	@Input() lowTimeThreshold: number = 1000 * 60 * 15; // 15 minutes

	@HostBinding('class.app-countdown--low-time') get isLowTimeLeft() {
		const date = new Date(this.date);
		const now = new Date().getTime();
		const distance = date.getTime() - now;
		return distance < this.lowTimeThreshold;
	}

	constructor(private changeDetectorRef: ChangeDetectorRef) {
	}

	ngOnInit() {
		setInterval(() => {
			this.changeDetectorRef.detectChanges();
		}, 1000);
	}

	get sections(): CountdownSection[] {
		const date = new Date(this.date);
		const now = new Date().getTime();
		const distance = date.getTime() - now;

		const values = this.getCountdownValues(distance);

		return [
			{titleKey: 'countdown.days', value: values.days},
			{titleKey: 'countdown.hours', value: values.hours},
			{titleKey: 'countdown.minutes', value: values.minutes},
			{titleKey: 'countdown.seconds', value: values.seconds},
		];
	}

	private getCountdownValues(distance: number) {
		if (distance <= 0) {
			return {days: 0, hours: 0, minutes: 0, seconds: 0};
		}

		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);

		return {days, hours, minutes, seconds};
	}
}

interface CountdownSection {
	titleKey: string;
	value: number;
}
