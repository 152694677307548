import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

import {Game, GameSource, GameSourceType, Prediction, PredictionOrigin} from '@inscouts/playersvote-models';

import {GameService} from '../services/game.service';
import {SnackBarService} from '../services/snackbar.service';
import {WebviewsOverlayComponent, OverlayType} from './webviews-overlay.component';
import {GameUtils} from '../core/game-utils';
import {ModalService} from '../services/modal.service';
import {StyleUtils} from '../core/style.utils';
import {AnalyticsService} from '../services/analytics.service';
import {ClientGameStatus} from '../../../core/core';

@Component({
	selector: 'app-round-prediction',
	template: `
		<div class="main-container">
			<ng-template [ngIf]="games?.length > 0 && (gameSource$ | async)">
				<app-game-header [game]="currentGame" [gameSource]="gameSource$ | async">
					<app-countdown [date]="firstGame?.start"></app-countdown>
				</app-game-header>
				<app-prediction [game]="currentGame"></app-prediction>
				<button class="button" [class.button--gray]="!isPredictionComplete()" (click)="next()" [disabled]="saving">
					<span *ngIf="!isPredictionComplete()" class="button__text button__text--small">{{ predictionTranslateKey + '.missing-prediction' | translate }}</span>

					<ng-template [ngIf]="isPredictionComplete()">
						<span *ngIf="!isLastGameSelected() && !saving" class="button__text">{{ 'general.next' | translate }}</span>
						<span *ngIf="isLastGameSelected() && !saving" class="button__text">{{ predictionTranslateKey + '.finish' | translate }}</span>
						<span *ngIf="saving" class="button__text">{{ 'general.saving' | translate }}</span>
					</ng-template>
				</button>
			</ng-template>
		</div>
		<app-sponsor [sponsor]="currentGame?.meta_data?.sponsor" *ngIf="currentGame?.meta_data?.sponsor"></app-sponsor>
	`
})
export class RoundPredictionComponent implements OnInit {

	games: Game[];
	currentGameIndex = 0;
	saving = false;

	gameSource$ = new ReplaySubject<GameSource>();
	predictionTranslateKey = 'prediction';
	gameSourceInfo: GameSource;

	@HostBinding('class') clazz = 'main-component';

	constructor(private gameService: GameService,
				private snackBarService: SnackBarService,
				private analyticsService: AnalyticsService,
				private translate: TranslateService,
				private route: ActivatedRoute,
				private gameUtils: GameUtils,
				private modalService: ModalService) {
		this.route.params.subscribe((params: Params) => {
			this.gameService.loadSourceInfo(params.source);
		});

		this.gameSource$ = this.gameService.gameSource$;
	}

	ngOnInit() {
		this.gameService.gameSource$.pipe(
			take(1))
			.subscribe((source: GameSource) => {
				StyleUtils.styleForSource(source.style);

				this.gameSourceInfo = source;
				if (source.type === GameSourceType.TEAM) {
					this.predictionTranslateKey = 'prediction_singular';
				}

				this.gameService.getUpcomingGames(source)
					.subscribe(
						games => {
							this.games = games;
							this.analyticsService.trackGamesPageViews(source, games.map(g => g.id), PredictionOrigin.BOT);
							if (!this.isRoundPredictable()) {
								this.showPredictionOverOverlay();
							}
						},
						() => {
							this.snackBarService.error(this.translate.instant(`${this.predictionTranslateKey}.error`));
						});
			});
	}

	get firstGame() {
		if (this.games) {
			return this.games[0];
		}
	}

	isRoundPredictable(): boolean {
		if (!this.games) {
			return false;
		}

		return this.games.reduce((last: boolean, game: Game) => {
			return last && (GameUtils.getGameStatus(game) === ClientGameStatus.Prediction);
		}, true);
	}

	showPredictionOverOverlay() {
		this.gameService.gameSource$.pipe(
			take(1))
			.subscribe((source: GameSource) => {
				const ref = this.modalService.create(WebviewsOverlayComponent, {
					disableClose: true,
					parameters: {
						type: OverlayType.Error,
						headerText: this.gameUtils.getLeagueAndRoundText(this.currentGame, source.key),
						title: this.translate.instant(`${this.predictionTranslateKey}.prediction-over-title`),
						subTitle: this.translate.instant(`${this.predictionTranslateKey}.prediction-over-text`),
						onClickOk: () => {
							ref.close();
						}
					}
				});
			});
	}

	get numberOfGames() {
		return this.games.length;
	}

	get currentGame(): Game {
		if (this.games && this.games.length) {
			return this.games[this.currentGameIndex];
		}

		return null;
	}

	savePredictions() {
		const predictions: Prediction[] = [];
		for (const game of this.games) {
			predictions.push(game.prediction);
		}

		this.saving = true;
		this.gameService.savePredictions(predictions)
			.subscribe(
				() => {
					this.saving = false;

					this.gameService.gameSource$.pipe(
						take(1))
						.subscribe((source: GameSource) => {
							const ref = this.modalService.create(WebviewsOverlayComponent, {
								parameters: {
									headerText: this.gameUtils.getLeagueAndRoundText(this.currentGame, source.key),
									title: this.translate.instant(`${this.predictionTranslateKey}.success-title`),
									subTitle: this.translate.instant(`${this.predictionTranslateKey}.success-text`),
									onClickOk: () => {
										ref.close();
									}
								}
							});
						});
				},
				() => {
					this.saving = false;
					this.snackBarService.error(this.translate.instant('prediction.save-error'));
				});
	}

	isLastGameSelected() {
		if (this.gameSourceInfo.type === GameSourceType.TEAM) {
			return true;
		}

		return this.currentGameIndex >= this.numberOfGames - 1;
	}

	isPredictionComplete() {
		return this.currentGame.prediction.result_team_1 != null
			&& this.currentGame.prediction.result_team_2 != null;
	}

	next() {
		if (this.isPredictionComplete()) {
			if (this.isLastGameSelected()) {
				this.savePredictions();
			} else {
				this.currentGameIndex++;
			}
		}
	}
}
