import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GamePollService} from '../services/game-poll.service';
import {GameUtils} from '../core/game-utils';
import {GamePollQuestion, GamePollQuestionType, Player, Team} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-game-poll-question',
	template: `
		<div class="box">
			<div class="box__overlay" *ngIf="disabled"></div>
			<div class="box__header">{{ header }}</div>
			<ng-template [ngIf]="teams">
				<div class="box__content">
					<div class="poll-question__text">
						{{ question.text }}
					</div>
				</div>

				<div class="box-dropdown-wrapper box__content">
					<div class="box-dropdown" [class.box-dropdown--open]="isTeamListOpen">
						<ul class="box-dropdown__list">
							<li class="box-dropdown__item"
								*ngFor="let team of teams"
								(click)="selectTeam(team)"
								[class.box-dropdown__item--selected]="isTeamSelected(team)">
								{{ team.name }}
							</li>
						</ul>
						<div class="box-dropdown__button" (click)="toggleTeamList()">
							<span *ngIf="!selectedTeam">{{ 'polls.select-team' | translate }}</span>
							<span *ngIf="selectedTeam">{{ selectedTeam.name }}</span>
							<app-icon name="arrow-down"></app-icon>
						</div>
					</div>

					<div class="box-dropdown" *ngIf="isPlayerDropdownShown()"
						 [class.box-dropdown--open]="isPlayerListOpen">
						<ul class="box-dropdown__list">
							<li class="box-dropdown__item"
								*ngFor="let player of players$ | async"
								(click)="selectPlayer(player)"
								[class.box-dropdown__item--selected]="isPlayerSelected(player)">
								{{ getPlayerName(player) }}
							</li>
						</ul>
						<div class="box-dropdown__button" (click)="togglePlayerList()">
							<span *ngIf="!selectedPlayer">{{ 'polls.select-player' | translate }}</span>
							<span *ngIf="selectedPlayer">{{ getPlayerName(selectedPlayer) }}</span>
							<app-icon name="arrow-down"></app-icon>
						</div>
					</div>
				</div>
			</ng-template>
			<div class="box__content" *ngIf="!teams">
				<mat-spinner></mat-spinner>
			</div>
		</div>
	`
})
export class GamePollQuestionComponent implements OnInit, OnChanges {

	@Input() header: string;
	@Input() question: GamePollQuestion;
	@Input() disabled = false;

	teams: Team[];
	selectedTeam: Team;
	selectedPlayer: Player;

	isTeamListOpen = false;
	isPlayerListOpen = false;

	@HostBinding('class') clazz = 'poll-question';

	constructor(private pollService: GamePollService) {
	}

	ngOnInit() {
		this.pollService.teams$
			.subscribe(teams => {
				this.teams = teams;

				this.initPreSelection();
			});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.question) {
			this.selectedTeam = null;
			this.selectedPlayer = null;
			this.initPreSelection();
		}
	}

	private initPreSelection() {
		if (this.teams) {
			if (this.question.type === GamePollQuestionType.PLAYER) {
				if (this.question.answer.player_id) {
					this.selectedPlayer = this.getPlayer(this.question.answer.player_id, this.teams);
					if (this.selectedPlayer) {
						this.selectedTeam = this.teams.find(team => {
							return !!team.players.find(player => {
								return player.id === this.selectedPlayer.id;
							});
						});
					}
				}
			} else if (this.question.type === GamePollQuestionType.TEAM) {
				if (this.question.answer.team_id) {
					this.selectedTeam = this.teams.find(team => {
						return team.id === this.question.answer.team_id;
					});
				}
			}
		}
	}

	getPlayer(playerId: number, teams: Team[]) {
		for (const team of teams) {
			for (const player of team.players) {
				if (player.id === playerId) {
					return player;
				}
			}
		}
	}

	isPlayerDropdownShown() {
		return this.question.type === GamePollQuestionType.PLAYER && this.selectedTeam;
	}

	get players$() {
		if (this.selectedTeam) {
			return this.pollService.getPlayers(this.selectedTeam.id);
		}

		return null;
	}

	toggleTeamList() {
		this.isPlayerListOpen = false;
		this.isTeamListOpen = !this.isTeamListOpen;
	}

	togglePlayerList() {
		this.isTeamListOpen = false;
		this.isPlayerListOpen = !this.isPlayerListOpen;
	}

	selectPlayer(player: Player) {
		this.selectedPlayer = player;
		this.isPlayerListOpen = false;
		if (this.question.type === GamePollQuestionType.PLAYER) {
			this.question.answer.player_id = player.id;
		}
	}

	selectTeam(team: Team) {
		this.selectedTeam = team;
		this.isTeamListOpen = false;
		if (this.question.type === GamePollQuestionType.TEAM) {
			this.question.answer.team_id = team.id;
		}
	}

	isPlayerSelected(player: Player) {
		if (this.selectedPlayer) {
			return this.selectedPlayer.id === player.id;
		}

		return false;
	}

	isTeamSelected(team: Team) {
		if (this.selectedTeam) {
			return this.selectedTeam.id === team.id;
		}

		return false;
	}

	getPlayerName(player: Player): string {
		return GameUtils.getPlayerDisplayName(player);
	}
}
