import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {GameService} from '../services/game.service';
import {GameUtils} from '../core/game-utils';
import {TranslateService} from '@ngx-translate/core';
import {SnackBarService} from '../services/snackbar.service';
import {switchMap} from 'rxjs/operators';
import {Game, GameSource, GameSourceType, GameStats, Player, PlayersvoteGames} from '@inscouts/playersvote-models';
import {ActivatedRoute} from "@angular/router";

@Component({
	selector: 'app-widget-rating',
	template: `
		<header class="widget-header">
			<ng-template [ngIf]="finished && !isSingleTeamMode">{{ 'widget.rating.title-finished' | translate }}</ng-template>
			<ng-template [ngIf]="finished && isSingleTeamMode">{{ 'widget.rating.title-finished-single' | translate }}</ng-template>
			<ng-template [ngIf]="!finished">{{ 'widget.rating.title' | translate }}</ng-template>
		</header>
		<div class="app-widget-rating__container" *ngIf="game">
			<app-widget-rating-controls [game]="game" [stats]="stats" [team1]="team1" [team2]="team2" [singleTeamNumber]="singleTeamNumber"
										[finished]="finished && stats" *ngIf="!loadingStats && !ratingAlreadyDoneBeforeLoading"></app-widget-rating-controls>

			<mat-spinner *ngIf="loadingStats" class="app-widget__spinner"></mat-spinner>

			<div *ngIf="!finished">
				<app-rating-legend></app-rating-legend>
			</div>
		</div>
		<div class="app-widget-rating__bottom" *ngIf="!finished">
			<button class="widget-button" [class.widget-button--gray]="!isRatingComplete()" (click)="save()"
					[disabled]="saving || !isRatingComplete()" *ngIf="!finished">
			<span *ngIf="!isRatingComplete()"
				  class="button__text button__text--small">{{ 'widget.rating.missing-rating' | translate }}</span>

				<ng-template [ngIf]="isRatingComplete()">
					<span *ngIf="!saving" class="button__text">{{ 'widget.rating.submit' | translate }}</span>
					<span *ngIf="saving" class="button__text">{{ 'general.saving' | translate }}</span>
				</ng-template>
			</button>
		</div>

		<iframe *ngIf="integrations.hmc" src="https://demo.hearmecheer.com/games/leafs-vs-columbus-2020-game-1/PlayersVote/button2" style="border:none;" allow="microphone">
		</iframe>
	`
})

export class WidgetRatingComponent implements OnInit {

	@HostBinding('class') clazz = 'app-widget-rating';

	@Input() game: Game;
	@Input() gameSource: GameSource;

	finished = false;
	saving = false;
	loadingStats = false;
	stats: GameStats;
	ratingAlreadyDoneBeforeLoading = false;
	integrations = {
		hmc: false
	}

	team1: Player[];
	team2: Player[];

	ratings = PlayersvoteGames.RATING_NUMBERS;

	constructor(private gameService: GameService, private translate: TranslateService, private snackBarService: SnackBarService, private route: ActivatedRoute) {
	}

	ngOnInit() {
		const players = GameService.getTeamPlayers(this.game);
		this.team1 = players.team1;
		this.team2 = players.team2;

		if (this.isRatingComplete()) {
			this.finished = true;
			this.loadingStats = true;
			this.ratingAlreadyDoneBeforeLoading = true;

			return this.gameService.getStats(this.game.id)
				.subscribe(stats => {
					this.loadingStats = false;
					this.ratingAlreadyDoneBeforeLoading = false;
					this.stats = stats;
				});
		}

		this.route.queryParams.subscribe(params => {
			Object.entries(params).forEach(([k, v]) => {
				if (k.startsWith('integration_') || k.startsWith('integration.')) {
					const kv = k.substring(12)
					this.integrations[kv] = v;
				}
			})
		})
	}

	get isSingleTeamMode() {
		return this.gameService.gameSource.type === GameSourceType.TEAM;
	}

	get singleTeamNumber() {
		return GameUtils.getSingleTeamNumber(this.singleTeam, this.game);
	}

	get singleTeam() {
		return PlayersvoteGames.getMainTeamForGameSource(this.game, this.gameService.gameSource);
	}

	isRatingComplete(): boolean {
		return GameUtils.isRatingComplete(this.game, this.gameSource);
	}

	save() {
		this.saving = true;
		this.gameService.rateGame(this.game.id, this.game.prediction)
			.pipe(
				switchMap(() => {
					this.saving = false;
					this.finished = true;
					return this.gameService.getStats(this.game.id);
				})
			).subscribe((stats) => {
				this.stats = stats;
			},
			() => {
				this.saving = false;
				this.finished = false;
				this.snackBarService.error(this.translate.instant('rating.error'));
			}
		);
	}
}
