import {Component, OnInit, HostBinding, Input, EventEmitter, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../services/snackbar.service';
import {PollService, UpdateUserData} from '../services/poll.service';
import {Poll} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-poll-email-form',
	templateUrl: 'poll-email-form.component.html'
})
export class PollEmailFormComponent implements OnInit {

	@HostBinding('class') clazz = 'app-poll-email-form';

	@Input() poll: Poll;
	@Output() submittingChange = new EventEmitter<boolean>();
	@Output() success = new EventEmitter<boolean>();

	emailForm: UntypedFormGroup;
	private _emailSuccess = false;
	private _emailSubmitting = false;

	constructor(private fb: UntypedFormBuilder, private snackbar: SnackBarService, private pollService: PollService) {


	}

	ngOnInit() {
		this.emailForm = this.fb.group({
			email: ['', Validators.compose([Validators.required, Validators.email])],
			phone: ['', (this.poll.email_opt_in_phone) ? Validators.required : null],
			terms: [false, Validators.requiredTrue],
			allowed_data_use: [false],
			subscribed_newsletter: [false]
		});
	}

	get emailSubmitting() {
		return this._emailSubmitting;
	}

	set emailSubmitting(submitting: boolean) {
		this._emailSubmitting = submitting;
		this.submittingChange.next(this._emailSubmitting);
	}

	get emailSuccess() {
		return this._emailSuccess;
	}

	set emailSuccess(success: boolean) {
		this._emailSuccess = success;
		this.success.next(this._emailSuccess);
	}

	submitEmail() {
		this.emailForm.controls.email.markAsTouched();
		if (this.poll.email_opt_in_phone) {
			this.emailForm.controls.phone.markAsTouched();
		}

		this.emailForm.controls.terms.markAsTouched();
		if (this.emailForm.valid) {
			this.emailSubmitting = true;
			this.pollService.updateUser(this.emailForm.value as UpdateUserData)
				.subscribe(() => {
					this.emailSuccess = true;
				}, () => {
					this.snackbar.error('new-polls.email-error');
				}, () => {
					this.emailSubmitting = false;
				});
		}
	}
}
