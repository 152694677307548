import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChange, ViewChild} from '@angular/core';

import {Battle, Poll,} from '@inscouts/playersvote-models';

import {State} from '../core/state';
import {PollService} from '../services/poll.service';
import {TranslateService} from "@ngx-translate/core";

import {differenceInHours, differenceInMilliseconds, intervalToDuration, isAfter, isBefore} from "date-fns/fp";

@Component({
	selector: 'battle-poll',
	template: `
		<div
			class="battle-poll"
			[ngClass]="{
				'battle-poll--past': isClosed,
				'battle-poll--active': isActive,
				'battle-poll--future': isFuture
			}"
			#pollEl
		>
			<!-- <div class="battle-poll__type">{{ poll.name }}</div> -->
			<div class="battle-poll__date battle-poll__date-day">
				{{ poll.available_start | date: 'dd.' : '' :  translateService.currentLang }}
			</div>
			<div class="battle-poll__date battle-poll__date-month">
				{{ poll.available_start | date: 'MMMM' : '' :  translateService.currentLang }}
			</div>
			<div *ngIf="isClosed" class="battle-poll__state">
				{{ "battle.poll-closed" | translate }}
			</div>
			<div *ngIf="showCountDown" class="battle-poll__state battle-poll__state--countdown">
				<app-icon name="clock"></app-icon>
				{{ countdownStr }}
			</div>
			<div *ngIf="!showCountDown && isFuture" class="battle-poll__state">
				{{ poll.available_start | date: 'HH:mm' : '' :  translateService.currentLang  }}
			</div>
			<div *ngIf="isActive && userCanAnswer === false" class="battle-poll__state">
				{{ "battle.poll-answered" | translate }}
			</div>
			<div *ngIf="isActive && userCanAnswer">
				<button (click)="launchPoll()" class="battle-poll__button">
					{{ "battle.poll-now" | translate }}
				</button>
			</div>
			<div *ngIf="isPreview">
				<button (click)="launchPoll(true)" class="battle-poll__button" style="background:black">
					{{ "battle.poll-now" | translate }}
				</button>
			</div>
			<app-icon *ngIf="isFuture" name="lock" class="battle-poll__lock-icon"></app-icon>
		</div>


	`
})
export class BattlePollComponent implements OnChanges {

	@ViewChild('pollEl', { static: true }) pollEl: ElementRef;

	@Input() poll: Poll;
	@Input() isPreview: boolean = false;
	@Input() battle: Battle;
	@Output() active = new EventEmitter<unknown>();

	isClosed: boolean;
	isActive: boolean;
	isFuture: boolean;
	userCanAnswer: boolean;
	dateAnswered: Date;
	showCountDown: boolean;
	countdownStr: string;

	constructor(
		public state: State,
		private pollService: PollService,
		public translateService: TranslateService,
	) {
		this.setActive();
	}

	ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
		if('poll' in changes) {
			this.setActive();
		}
	}

	launchPoll(isPreview: boolean = false) {
		const host = window.location.host;
		const isDemo = window.location.search.includes('demo=true');

		const encodedState = encodeURIComponent(btoa(JSON.stringify({
			pollType: 0,
			sportType: 'soccer',
			sponsor: 1,
		})));
		const battleId = isDemo ? encodedState : this.battle.id;
		const pollId = isDemo ? encodedState : this.poll.id;
		window.parent.postMessage({
			type: 'OPEN_OVERLAY',
			preview: this.isPreview,
			url: `//${host}/battles/${battleId}/polls/${pollId}`,
		}, '*');

		const overlayClosedHandler = (ev) => {
			if(ev.data.type === 'CLOSED_OVERLAY') {
				this.getPollAnswerDate()
				window.removeEventListener("message", overlayClosedHandler);
			}
		}
		window.addEventListener("message", overlayClosedHandler);
	}

	setActive() {
		if(this.poll) {
			const now = new Date();
			const {available_start: start, available_end: end} = this.poll;
			this.isActive = isAfter(now, start) && isBefore(now, end);
			this.isClosed = isAfter(now, end);
			this.isFuture = isBefore(now, start);

			if (this.isFuture) {
				let timeout: number;

				if(differenceInHours(now, start) < 24) {
					this.showCountDown = true;
					const duration = intervalToDuration({start: now, end: start})
					const zeroPad = (num) => String(num).padStart(2, '0');
					this.countdownStr = `${zeroPad(duration.hours)}:${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`
					timeout = 1000;  // update every second if less than 24h
				} else {
					timeout = differenceInMilliseconds(now, start) - 86400000; // schedule first update 24h before end
				}
				window.setTimeout(() => this.setActive(), timeout);
			}
			if (isBefore(now, end)) {
				window.setTimeout(() => this.setActive(), differenceInMilliseconds(now, end) + 1000);
			}

			if (this.isActive) {
				this.active.emit(this.pollEl.nativeElement);
				if(!this.dateAnswered && this.userCanAnswer === undefined) {
					this.getPollAnswerDate()
				}
			}
		}
	}

	getPollAnswerDate() {
		this.pollService.getPollAnswered(this.poll.id)
			.subscribe((res) => {
				if(res.date) {
					this.dateAnswered = new Date(res.date);
					this.userCanAnswer = false;
				} else {
					this.userCanAnswer = true;
				}
			}, () => {
				this.userCanAnswer = true;
			})
	}
}
