import {AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {PredictTeamComponent} from './predict-team.component';
import {Game} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-prediction',
	template: `
		<div class="prediction-container">
			<app-predict-team [headerText]="game.team_1?.name"
							  [(result)]="game.prediction.result_team_1"
							  [showInputs]="showInputs"
							  [disabled]="disabled" #leftPrediction></app-predict-team>

			<div class="result-separator">
				<div class="bullet"></div>
				<div class="bullet"></div>
			</div>

			<app-predict-team [headerText]="game.team_2?.name"
							  [(result)]="game.prediction.result_team_2"
							  [showInputs]="showInputs"
							  [disabled]="disabled"></app-predict-team>
		</div>
	`
})
export class PredictionComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() game: Game;
	@Input() disabled = false;
	@Input() showInputs = true;
	@Input() focusFirstInputOnInit = true;
	@ViewChild('leftPrediction', { static: true }) leftPrediction: PredictTeamComponent;

	constructor() {
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		if (this.focusFirstInputOnInit) {
			this.focusFirstInput();
		}
	}

	ngOnChanges() {
		if (this.focusFirstInputOnInit) {
			this.focusFirstInput();
		}
	}

	private focusFirstInput() {
		this.leftPrediction.focusInput();
	}
}
