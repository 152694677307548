import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
	dsn: "https://a30409f4b36f4608a3acc91af1aec52e@o1123554.ingest.sentry.io/6196882",
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.routingInstrumentation,
		}),
	],
	environment: environment.releaseStage,
	tracesSampleRate: environment.production ? .001 : 0,
	tracePropagationTargets: ['localhost', environment.apiUrl]
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(() => console.log(`Bootstrap success`))
	.catch(err => console.error(err));
