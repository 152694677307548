import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {routes} from './routes';

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
	],
	exports: [RouterModule],
	declarations: []
})
export class AppRoutingModule {
}
