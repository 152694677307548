import {AccountStyles, GameSourceStyle} from '@inscouts/playersvote-models';

export class StyleUtils {

	static createNewStyleCollection() {
		const style: any = document.createElement('style');
		document.head.appendChild(style);

		return style;
	}

	static styleForAccountStyle(accountStyles: AccountStyles) {
		if (accountStyles.primaryColor) {
			console.log('Styling for Account', accountStyles);
			const style = StyleUtils.createNewStyleCollection();
			StyleUtils.styleForPrimaryColor(style, accountStyles.primaryColor);
		}
	}

	static styleForSource(gameSourceStyle: GameSourceStyle) {
		const style = this.createNewStyleCollection();
		StyleUtils.styleForBackgroundImageUrl(style, gameSourceStyle.backgroundImageUrl);
		StyleUtils.styleForPrimaryColor(style, gameSourceStyle.primaryColor);
	}

	static styleForBackgroundImageUrl(style: any, backgroundImageUrl: string) {
		StyleUtils.insertRules(style, [
			`.main-background {background-image: url(${backgroundImageUrl})}`,
			`.app-background {background-image: url(${backgroundImageUrl})}`,
		]);
	}

	static styleForPrimaryColor(style: any, primaryColor: string) {
		StyleUtils.insertRules(style, [
			`.app-webviews .box .box__header {background: ${primaryColor}}`,
			`.app-webviews-overlay .box .box__header {background: ${primaryColor}}`,

			`.rating__scale-item--selected { background: ${primaryColor} }`,
			`.app-bar__bar-inner { background: ${primaryColor} }`,
			`.app-mobile-button { background: ${primaryColor} }`,
			`.widget-button { background: ${primaryColor} }`,
			`.app-widget-rate-team__scale-item--selected { background: ${primaryColor} }`,
			`.app-rating-scale-item--selected { background: ${primaryColor} }`,

			`.app-rating-scale--finished .app-rating-scale-item--selected .app-rating-scale-item-text { color: ${primaryColor} }`,

			`.app-widget-rate-team--finished .app-widget-rate-team__scale-item--selected .app-widget-rate-team__scale-item-text { color: ${primaryColor} }`,
			`.app-widget-rate-team--finished .app-widget-rate-team__scale-item--selected .app-bar__value { color: ${primaryColor} }`,
			`.app-dial__title--highlighted { color: ${primaryColor} }`,
			`.app-dial-number--highlighted { color: ${primaryColor} }`,

			`.app-bar__bar-inner { background: ${primaryColor} }`,
			`.app-mobile-button { background: ${primaryColor} }`,
			`.widget-button { background: ${primaryColor} }`,
			`.app-widget-rate-team__scale-item--selected { background: ${primaryColor} }`,
			`.app-rating-scale-item--selected { background: ${primaryColor} }`,

			`.app-rating-scale--finished .app-rating-scale-item--selected .app-rating-scale-item-text { color: ${primaryColor} }`,

			`.app-widget-rate-team--finished .app-widget-rate-team__scale-item--selected .app-widget-rate-team__scale-item-text { color: ${primaryColor} }`,
			`.app-widget-rate-team--finished .app-widget-rate-team__scale-item--selected .app-bar__value { color: ${primaryColor} }`,
			`.app-dial__title--highlighted { color: ${primaryColor} }`,
			`.app-dial-number--highlighted { color: ${primaryColor} }`,


			`.app-poll-primary-button.mat-flat-button.mat-primary:not([disabled]) { background-color: ${primaryColor} }`,
			`.app-poll-question__button-choices button.mat-flat-button { background-color: ${primaryColor} }`,
			`.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle { border-color: ${primaryColor} }`,
			`.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle { background-color: ${primaryColor} }`,
			`.mat-radio-button.mat-accent.mat-radio-checked .mat-ripple-element { background-color: ${primaryColor} }`
		]);
	}

	static insertRules(style: any, rules: string[]) {
		for (const rule of rules) {
			StyleUtils.insertRule(style, rule);
		}
	}

	static insertRule(style: any, rule: string) {
		style.sheet.insertRule(rule, 0);
	}
}
