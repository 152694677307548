import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {StyleUtils} from '../core/style.utils';
import {
	Game,
	GameSource,
	GameStats,
	GameStatus,
	MvpValue,
	PercentageValue,
	Player,
	Prediction,
	ResultPercentageValue,
	Sponsor,
	SponsorBarSize,
	SportClassification,
	SportType,
	Team,
	TendencyStats
} from '@inscouts/playersvote-models';
import {GameSourceKey} from '../models/game.model';
import {cloneDeep} from 'lodash';
import {ClientGameStatus, DemoState} from '../../../core/core';
import {GameUtils} from '../core/game-utils';

export const parseDemoStateString = (state: string): DemoState => {
	return JSON.parse(atob(decodeURIComponent(state)));
};

@Injectable()
export class FakeGameService {

	gameSource: GameSource = null;
	gameSource$ = new ReplaySubject<GameSource>();

	static createPercentValues(values: number[]): PercentageValue[] {
		const sum = values.reduce((total, value) => total + value, 0);
		return values.map((value) => {
			return {
				percentage: Math.round(value / sum * 10000) / 100,
				total: value
			};
		});
	}

	private static createResultPercentValues(values: number[], results: number[][]): ResultPercentageValue[] {
		return this.createPercentValues(values)
			.map((value, index) => {
				return <ResultPercentageValue>{
					...value,
					result_team_1: results[index][0],
					result_team_2: results[index][1],
				};
			});
	}

	private static createMvpValues(values: number[], players: Player[]): MvpValue[] {
		return this.createPercentValues(values)
			.map((value, index) => {
				return <MvpValue>{
					...value,
					player: players[index]
				};
			});
	}

	private static dateInHours(hours: number): Date {
		const date = new Date();
		date.setHours(date.getHours() + hours);
		return date;
	}

	constructor() {
		const pairs = [
			[fcKoeln, wolfsburg],
			[rangers, mapleLeafs],
			[lakers, bostonCeltics],
		];

		for (const pair of pairs) {
			FakeGameService.prettifyPlayers(pair[0], pair[1]);
		}
	}

	// noinspection JSUnusedGlobalSymbols
	loadSourceInfo(sourceKey: string): Observable<GameSource> {
		const state = FakeGameService.parseStateString(sourceKey);

		this.gameSource = {
			key: GameSourceKey.Bundesliga1819,
			type: state.gameSourceType,
			name: 'Fantasy League',
			calendar_opta_ids: ['123'],
			team_opta_id: '1',
			style: {
				primaryColor: '#00ABED',
				backgroundImageUrl: 'assets/images/basketball-bg.jpg'
			},
			league: {
				account_id: 0,
				name: 'Fantasy League',
				analytics_key: 'fantasy_league',
				classification: SportClassification.SPORT,
				sport_type: SportType.Soccer,
			},
			league_id: 0
		};

		StyleUtils.styleForSource(this.gameSource.style);
		this.gameSource$.next(this.gameSource);
		return of(this.gameSource);
	}

	private static parseStateString(state: string): DemoState {
		return parseDemoStateString(state);
	}

	private static getGameForState(state: DemoState, stateString: string): Game {
		const game: Game = cloneDeep(baseGame);

		game.id = stateString as any;

		switch (state.gameStatus) {
			case ClientGameStatus.Prediction:
				game.start = FakeGameService.dateInHours(10);
				game.status = GameStatus.FIXTURE;
				break;
			case ClientGameStatus.Rating:
				game.start = FakeGameService.dateInHours(-1);
				game.status = GameStatus.PLAYING;
				break;
			case ClientGameStatus.Over:
				game.start = FakeGameService.dateInHours(-2);
				game.status = GameStatus.PLAYED;
				game.meta_data = {
					featured: true,
					sponsor: baseGame.meta_data.sponsor,
					rating_end: FakeGameService.dateInHours(-1)
				};
		}

		switch (state.sportType) {
			case SportType.IceHockey:
				game.team_1 = rangers;
				game.team_2 = mapleLeafs;
				game.team_1_score = 4;
				game.team_2_score = 1;
				break;
			case SportType.Basketball:
				game.team_1 = lakers;
				game.team_2 = bostonCeltics;
				game.team_1_score = 129;
				game.team_2_score = 128;
				break;
			case SportType.Soccer:
				game.team_1 = fcKoeln;
				game.team_2 = wolfsburg;
				game.team_1_score = 2;
				game.team_2_score = 2;
				break;
		}

		game.team_1.id = 1;
		game.team_2.id = 2;

		game.team_1.opta_id = '1';
		game.team_2.opta_id = '2';
		game.players = [...game.team_1.players, ...game.team_2.players];
		game.meta_data.sponsor = FakeGameService.getSponsor(state.sponsor);
		game.sport_type = state.sportType;

		if (state.sponsor === 1) {
			game.odds_cross = 1700;
			game.odds_team_1_win = 2300;
			game.odds_team_2_win = 1200;
		}

		return game;
	}

	// noinspection JSMethodCanBeStatic, JSUnusedGlobalSymbols
	getGame(gameId: string): Observable<Game> {
		const state = FakeGameService.parseStateString(gameId);

		const game = FakeGameService.getGameForState(state, gameId);

		return of(game);
	}

	// noinspection JSMethodCanBeStatic, JSUnusedGlobalSymbols
	savePredictions() {
		return of({});
	}

	// noinspection JSMethodCanBeStatic, JSUnusedGlobalSymbols
	rateGame() {
		return of({});
	}

	// noinspection JSMethodCanBeStatic, JSUnusedGlobalSymbols
	getStats(gameId: any): Observable<GameStats> {
		const state = FakeGameService.parseStateString(gameId);
		const game = FakeGameService.getGameForState(state, gameId);

		const players1 = game.team_1.players.slice(0, 4);
		const players2 = game.team_2.players.slice(0, 4);

		const drawTendency = FakeGameService.createPercentValues([2334, 904, 258]);
		const winTendency = FakeGameService.createPercentValues([2334, 0, 532]);

		console.log(winTendency);

		const tendency = GameUtils.canGameEndInDraw(game) ? drawTendency : winTendency;

		return of({
			game_id: gameId,
			total_interactions: 87933,
			tendency: {
				team_1_win: tendency[0],
				draw: tendency[1],
				team_2_win: tendency[2],
				total: tendency.reduce((total, p) => total + p.total, 0),
			} as TendencyStats,
			top_results: FakeGameService.createResultPercentValues([1022, 583, 201], [[2, 1], [3, 1], [1, 1]]),
			mvp: {
				team_1: FakeGameService.createMvpValues([584, 294, 76, 62], players1),
				team_2: FakeGameService.createMvpValues([284, 124, 66, 52], players2),
			},
			rating: {
				total: 390,
				team_1: {
					total: 195,
					average: 3.26,
					median: 4,
					distribution: [
						{
							total: 5, percentage: 0, rating: 1
						}, {
							total: 20,
							percentage: 2,
							rating: 2
						},
						{total: 105, percentage: 8, rating: 3}, {
							total: 54,
							percentage: 12,
							rating: 4
						}, {total: 6, percentage: 49, rating: 5}, {total: 5, percentage: 29, rating: 6}]
				},
				team_2: {
					total: 195,
					average: 3.64,
					median: 4,
					distribution: [{total: 3, percentage: 0, rating: 1}, {
						total: 12,
						percentage: 2,
						rating: 2
					}, {total: 68, percentage: 11, rating: 3}, {
						total: 89,
						percentage: 38,
						rating: 4
					}, {total: 16, percentage: 31, rating: 5}, {total: 7, percentage: 18, rating: 6}]
				}
			},
			bounded_results_team_1: [
				{
					total: 0,
					percentage: 64,
					lower_bound: 95
				},
				{
					total: 0,
					percentage: 56,
					lower_bound: 100
				},
				{
					total: 0,
					percentage: 41,
					lower_bound: 105
				},
				{
					total: 0,
					percentage: 28,
					lower_bound: 110
				},
				{
					total: 0,
					percentage: 14,
					lower_bound: 115
				}
			],
			bounded_results_team_2: [
				{
					total: 0,
					percentage: 45,
					lower_bound: 95
				},
				{
					total: 0,
					percentage: 32,
					lower_bound: 100
				},
				{
					total: 0,
					percentage: 27,
					lower_bound: 105
				},
				{
					total: 0,
					percentage: 18,
					lower_bound: 110
				},
				{
					total: 0,
					percentage: 9,
					lower_bound: 115
				}
			],
		});
	}

	static getSponsor(id: number): Sponsor {
		switch (id) {
			// UNIBET
			case 1:
				return {
					bar_size: SponsorBarSize.Large,
					logo_url: 'https://a1s.unicdn.net/polopoly_fs/1.1193254.1560787570!/menu/standard/file/Unibet%20new%20logo%20large1.svg',
					placement_settings: {
						widget: {
							background_color: '#000'
						},
						mobile: {
							background_color: '#000'
						},
						bot: {}
					}
				};

			// Cancer Day
			case 2:
				return {
					logo_url: 'assets/images/demo/cancer-day-official.png',
					url: 'https://www.worldcancerday.org/',
					placement_settings: {
						widget: {
							background_color: '#ff7f4d'
						},
						mobile: {
							background_color: '#ff7f4d'
						},
						bot: {}
					}
				};

			// Nike
			case 3:
				return {
					logo_url: 'assets/images/demo/nike.png',
					placement_settings: {
						widget: {
							background_color: '#d61e16'
						},
						mobile: {
							background_color: '#d61e16'
						},
						bot: {}
					}
				};

			default:
				return null;
		}
	}

	private static prettifyPlayers(team1: Team, team2: Team) {
		team1.players = team1.players.map((player, index) => {
			const names = player.firstname.split(' ');
			return {...player, id: index + 1, team_id: 1, firstname: names[0], lastname: names[1] ? names[1] : ''};
		});

		team2.players = team2.players.map((player, index) => {
			const names = player.firstname.split(' ');
			return {...player, id: index + team1.players.length + 1, team_id: 2, firstname: names[0], lastname: names[1] ? names[1] : ''};
		});
	}
}

const baseGame: Game = {
	week: 10,
	league_name: 'Test',
	meta_data: {
		featured: true,
		rating_end: null,
	},
	prediction: <Prediction>{
		result_team_1: null,
		result_team_2: null,
	},
	team_1: null,
	team_1_score: null,
	team_2: null,
	team_2_score: null,
	sport_type: null,

	odds_team_2_win: null,
	odds_team_1_win: null,
	odds_cross: null,
};


const rangers: Team = {
	logo_url: 'assets/images/demo/ny_rangers.svg',
	name: 'New York Rangers',
	players: [
		{firstname: 'Mika Zibanejad'},
		{firstname: 'Lias Andersson'},
		{firstname: 'Connor Brickley'},
		{firstname: 'Pavel Buchnevich'},
		{firstname: 'Filip Chytil'},
		{firstname: 'Fredrik Claesson'},
		{firstname: 'Tony DeAngelo'},
		{firstname: 'Jesper Fast'},
		{firstname: 'Alexandar Georgiev'},
		{firstname: 'Brett Howden '},
		{firstname: 'Chris Kreider'},
		{firstname: 'Brendan Lemieux'},
		{firstname: 'Henrik Lundqvist'},
		{firstname: 'Vladislav Namestnikov'},
		{firstname: 'Boo Nieves'},
		{firstname: 'Neal Pionk'},
		{firstname: 'Kevin Shattenkirk'},
		{firstname: 'Brady Skjei'},
		{firstname: 'Brendan Smith'},
		{firstname: 'Marc Staal'},
		{firstname: 'Ryan Strome'},
		{firstname: 'Jimmy Vesey'},
	]
};

const mapleLeafs = {
	logo_url: 'assets/images/demo/maple_leafs.png',
	name: 'Toronto Maple Leafs',
	players: [
		{firstname: 'John Tavares'},
		{firstname: 'Frederik Andersen'},
		{firstname: 'Connor Brown'},
		{firstname: 'Travis Dermott'},
		{firstname: 'Tyler Ennis'},
		{firstname: 'Jake Gardiner'},
		{firstname: 'Frederik Gauthier'},
		{firstname: 'Ron Hainsey'},
		{firstname: 'Justin Holl'},
		{firstname: 'Zach Hyman'},
		{firstname: 'Andreas Johnsson'},
		{firstname: 'Nazem Kadri'},
		{firstname: 'Kasperi Kapanen'},
		{firstname: 'Patrick Marleau'},
		{firstname: 'Mitchell Marner'},
		{firstname: 'Auston Matthews'},
		{firstname: 'Trevor Moore'},
		{firstname: 'Jake Muzzin'},
		{firstname: 'William Nylander'},
		{firstname: 'Igor Ozhiganov'},
		{firstname: 'Nic Petan'},
		{firstname: 'Morgan Rielly'},
		{firstname: 'Garret Sparks'},
		{firstname: 'Nikita Zaitsev'},
	]
};

// Soccer
const fcKoeln = {
	logo_url: 'assets/images/demo/fc_koeln.svg',
	name: 'FC Köln',
	players: [
		{firstname: 'Louis Schaub'},
		{firstname: 'Timo Horn'},
		{firstname: 'Jannes Horn'},
		{firstname: 'Noah Katterbach'},
		{firstname: 'Benno Schmitz'},
		{firstname: 'Matthias Bader'},
		{firstname: 'Johannes Geis'},
		{firstname: 'Marco Höger'},
		{firstname: 'Matthias Lehmann'},
		{firstname: 'Salih Özcan'},
		{firstname: 'Vincent Koziello'},
		{firstname: 'Niklas Hauptmann'},
		{firstname: 'Nikolas Nartey'},
		{firstname: 'Chris Führich'},
		{firstname: 'Marcel Risse'},
		{firstname: 'Dominick Drexler'},
		{firstname: 'Florian Kainz'},
		{firstname: 'Christian Clemens'},
		{firstname: 'Anthony Modeste'},
		{firstname: 'Jhon Córdoba'},
		{firstname: 'Simon Terodde'},
		{firstname: 'Thomas Kessler'},
		{firstname: 'Jan-Christoph Bartels'},
		{firstname: 'Brady Scott'},
		{firstname: 'Jorge Meré'},
		{firstname: 'Lasse Sobiech'},
		{firstname: 'Frederik Sörensen'},
		{firstname: 'Rafael Czichos'},
		{firstname: 'Jonas Hector'},
	]
};

const wolfsburg = {
	logo_url: 'assets/images/demo/wolfsburg.svg',
	name: 'VfL Wolfsburg',
	players: [
		{firstname: 'Josuha Guilavogui'},
		{firstname: 'Koen Casteels'},
		{firstname: 'Pavao Pervan'},
		{firstname: 'Phillip Menzel'},
		{firstname: 'John Anthony Brooks'},
		{firstname: 'Marcel Tisserand'},
		{firstname: 'Felix Uduokhai'},
		{firstname: 'Robin Knoche'},
		{firstname: 'Jérôme Roussillon'},
		{firstname: 'Gian-Luca Itter'},
		{firstname: 'William de Asevedo Furtado'},
		{firstname: 'Paul Verhaegh'},
		{firstname: 'Sebastian Jung'},
		{firstname: 'Ignacio Camacho'},
		{firstname: 'Maximilian Arnold'},
		{firstname: 'Yannick Gerhardt'},
		{firstname: 'Elvis Rexhbecaj'},
		{firstname: 'Yunus Malli'},
		{firstname: 'Josip Brekalo'},
		{firstname: 'Paul-Georges Ntep'},
		{firstname: 'John Yeboah'},
		{firstname: 'Marvin Stefaniak'},
		{firstname: 'Felix Klaus'},
		{firstname: 'Renato Steffen'},
		{firstname: 'Ismail Azzaoui'},
		{firstname: 'Admir Mehmedi'},
		{firstname: 'Wout Weghorst'},
		{firstname: 'Daniel Ginczek'},
	]
};


// Basketball
const lakers = {
	logo_url: 'assets/images/demo/lakers.svg',
	name: 'Los Angeles Lakers',
	players: [
		{firstname: 'Rajon Rondo'},
		{firstname: 'Lonzo Ball'},
		{firstname: 'Isaac Bonga'},
		{firstname: 'Reggie Bullock'},
		{firstname: 'Kentavious Caldwell-Pope'},
		{firstname: 'Alex Caruso'},
		{firstname: 'Tyson Chandler'},
		{firstname: 'Josh Hart'},
		{firstname: 'Brandon Ingram'},
		{firstname: 'LeBron James'},
		{firstname: 'Kyle Kuzma'},
		{firstname: 'JaVale McGee'},
		{firstname: 'Mike Muscala'},
		{firstname: 'Lance Stephenson'},
		{firstname: 'Moritz Wagner'},
		{firstname: 'Johnathan Williams'},
	]
};

const bostonCeltics = {
	logo_url: 'assets/images/demo/celtics.svg',
	name: 'Boston Celtics',
	players: [
		{firstname: 'Al Horford'},
		{firstname: 'Jayson Tatum'},
		{firstname: 'R.J. Hunter'},
		{firstname: 'Jaylen Brown'},
		{firstname: 'Brad Wanamaker'},
		{firstname: 'Kyrie Irving'},
		{firstname: 'Terry Rozier'},
		{firstname: 'Marcus Morris'},
		{firstname: 'Gordon Hayward'},
		{firstname: 'Daniel Theis'},
		{firstname: 'Guerschon Yabusele'},
		{firstname: 'Marcus Smart'},
		{firstname: 'Semi Ojeleye'},
		{firstname: 'Robert Williams'},
		{firstname: 'Aron Baynes'},
		{firstname: 'P.J. Dozier'},
	]
};
