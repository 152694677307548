import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	ViewChild
} from '@angular/core';
import {GameService} from '../../services/game.service';

@Component({
	selector: 'app-dial',
	template: `
		<div class="app-dial__circle">
			<canvas #dialCanvas></canvas>
			<div class="app-dial-overlay">
				<div class="app-dial-number-wrapper">
					<div class="app-dial-number" [class.app-dial-number--highlighted]="titleHighlighted">{{ integerValue }}%</div>
				</div>
			</div>
		</div>
		<div class="app-dial__title" [class.app-dial__title--highlighted]="titleHighlighted" *ngIf="title">
			{{ title }}
		</div>
	`,
	host: {
		'class': 'app-dial',
	},
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class DialComponent implements OnInit, AfterViewInit, OnChanges {

	private static readonly ANIMATION_DURATION = 2000;

	@ViewChild('dialCanvas', { static: true }) canvasRef: ElementRef;
	private canvas: HTMLCanvasElement;
	private ctx: CanvasRenderingContext2D;


	@Input() title: string;
	@Input() titleHighlighted = false;
	@Input() size = 300;
	@Input() value = 0;
	@Input() maxValue = 100;
	@Input() alwaysShowDecimals = false;
	@Input() suffix: string;

	animationScale = 0;

	constructor(private gameService: GameService) {
	}

	ngOnInit() {
		this.canvas = this.canvasRef.nativeElement;
		this.ctx = this.canvas.getContext('2d');


		this.canvas.width = this.size;
		this.canvas.height = this.size;
		this.canvas.style.width = this.size / 2 + 'px';
		this.canvas.style.height = this.size / 2 + 'px';
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const start = Date.now();
			const interval = setInterval(() => {
				this.animationScale = DialComponent.ease((Date.now() - start) / DialComponent.ANIMATION_DURATION);
				if (this.animationScale >= 1) {
					this.animationScale = 1;
					clearInterval(interval);
				}

				this.draw();
			}, 25);
		}, 400);
	}

	ngOnChanges() {
		if (this.ctx) {
			this.draw();
		}
	}

	static ease(t) {
		return (--t) * t * t + 1;
	}

	static get NINETY_DEGREES() {
		return Math.PI * 0.5;
	}

	static get TAU() {
		return Math.PI * 2;
	}

	get percentValue() {
		return this.value / this.maxValue;
	}

	get integerValue(): number {
		return Math.floor(this.value);
	}

	draw() {
		const mid = this.size * 0.5;

		// Since the coordinate system is about to get changed,
		// save the context (and restore it when we're done later).
		this.ctx.save();

		// Clear everything.
		this.ctx.clearRect(0, 0, this.size, this.size);

		// Rotate the coordinate system.
		this.ctx.translate(mid, mid);
		this.ctx.rotate(-DialComponent.NINETY_DEGREES);
		this.ctx.translate(-mid, -mid);

		// Outer arc.
		this.ctx.beginPath();
		this.ctx.moveTo(mid, mid);

		const colorEndAngle = this.animationScale * this.percentValue * DialComponent.TAU;
		this.ctx.arc(mid, mid, mid, 0, colorEndAngle);
		this.ctx.closePath();

		this.ctx.fillStyle = this.getCssColorValue();
		this.ctx.fill();

		this.ctx.beginPath();
		this.ctx.moveTo(mid, mid);
		this.ctx.arc(mid, mid, mid, colorEndAngle, DialComponent.TAU);
		this.ctx.closePath();

		this.ctx.fillStyle = '#dedede';
		this.ctx.fill();

		// Inner arc.
		this.ctx.beginPath();
		this.ctx.moveTo(mid, mid);
		this.ctx.arc(mid, mid, mid * 0.82, 0, DialComponent.TAU);
		this.ctx.closePath();
		this.ctx.fillStyle = '#FFF';
		this.ctx.fill();

		this.ctx.restore();
	}

	getCssColorValue(): string {
		const source = this.gameService.gameSource;
		if (source && source.style) {
			return source.style.primaryColor;
		}
	}
}
