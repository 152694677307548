import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {DialValue} from '../core/components/multi-dial.component';
import {TeamNumber} from '../models/game.model';
import {BoundResultPercentValue, GameStats} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-mobile-high-score-prediction-stats',
	template: `
		<div class="app-mobile-prediction-bars">
			<div class="app-mobile-prediction-bars__wrapper app-mobile-prediction-bars__wrapper--side">
				<app-bar [title]="'mobile.prediction.win' | translate"
						 [percentValue]="stats?.tendency.team_1_win.percentage" [maxHeight]="maxHeight"></app-bar>
			</div>
			<div class="app-mobile-prediction-bars__wrapper app-mobile-prediction-bars__wrapper--side">
				<app-bar [title]="'mobile.prediction.win' | translate"
						 [percentValue]="stats?.tendency.team_2_win.percentage" [maxHeight]="maxHeight"></app-bar>
			</div>
		</div>
		<div class="app-mobile-prediction-dials">
			<ng-template [ngIf]="isSingleTeamMode">
				<span class="app-mobile-prediction-dials__headline" style="margin-top: 16px;">{{ 'mobile.prediction.high-score-top-score-single-team-headline' | translate:{ teamName: singleTeamName } }}</span>
				<app-multi-dial [values]="dialValuesForTeamNumber(singleTeamNumber)"
								[largeDialIndex]="null"></app-multi-dial>
			</ng-template>
			<ng-template [ngIf]="!isSingleTeamMode">
				<div class="app-mobile-prediction-dials__side">
					<span class="app-mobile-prediction-dials__headline">{{ 'mobile.prediction.high-score-top-score-headline' | translate }}</span>
					<app-multi-dial [values]="dialValuesForTeamNumber(1)" [largeDialIndex]="forceMoreDials ? 1 : null" [defaultSize]="120"></app-multi-dial>
				</div>
				<div class="app-mobile-prediction-dials__side">
					<span class="app-mobile-prediction-dials__headline">{{ 'mobile.prediction.high-score-top-score-headline' | translate }}</span>
					<app-multi-dial [values]="dialValuesForTeamNumber(2)" [largeDialIndex]="forceMoreDials ? 1 : null" [defaultSize]="120"></app-multi-dial>
				</div>
			</ng-template>
		</div>

	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileHighScorePredictionStatsComponent implements OnInit {

	@HostBinding('class') clazz = 'app-mobile-high-score-prediction-stats';

	@Input() stats: GameStats;
	@Input() isSingleTeamMode = false;
	@Input() singleTeamNumber: TeamNumber;
	@Input() singleTeamName: string;
	@Input() forceMoreDials = false;
	@Input() showDialSpacer = false;

	team1DialValues: DialValue[];
	team2DialValues: DialValue[];

	maxHeight = 70;

	constructor() {
	}

	ngOnInit() {
		this.team1DialValues = this.getDialValues(TeamNumber.One);
		this.team2DialValues = this.getDialValues(TeamNumber.Two);
	}

	dialValuesForTeamNumber(teamNumber: TeamNumber) {
		switch (teamNumber) {
			case TeamNumber.One:
				return this.team1DialValues;
			case TeamNumber.Two:
				return this.team2DialValues;
		}
	}

	private getDialValues(teamNumber: TeamNumber): DialValue[] {
		if (this.stats) {
			const key = `bounded_results_team_${teamNumber}`;
			const results = this.stats[key] as BoundResultPercentValue[];

			let filteredResults: BoundResultPercentValue[];
			if (this.isSingleTeamMode || this.forceMoreDials) {
				filteredResults = [results[0], results[2], results[4]];
			} else {
				filteredResults = [results[1], results[3]];
			}

			console.log(filteredResults);

			return filteredResults.map(result => {
				return {
					title: result.lower_bound + '',
					value: result.percentage,
					titleHighlighted: false
				};
			});
		}
	}
}
