import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {PredictionOrigin, Sponsor, SponsorBarSize, SponsorPlacementSettings} from '@inscouts/playersvote-models';
import {State} from '../state';
import {AnalyticsService} from '../../services/analytics.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
	selector: 'app-sponsor',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
        <ng-template [ngIf]="sponsor">
			<h6>Werbung</h6>
            <div (click)="openLink()" class="sponsor">
                <span class="sponsor__text" *ngIf="showPoweredBy">Powered by</span>
                <img src="{{ getLogoUrl() }}">
            </div>
        </ng-template>
        <img src="/assets/images/playersvote-logo-accent.svg" *ngIf="!sponsor">
	`
})
export class SponsorComponent implements OnInit {

	@Input() sponsor: Sponsor;
	@Input() showPoweredBy = false;
	@HostBinding('class') clazz = 'footer app-sponsor';

	@HostBinding('class.footer--no-sponsor')
	private get noSponsor() {
		return !this.sponsor;
	}

	@HostBinding('class.app-sponsor--large')
	private get isLarge() {
		if (this.sponsor && this.sponsor.bar_size) {
			return this.sponsor.bar_size === SponsorBarSize.Large;
		}

		return false;
	}

	@HostBinding('style.background-color')
	private get backgroundColor() {
		const placementSettings = this.getPlacementSettings();
		if (placementSettings && placementSettings.background_color) {
			return placementSettings.background_color;
		}

		return null;
	}

	@HostBinding('style.background-image')
	private get backgroundImage() {
		const placementSettings = this.getPlacementSettings();
		if (placementSettings && placementSettings.background_image_url) {
			return this.sanitizer.bypassSecurityTrustStyle(`url(${placementSettings.background_image_url})`);
		}

		return null;
	}

	constructor(private state: State,
				private sanitizer: DomSanitizer,
				private analyticsService: AnalyticsService) {
	}

	ngOnInit() {
		if (!this.sponsor) {
			return;
		}
		this.analyticsService.trackSponsorDelivery(this.sponsor.id, this.state.origin);
		if (this.state.origin !== PredictionOrigin.WIDGET) {
			this.analyticsService.trackSponsorView(this.sponsor.id, this.state.origin);
		} else {
			window.addEventListener('message', (event) => {
				if (event.data === 'playersvote-visible') {
					this.analyticsService.trackSponsorView(this.sponsor.id, this.state.origin);
				}
			});
		}
	}

	private getPlacementSettings(): SponsorPlacementSettings {
		if (this.sponsor) {
			const placementSettings = this.sponsor.placement_settings;
			const origin = this.state.origin;

			if (origin && placementSettings && placementSettings[origin]) {
				return placementSettings[origin];
			}
		}

		return null;
	}

	getLogoUrl() {
		const placementSettings = this.getPlacementSettings();
		if (placementSettings && placementSettings.active && placementSettings.logo_url) {
			return placementSettings.logo_url;
		}

		return this.sponsor.logo_url;
	}

	openLink() {
		if (this.sponsor.url) {
			this.analyticsService.trackSponsorClick(this.sponsor.id, this.state.origin);
			const win = window.open(this.sponsor.url, '_blank');
			win.focus();
		}
	}
}
