import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {GameUtils} from '../core/game-utils';
import {GameService} from '../services/game.service';
import {switchMap} from 'rxjs/operators';
import {SnackBarService} from '../services/snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {DialValue} from '../core/components/multi-dial.component';
import {StatsUtils} from '../core/components/stats-utils';
import {Game, GameSourceType, GameStats, Player, Team} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-mobile-rating',
	template: `
		<app-mobile-rate-team [team]="currentRatingTeam"
							  *ngIf="shouldTopMobileRateBeShown"
							  [finished]="finished && stats && isSingleTeamRating"
							  [barValues]="getBarValues()"
							  [mvp]="currentMvp"
							  [mvpDialValues]="getMvpDialValues()"
							  [rating]="currentRating"
							  (ratingChange)="rateCurrentTeam($event)"
							  (mvpChange)="selectMvpForCurrentTeam($event)"></app-mobile-rate-team>

		<div *ngIf="finished && stats && !isSingleTeamRating" class="app-mobile-rating__stats">
			<div class="app-mobile-rating__stats-side">
				<app-mobile-rate-team [team]="game.team_1"
									  *ngIf="!loadingStats"
									  [finished]="true"
									  [barValues]="getBarValues(game.team_1)"
									  [mvpDialValues]="getMvpDialValues(game.team_1)"
									  [slimMode]="true"
									  [rating]="game.prediction.rating_team_1"></app-mobile-rate-team>
			</div>
			<div class="app-mobile-rating__stats-side">
				<app-mobile-rate-team [team]="game.team_2"
									  *ngIf="!loadingStats"
									  [finished]="true"
									  [barValues]="getBarValues(game.team_2)"
									  [mvpDialValues]="getMvpDialValues(game.team_2)"
									  [slimMode]="true"
									  [rating]="game.prediction.rating_team_2"></app-mobile-rate-team>
			</div>
		</div>

		<div class="app-mobile__container app-mobile-rating__button-container" *ngIf="!finished && !loadingStats">
			<button class="app-mobile-button" [class.app-mobile-button--gray]="!isRatingComplete()" (click)="save()"
					[disabled]="saving || !isRatingComplete()" *ngIf="!finished">
				<span *ngIf="!isRatingComplete()"
					  class="button__text button__text--small">{{ 'mobile.rating.missing-rating' | translate
					}}</span>

				<ng-template [ngIf]="isRatingComplete()">
					<span class="button__text">{{ submitButtonRatingCompleteText }}</span>
				</ng-template>
			</button>
		</div>
	`
})
export class MobileRatingComponent implements OnInit {

	@HostBinding('class') clazz = 'app-mobile-rating';

	@Input() game: Game;

	stats: GameStats;
	finished = false;
	saving = false;
	loadingStats = false;

	ratingStep = RatingStep.TeamOne;
	private _mvpDialValuesTeam1: DialValue[];
	private _mvpDialValuesTeam2: DialValue[];

	constructor(private gameService: GameService, private snackBarService: SnackBarService, private translate: TranslateService) {
	}

	ngOnInit() {
		this.setRatingsStep(RatingStep.TeamOne);

		if (this.isRatingComplete()) {
			this.finished = true;
			this.loadingStats = true;

			return this.gameService.getStats(this.game.id)
				.subscribe(stats => {
					this.stats = stats;
					this.loadingStats = false;
				});
		}
	}

	get shouldTopMobileRateBeShown() {
		if (this.isSingleTeamRating) {
			return !this.loadingStats;
		}

		return !this.finished && !this.stats;
	}

	get submitButtonRatingCompleteText() {
		let key: string;
		if (this.saving) {
			key = this.translate.instant('general.saving');
		} else {
			key = !this.isSingleTeamRating && this.ratingStep === RatingStep.TeamOne ? 'general.next' : 'mobile.rating.submit';
		}

		return this.translate.instant(key);
	}

	get currentMvp(): Player {
		const team = this.currentRatingTeam;
		if (team === this.game.team_1) {
			return this.game.prediction.mvp_team_1;
		} else {
			return this.game.prediction.mvp_team_2;
		}
	}

	get currentRating() {
		const team = this.currentRatingTeam;
		if (team === this.game.team_1) {
			return this.game.prediction.rating_team_1;
		} else {
			return this.game.prediction.rating_team_2;
		}
	}

	rateCurrentTeam(rating: number) {
		if (!this.finished) {
			const team = this.currentRatingTeam;
			if (team === this.game.team_1) {
				this.game.prediction.rating_team_1 = rating;
			} else {
				this.game.prediction.rating_team_2 = rating;
			}
		}
	}

	selectMvpForCurrentTeam(player: Player) {
		const team = this.currentRatingTeam;
		if (team === this.game.team_1) {
			this.game.prediction.mvp_team_1 = player;
		} else {
			this.game.prediction.mvp_team_2 = player;
		}
	}

	get isSingleTeamRating() {
		return this.gameService.gameSource.type === GameSourceType.TEAM;
	}

	getBarValues(team?: Team) {
		if (this.stats) {
			const gameTeam = team || this.currentRatingTeam;

			if (gameTeam === this.game.team_1) {
				return this.stats.rating.team_1.distribution;
			} else {
				return this.stats.rating.team_2.distribution;
			}
		}

		return [];
	}

	getMvpDialValues(team?: Team): DialValue[] {
		if (this.stats) {
			const gameTeam = team || this.currentRatingTeam;

			if (gameTeam === this.game.team_1) {
				if (!this._mvpDialValuesTeam1) {
					this._mvpDialValuesTeam1 = StatsUtils.getMvpDialValues(this.stats.mvp.team_1, this.game.prediction.mvp_team_1);
				}

				return this._mvpDialValuesTeam1;
			} else {
				if (!this._mvpDialValuesTeam2) {
					this._mvpDialValuesTeam2 = StatsUtils.getMvpDialValues(this.stats.mvp.team_2, this.game.prediction.mvp_team_2);
				}

				return this._mvpDialValuesTeam2;
			}
		}

		return null;
	}

	get currentRatingTeam(): Team {
		if (this.gameService.gameSource.type === GameSourceType.TEAM) {
			return GameUtils.getMainTeamForGameSource(this.game, this.gameService.gameSource);
		}

		switch (this.ratingStep) {
			case RatingStep.TeamOne:
				return this.game.team_1;
			case RatingStep.TeamTwo:
				return this.game.team_2;
		}
	}

	isRatingComplete() {
		if (!this.isSingleTeamRating && this.ratingStep === RatingStep.TeamOne) {
			return GameUtils.isTeam1RatingComplete(this.game);
		}
		return GameUtils.isRatingComplete(this.game, this.gameService.gameSource);
	}

	private setRatingsStep(step: RatingStep) {
		this.ratingStep = step;
	}

	save() {
		if (!this.isSingleTeamRating && this.ratingStep === RatingStep.TeamOne) {
			this.setRatingsStep(RatingStep.TeamTwo);
			return;
		}

		this.saving = true;
		this.gameService.rateGame(this.game.id, this.game.prediction)
			.pipe(
				switchMap(() => {
					this.saving = false;
					this.finished = true;
					return this.gameService.getStats(this.game.id);
				})
			).subscribe((stats) => {
				this.stats = stats;
			},
			() => {
				this.saving = false;
				this.finished = false;
				this.snackBarService.error(this.translate.instant('rating.error'));
			}
		);
	}
}

export enum RatingStep {
	TeamOne,
	TeamTwo
}
