import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DialValue} from '../core/components/multi-dial.component';
import {AnimationUtils} from '../core/animation-utils';
import {StatsUtils} from '../core/components/stats-utils';
import {Game, GameStats, MvpValue, Player} from '@inscouts/playersvote-models';
import {TeamNumber} from '../models/game.model';

@Component({
	selector: 'app-widget-rating-controls',
	template: `
		<div class="app-widget-rating__team-row" [class.app-widget-rating__team-row--single]="singleTeamNumber">
			<div class="app-widget-rating__team-side" *ngIf="!singleTeamNumber || singleTeamNumber === teamNumberOne">
				<app-widget-rate-team [finished]="finished"
									  [barValues]="stats?.rating.team_1.distribution"
									  [headerText]="game.team_1.name"
									  [(rating)]="game.prediction.rating_team_1"
									  [singleTeamMode]="isSingleTeamMode"
									  [(mvp)]="game.prediction.mvp_team_1"
									  [players]="team1"></app-widget-rate-team>

				<ng-template [ngIf]="finished && stats">
					<div class="app-widget-rating__best-player" >{{ 'widget.rating.best-player' | translate }}</div>
					<app-multi-dial [values]="team1Values" [@fadeIn]="'shown'"
									[largeDialIndex]="1"></app-multi-dial>
				</ng-template>
			</div>

			<div class="app-widget-rating__team-side" *ngIf="!singleTeamNumber || singleTeamNumber === teamNumberTwo">
				<app-widget-rate-team [finished]="finished"
									  [barValues]="stats?.rating.team_2.distribution"
									  [headerText]="game.team_2.name"
									  [(rating)]="game.prediction.rating_team_2"
									  [singleTeamMode]="isSingleTeamMode"
									  [(mvp)]="game.prediction.mvp_team_2"
									  [players]="team2"></app-widget-rate-team>

				<ng-template [ngIf]="finished && stats">
					<div class="app-widget-rating__best-player">{{ 'widget.rating.best-player' | translate }}</div>
					<app-multi-dial [values]="team2Values" [@fadeIn]="'shown'"
									[largeDialIndex]="1"></app-multi-dial>
				</ng-template>
			</div>
		</div>
	`,
	animations: [
		AnimationUtils.fadeIn()
	]
})

export class WidgetRatingControlsComponent implements OnInit, OnChanges {

	@Input() finished = false;
	@Input() game: Game;
	@Input() stats: GameStats;
	@Input() team1: Player[];
	@Input() team2: Player[];
	@Input() singleTeamNumber: TeamNumber = null;

	teamNumberOne = TeamNumber.One;
	teamNumberTwo = TeamNumber.Two;

	team1Values: DialValue[];
	team2Values: DialValue[];

	constructor() {
	}

	ngOnInit() {
		this.team1Values = this.getTeam1Values();
		this.team2Values = this.getTeam2Values();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.stats) {
			this.team1Values = this.getTeam1Values();
			this.team2Values = this.getTeam2Values();
		}
	}

	get isSingleTeamMode() {
		return !!this.singleTeamNumber;
	}

	private getTeam1Values(): DialValue[] {
		if (this.stats) {
			return this.getTeamDialValues(this.stats.mvp.team_1, this.game.prediction.mvp_team_1);
		}
	}

	private getTeam2Values(): DialValue[] {
		if (this.stats) {
			return this.getTeamDialValues(this.stats.mvp.team_2, this.game.prediction.mvp_team_2);
		}
	}

	private getTeamDialValues(values: MvpValue[], selectedMvp: Player): DialValue[] {
		return StatsUtils.getMvpDialValues(values, selectedMvp).slice(0, 3);
	}
}
