import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
	selector: 'app-result-input',
	template: `
		<input [disabled]="disabled" #input [type]="disabled ? 'text' : 'number'" min="0" step="1" pattern="\\d*"
			   [ngModel]="result"
			   (ngModelChange)="resultChanged($event)" placeholder="-">
	`
})
export class ResultInputComponent implements OnInit {

	@HostBinding('class') clazz = 'result-input-wrapper';

	@Input() result: number;
	@Output() resultChange = new EventEmitter<number>();
	@Input() disabled = false;

	@ViewChild('input', { static: true }) inputField;

	constructor() {
	}

	ngOnInit() {
	}

	resultChanged(number: string) {
		if (number === '') {
			this.result = null;
		} else {
			this.result = parseInt(number, 10);
		}

		this.resultChange.emit(this.result);
	}

	focusInput() {
		if (this.inputField && !this.disabled) {
			this.inputField.nativeElement.focus();
		}
	}
}
