import {AfterViewInit, Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {PollService} from '../services/poll.service';
import {State} from '../core/state';
import {AuthService} from '../services/auth.service';
import {Battle, Poll, PredictionOrigin,} from '@inscouts/playersvote-models';
import { SnackBarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import {BattleService} from "../services/battle.service";

declare const window: any;

@Component({
	selector: 'app-battle-overlay',
	template: `
		<div class="battle-overlay">
			<div class="battle-overlay__backdrop" #backdrop (click)="onBackdropClick($event)">
				<main class="battle-overlay__main">
					<mat-spinner *ngIf="loading && !(state.authError$ | async)" class="app-battle__spinner"></mat-spinner>
					<div *ngIf="poll && !loading && !(state.authError$ | async)">
						<div class="battle-overlay__poll">
							<div class="battle-overlay__poll-day">{{ poll.available_start | date: 'dd' : '' :  translate.currentLang }}</div>
							<div class="battle-overlay__poll-month">{{ poll.available_start | date: 'MMMM' : '' :  translate.currentLang }}</div>
						</div>
						<app-poll [battle]="battle" [poll]="poll" [isPreview]="isPreview" *ngIf="poll && !loading && !(state.authError$ | async)"></app-poll>
						<app-sponsor *ngIf="poll?.sponsor" [sponsor]="poll?.sponsor"></app-sponsor>
					</div>
					<app-unauthenticated *ngIf="state.authError$ | async"></app-unauthenticated>
				</main>
			</div>
		</div>
	`
})
export class BattleOverlayComponent implements OnInit, AfterViewInit {

	@HostBinding('class') clazz = 'app-battle-overlay';

	@ViewChild('backdrop', { static: true }) backdrop: ElementRef;

	loading: boolean;
	battleId: number;
	pollId: number;
	poll: Poll;
	battle: Battle;
	isPreview: boolean = false;

	constructor(
		private route: ActivatedRoute,
		public state: State,
		private authService: AuthService,
		public translate: TranslateService,
		private snackbar: SnackBarService,
		private pollService: PollService,
		private battleService: BattleService,
	) {
		this.state.origin = PredictionOrigin.WIDGET;
		this.authService.initToken(this.state.apiKey);
	}

	async  ngOnInit() {
		this.route.queryParams
			.subscribe(params => {
					this.isPreview = params.preview;
				}
			);
		this.route.params
			.pipe(first())
			.subscribe(params => {
				this.battleId = params.battleId;
				this.pollId = params.pollId;

				this.loading = true;


				this.battleService.getBattle(this.battleId)
					.subscribe(battle => {
						this.pollService.getPoll(this.pollId)
							.subscribe(poll => {
									this.poll = poll;
									//console.log(battle);
									const currentPoll = battle.polls.filter(e => e.id === poll.id)[0];
									this.poll.sponsor = battle.sponsor;
									this.poll.sponsor_id = battle.sponsor_id;
									this.poll.available_start = currentPoll.available_start;
									this.poll.available_end = currentPoll.available_end;
									this.poll.instant_feedback = battle.instant_feedback;
									this.poll.question_countdown_time = battle.question_countdown_time;
									this.poll.joker = battle.joker;
									this.poll.online = true; // Fraglich, aber es könnte theoretisch sein, das der Poll auf Entwuf ist
									this.loading = false;
									//console.log(this.poll);
								},
								() => {
									this.snackbar.error(this.translate.instant('errors.load-data'));
								});
					},
					() => {
						this.snackbar.error(this.translate.instant('errors.load-data'));
					});

			});
	}



	ngAfterViewInit() {
		const interval = setInterval(() => {
			if (window.parentIFrame) {
				window.parentIFrame.sendMessage('resize');
				clearInterval(interval);
			}
		}, 300);
	}

	onBackdropClick($event: MouseEvent) {
		if($event.target === this.backdrop.nativeElement) {
			this.closeOverlay()
		}
	}
	closeOverlay() {
		window.parent.postMessage({
			type: 'CLOSE_OVERLAY',
		}, '*');
	}

}
