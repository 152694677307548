export enum TeamNumber {
	One = 1,
	Two = 2
}

export enum GameSourceKey {
	Bundesliga = 'austrian_bundesliga1718',
	Bundesliga1819 = 'austrian_bundesliga1819',
	Bundesliga2021 = 'austrian_bundesliga2021',
	AustrianFriendlies = 'austrian_friendlies',
	WordlCup2018 = 'world_cup_2018'
}
