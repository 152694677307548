import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {Game, GameSource, GameSourceType, Player, PredictionOrigin, Team} from '@inscouts/playersvote-models';

import {GameService} from '../services/game.service';
import {SnackBarService} from '../services/snackbar.service';
import {WebviewsOverlayComponent} from './webviews-overlay.component';
import {GameUtils} from '../core/game-utils';
import {ModalService} from '../services/modal.service';
import {AnalyticsService} from '../services/analytics.service';
import {ClientGameStatus} from '../../../core/core';

@Component({
	selector: 'app-rating',
	templateUrl: './rating.component.html'
})
export class RatingComponent implements OnInit, OnChanges {

	@Input() game: Game;
	@Input() gameSource: GameSource;

	saving = false;
	team1: Player[] = [];
	team2: Player[] = [];

	ratingTranslateKey = 'rating';

	constructor(private gameService: GameService,
				private snackBarService: SnackBarService,
				private modalService: ModalService,
				private gameUtils: GameUtils,
				private analyticsService: AnalyticsService,
				private translate: TranslateService) {
	}

	ngOnInit() {
		// *** For debug purposes ***
		/*this.game.start = '2018-05-25T11:26:06.591Z';
		this.game.meta_data.rating_end = '2018-05-25T19:51:40.591Z';
		this.game.status = OptaGameStatus.Playing;*/

		const players = GameService.getTeamPlayers(this.game);
		this.team1 = players.team1;
		this.team2 = players.team2;
		this.analyticsService.trackGamePageView(this.gameSource, this.game.id, PredictionOrigin.BOT);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.gameSource && changes.gameSource.currentValue) {
			if (this.gameSource.type === GameSourceType.TEAM) {
				this.ratingTranslateKey = 'rating_singular';
			}
		}
	}

	save() {
		this.saving = true;

		this.gameService.rateGame(this.game.id, this.game.prediction)
			.subscribe(
				() => {
					this.saving = false;
					const ref = this.modalService.create(WebviewsOverlayComponent, {
						parameters: {
							headerText: this.gameUtils.getLeagueAndRoundText(this.game, this.gameSource.key),
							title: this.translate.instant(`${this.ratingTranslateKey}.success-title`),
							subTitle: this.translate.instant(`${this.ratingTranslateKey}.success-text`),
							onClickOk: () => {
								ref.close();
							}
						}
					});
				},
				() => {
					this.saving = false;
					this.snackBarService.error(this.translate.instant('rating.error'));
				}
			);
	}

	isTeamShownForRating(team: Team) {
		if (this.gameSource.type === GameSourceType.TEAM) {
			return this.gameSource.team_opta_id === team.opta_id;
		}

		return true;
	}

	isButtonDisabled(): boolean {
		return this.saving || !this.isRatingComplete() || !this.isGameInRatingPhase() || this.isGameRatingPhaseOver();
	}

	isRatingComplete(): boolean {
		return GameUtils.isRatingComplete(this.game, this.gameSource);
	}

	isGameRatingPhaseOver(): boolean {
		return GameUtils.getGameStatus(this.game) === ClientGameStatus.Over;
	}

	isGameInRatingPhase(): boolean {
		return GameUtils.getGameStatus(this.game) === ClientGameStatus.Rating;
	}

	isGameOverButRateable(): boolean {
		return !!this.game.meta_data.rating_end;
	}
}
