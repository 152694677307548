import {Component, NgZone} from '@angular/core';

import {State} from '../core/state';
import {AuthService} from '../services/auth.service';

@Component({
	selector: 'app-webviews',
	template: `
		<div class="main-background"></div>
		<ng-template>
			<router-outlet></router-outlet>
		</ng-template>
	`
})
export class WebviewsComponent {

	constructor(private zone: NgZone, private authService: AuthService, private state: State) {
		this.authService.initToken(this.state.apiKey);
	}

}
