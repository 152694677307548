import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {GameUtils} from '../core/game-utils';
import {Game, GameStats, PlayersvoteGames} from '@inscouts/playersvote-models';
import {GameService} from '../services/game.service';

@Component({
	selector: 'app-widget-post',
	template: `
		<header class="widget-header">
			{{ 'widget.post.title' | translate }}

			<div class="app-widget-post__result" *ngIf="gameHasResult">
				<span class="app-widget-post__result-headline">{{ 'widget.post.result' | translate }}</span>
				<span class="app-widget-post__result-score">{{ game.team_1_score }}:{{ game.team_2_score }}</span>
			</div>
		</header>
		<div class="app-box">
			<app-prediction [game]="game" [disabled]="true" [showInputs]="false"></app-prediction>
			<mat-spinner *ngIf="!stats" class="app-widget__spinner"></mat-spinner>

			<app-widget-prediction-stats [stats]="stats" [prediction]="game.prediction" [showDraw]="canGameEndInDraw"
										 [showDials]="!isGameHighScoring"
										 *ngIf="stats"></app-widget-prediction-stats>

			<div style="width: 100%; margin-bottom: 24px;" *ngIf="isGameHighScoring"></div>
		</div>


		<ng-template [ngIf]="game.meta_data?.featured">
			<header class="widget-header app-widget-post__rating-headline" *ngIf="stats?.rating.total > 0">
				{{ 'widget.post.title-rating' | translate }}
			</header>

			<mat-spinner *ngIf="!stats" class="app-widget__spinner"></mat-spinner>
			<div class="app-widget-rating__container" style="margin-bottom: 8px;" *ngIf="game && stats && stats?.rating.total > 0">
				<app-widget-rating-controls [game]="game" [stats]="stats" [finished]="true"
											[singleTeamNumber]="singleTeamNumber"></app-widget-rating-controls>
			</div>
		</ng-template>
	`
})

export class WidgetPostComponent implements OnInit {

	@HostBinding('class') clazz = 'app-widget-post';
	@Input() game: Game;
	@Input() stats: GameStats;

	constructor(private gameService: GameService) {
	}

	ngOnInit() {
		console.log(this.stats);
	}

	get canGameEndInDraw() {
		return GameUtils.canGameEndInDraw(this.game);
	}

	get singleTeamNumber() {
		return GameUtils.getSingleTeamNumber(this.singleTeam, this.game);
	}

	get singleTeam() {
		return PlayersvoteGames.getMainTeamForGameSource(this.game, this.gameService.gameSource);
	}

	get gameHasResult() {
		return this.game.team_1_score !== null && this.game.team_2_score !== null;
	}

	get isGameHighScoring() {
		return GameUtils.isGameHighScoringGame(this.game);
	}
}
