import {AfterViewInit, Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {BattleService} from '../services/battle.service';
import {State} from '../core/state';
import {AuthService} from '../services/auth.service';
import { Battle, PredictionOrigin, } from '@inscouts/playersvote-models';
import { SnackBarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

declare const window: any;

@Component({
	selector: 'app-battle-root',
	template: `

		<div *ngIf="!(state.authError$ | async) && (!battle?.is_draft || isPreview)" class="app-battle app-root-height-container">
			<mat-spinner *ngIf="loading" class="app-battle__spinner"></mat-spinner>
			<div *ngIf="!loading && battle">
				<!-- <h1 *ngIf="battle.name">{{battle.name}}</h1> -->

				<battle-rail [battle]="battle" [isPreview]="isPreview"></battle-rail>
			</div>
		</div>
		<app-unauthenticated *ngIf="state.authError$ | async"></app-unauthenticated>
	`
})
export class BattleRootComponent implements OnInit, AfterViewInit {

	@HostBinding('class') clazz = 'app-battle-root';

	loading: boolean;
	battleId: string;
	battle: Battle;
	isPreview: boolean = false;

	constructor(
		private route: ActivatedRoute,
		public state: State,
		private authService: AuthService,
		private translate: TranslateService,
		private snackbar: SnackBarService,
		private battleService: BattleService,
	) {
		this.state.origin = PredictionOrigin.WIDGET;
		this.authService.initToken(this.state.apiKey);

	}

	ngOnInit() {
		this.route.queryParams
			.subscribe(params => {
					//this.isPreview = params.preview && params.preview === this.state.apiKey;
					this.isPreview = true; // @HACK für Testzwecke
				}
			);
		this.route.params
			.pipe(first())
			.subscribe(params => {
				this.battleId = params.battleId;

				this.loading = true;
				this.battleService.getBattle(params.battleId)
					.subscribe(battle => {
						this.loading = false;
						this.battle = battle;

						},
					() => {
						this.loading = false;
						this.snackbar.error(this.translate.instant('errors.load-data'));
					})
			});
	}

	ngAfterViewInit() {
		const interval = setInterval(() => {
			if (window.parentIFrame) {
				window.parentIFrame.sendMessage('resize');
				clearInterval(interval);
			}
		}, 300);
	}


}
