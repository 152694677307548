import {GameSourceType, SportType} from '@inscouts/playersvote-models';

export interface DemoState {
	frameType: DemoFrameType;
	gameSourceType: GameSourceType;
	sportType: SportType;
	gameStatus: ClientGameStatus;
	pollType: DemoPollType;
	sponsor: number;
	locale: string;
}

export enum DemoFrameType {
	Widget = 'widget',
	Poll = 'poll',
	Battle = 'battle',
	Mobile = 'mobile',
}

export enum ClientGameStatus {
	Prediction = 'prediction',
	Rating = 'rating',
	Over = 'over',
	ActivityLocked = 'activity_locked',
}

export enum DemoPollType {
	Single,
	SinglePlus,
	Multi,
	MultiPlus,
	Rating,
	RatingPlus,
}
