import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {SnackBarService} from '../services/snackbar.service';
import {GameService} from '../services/game.service';
import {ModalService} from '../services/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {GameUtils} from '../core/game-utils';
import {switchMap} from 'rxjs/operators';
import {AnimationUtils} from '../core/animation-utils';
import {Game, GameSourceType, GameStats, PlayersvoteGames, Prediction} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-widget-prediction',
	template: `
		<header class="widget-header">
			<ng-template [ngIf]="finished">{{ 'widget.prediction.title-finished' | translate }}</ng-template>
			<ng-template [ngIf]="!finished">{{ 'widget.prediction.title' | translate }}</ng-template>
		</header>
		<app-prediction [game]="game" [disabled]="finished" [focusFirstInputOnInit]="false"></app-prediction>
		<div class="app-widget-prediction__bottom"
			 [class.app-widget-prediction__bottom--no-padding]="isGameHighScoring">
			<button class="widget-button" [class.widget-button--gray]="!isPredictionComplete()" (click)="save()"
					[disabled]="saving || !isPredictionComplete()" *ngIf="!finished">
				<span *ngIf="!isPredictionComplete()"
					  class="button__text button__text--small">{{ 'widget.prediction.missing-prediction' | translate
					}}</span>

				<ng-template [ngIf]="isPredictionComplete()">
					<span *ngIf="!saving" class="button__text">{{ 'widget.prediction.submit' | translate }}</span>
					<span *ngIf="saving" class="button__text">{{ 'general.saving' | translate }}</span>
				</ng-template>
			</button>

			<mat-spinner *ngIf="loadingStats" class="app-widget__spinner"></mat-spinner>


			<app-mobile-high-score-prediction-stats [stats]="stats"
													[forceMoreDials]="true"
													[isSingleTeamMode]="isSingleTeamMode"
													[singleTeamNumber]="singleTeamNumber"
													[singleTeamName]="singleTeamName"
													[@fadeIn]="'shown'"
													[showDialSpacer]="true"
													*ngIf="finished && stats && isGameHighScoring"></app-mobile-high-score-prediction-stats>

			<app-widget-prediction-stats [stats]="stats"
										 [@fadeIn]="'shown'"
										 [prediction]="game.prediction"
										 [showDraw]="canGameEndInDraw"
										 *ngIf="finished && stats && !isGameHighScoring"></app-widget-prediction-stats>
		</div>
	`,
	animations: [
		AnimationUtils.fadeIn()
	]
})

export class WidgetPredictionComponent implements OnInit {


	@HostBinding('class') clazz = 'app-widget-prediction';
	@Input() game: Game;

	stats: GameStats;

	saving = false;
	loadingStats = false;
	finished = false;

	constructor(private snackBarService: SnackBarService,
				private gameService: GameService,
				private gameUtils: GameUtils,
				private modalService: ModalService,
				private translate: TranslateService) {
	}

	ngOnInit() {
		if (this.isPredictionComplete()) {
			this.finished = true;
			this.loadingStats = true;

			return this.gameService.getStats(this.game.id)
				.subscribe(stats => {
					this.loadingStats = false;
					this.stats = stats;
				});
		}
	}

	get canGameEndInDraw(): boolean {
		return GameUtils.canGameEndInDraw(this.game);
	}

	get isGameHighScoring() {
		return GameUtils.isGameHighScoringGame(this.game);
	}

	get isSingleTeamMode() {
		return this.gameService.gameSource.type === GameSourceType.TEAM;
	}

	get singleTeamNumber() {
		return GameUtils.getSingleTeamNumber(this.singleTeam, this.game);
	}

	get singleTeam() {
		return PlayersvoteGames.getMainTeamForGameSource(this.game, this.gameService.gameSource);
	}

	get singleTeamName() {
		const mainTeam = this.singleTeam;
		if (mainTeam) {
			return mainTeam.name;
		}

		return '';
	}

	save() {
		const predictions: Prediction[] = [];

		this.game.prediction.game_id = this.game.id;
		predictions.push(this.game.prediction);

		this.saving = true;
		this.gameService.savePredictions(predictions)
			.pipe(
				switchMap(() => {
					this.finished = true;

					return this.gameService.getStats(this.game.id);
				})
			).subscribe((stats) => {
				this.saving = false;
				this.stats = stats;
			},
			() => {
				this.saving = false;
				this.finished = false;
				this.loadingStats = false;
				this.snackBarService.error(this.translate.instant('prediction.save-error'));
			});
	}

	isPredictionComplete() {
		return GameUtils.isPredictionComplete(this.game);
	}
}
