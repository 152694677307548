import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-success-overlay',
	template: `
		<div class="box">
			<header class="box__header">
				{{ headerText }}
			</header>
			<div class="box__content overlay__content">
				<div class="overlay__icon" [class.overlay__icon--error]="isTypeError()" role="presentation">
					<app-icon [name]="iconName"></app-icon>
				</div>
				<h2 class="overlay__title">{{ title }}</h2>
				<p class="overlay__subtitle">{{ subTitle }}</p>

				<button class="button" (click)="onClickOk()">{{ 'general.ok' | translate }}</button>
			</div>
		</div>
	`,
	host: {
		'class': 'overlay app-webviews-overlay'
	}
})
export class WebviewsOverlayComponent {

	@Input() type: OverlayType = OverlayType.Success;
	@Input() headerText: string;
	@Input() title: string;
	@Input() subTitle: string;
	@Input() onClickOk: () => void;

	get iconName() {
		switch (this.type) {
			case OverlayType.Success:
				return 'checkmark';
			case OverlayType.Error:
				return 'cross';
		}
	}

	isTypeError(): boolean {
		return this.type === OverlayType.Error;
	}
}

export enum OverlayType {
	Success = 'success' as any,
	Error = 'error' as any,
}
