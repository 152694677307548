import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {DialValue} from '../core/components/multi-dial.component';
import {StatsUtils} from '../core/components/stats-utils';
import {GameUtils} from '../core/game-utils';
import {GameService} from '../services/game.service';
import {Game, GameSourceType, GameStats, Team} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-mobile-post',
	template: `
		<div class="app-mobile-post__tendency" *ngIf="stats">
			<div class="app-mobile-post__tendency-side">
				<app-dial [value]="stats.tendency.team_1_win.percentage" [size]="140"></app-dial>
			</div>

			<div class="app-mobile-post__tendency-center">
				<div>{{ 'mobile.post.winner-headline' | translate }}</div>
			</div>

			<div class="app-mobile-post__tendency-side">
				<app-dial [value]="stats.tendency.team_2_win.percentage" [size]="140"></app-dial>
			</div>
		</div>
		<div class="app-mobile-bar">
			{{ 'mobile.post.rating-headline' | translate }}
		</div>
		<app-mobile-rate-team [team]="singleRatingTeam"
							  [showTopBar]="false"
							  [showBottomMvpBar]="true"
							  *ngIf="stats && isSingleRating"
							  [finished]="true"
							  [barValues]="currentTeamBarValues"
							  [mvpDialValues]="mvpDialValues"></app-mobile-rate-team>

		<div class="app-mobile-rating__stats" *ngIf="stats && !isSingleRating">
			<div class="app-mobile-rating__stats-side">
				<app-mobile-rate-team [team]="game.team_1"
									  [showTopBar]="false"
									  [showBottomMvpBar]="false"
									  [finished]="true"
									  [barValues]="getBarValuesForTeam(game.team_1)"
									  [singleMvpMode]="true"
									  [mvpDialValues]="mvpDialValuesTeam1"></app-mobile-rate-team>
			</div>
			<div class="app-mobile-rating__stats-side">
				<app-mobile-rate-team [team]="game.team_2"
									  [showTopBar]="false"
									  [showBottomMvpBar]="false"
									  [finished]="true"
									  [barValues]="getBarValuesForTeam(game.team_2)"
									  [singleMvpMode]="true"
									  [mvpDialValues]="mvpDialValuesTeam2"></app-mobile-rate-team>
			</div>
		</div>
	`
})
export class MobilePostComponent implements OnInit {

	@HostBinding('class') clazz = 'app-mobile-post';

	@Input() game: Game;
	@Input() stats: GameStats;

	private _mvpDialValues: DialValue[];

	mvpDialValuesTeam1: DialValue[];
	mvpDialValuesTeam2: DialValue[];


	constructor(private gameService: GameService) {
	}

	ngOnInit() {
		this.mvpDialValuesTeam1 = this.getMvpDialValuesForTeam(this.game.team_1);
		this.mvpDialValuesTeam2 = this.getMvpDialValuesForTeam(this.game.team_2);
	}

	get isSingleRating() {
		return this.gameService.gameSource.type === GameSourceType.TEAM;
	}

	get singleRatingTeam(): Team {
		if (this.isSingleRating) {
			return GameUtils.getMainTeamForGameSource(this.game, this.gameService.gameSource);
		}

		return this.game.team_1;
	}


	get currentTeamBarValues() {
		const team = this.singleRatingTeam;
		return this.getBarValuesForTeam(team);
	}

	getBarValuesForTeam(team: Team) {
		if (!this.stats) {
			return [];
		}

		if (team === this.game.team_1) {
			return this.stats.rating.team_1.distribution;
		} else {
			return this.stats.rating.team_2.distribution;
		}
	}

	getMvpDialValuesForTeam(team: Team): DialValue[] {
		if (this.stats) {
			if (team === this.game.team_1) {
				return StatsUtils.getMvpDialValues(this.stats.mvp.team_1, this.game.prediction.mvp_team_1);
			} else {
				return StatsUtils.getMvpDialValues(this.stats.mvp.team_2, this.game.prediction.mvp_team_2);
			}
		}
	}

	get mvpDialValues(): DialValue[] {
		if (this.stats && !this._mvpDialValues) {
			const team = this.singleRatingTeam;
			this._mvpDialValues = this.getMvpDialValuesForTeam(team);
		}

		return this._mvpDialValues;
	}
}
