import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	OnInit
} from '@angular/core';

@Component({
	selector: 'app-bar',
	template: `
		<span class="app-bar__value">{{ roundedPercent }}%</span>
		<div class="app-bar__bar" [style.height.px]="height">
			<div class="app-bar__bar-inner" [style.height.px]="innerHeight"></div>
		</div>
		<span class="app-bar__title" *ngIf="title">{{ title }}</span>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarComponent implements OnInit, AfterViewInit {

	@HostBinding('class') clazz = 'app-bar';

	@Input() title: string;
	@Input() percentValue: number;
	@Input() maxHeight = 100;

	innerHeight = 0;

	constructor(private cdf: ChangeDetectorRef) {
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.innerHeight = this.height;
			this.cdf.detectChanges();
		}, 300);
	}

	get height() {
		if (this.percentValue === 0) {
			return 1;
		}

		return this.percentValue / 100 * this.maxHeight;
	}

	get roundedPercent() {
		return Math.round(this.percentValue);
	}
}
