<div class="app-poll__header" *ngIf="isHitMissRatioPoll">
	<div class="app-poll-outro__header">
		<div class="app-poll__header-title">{{ 'new-polls.hit-miss-title' | translate:{
			percentage: correctPercentage,
			correctAnswers: correctAnswers,
			totalAnswers: totalAnswers
		} }}</div>
		<div *ngIf="seconds">{{ 'new-polls.outro-done-in-seconds' | translate:{seconds: seconds} }}</div>
	</div>
</div>
<div class="app-box app-poll-outro__box">
	<ng-template [ngIf]="!isHitMissRatioPoll">
		<h2 class="app-poll-outro__headline">{{ 'new-polls.outro-thanks' | translate }}</h2>
		<p *ngIf="seconds">{{ 'new-polls.outro-done-in-seconds' | translate:{seconds: seconds} }}</p>
	</ng-template>
	<div *ngIf="isHitMissRatioPoll" class="app-poll-outro__ratio">
		<div class="app-poll-outro__ratio-item" *ngFor="let question of tempQuestions; let i = index">
			<span>{{ i + 1 }}</span>
			<app-poll-badge [correct]="wasQuestionCorrectlyAnswered(question)"></app-poll-badge>
		</div>
	</div>
</div>
