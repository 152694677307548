import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {combineLatest} from 'rxjs';
import {first} from 'rxjs/operators';

import {Game, GameStats, PredictionOrigin} from '@inscouts/playersvote-models';

import {GameService} from '../services/game.service';
import {SnackBarService} from '../services/snackbar.service';
import {State} from '../core/state';
import {AuthService} from '../services/auth.service';
import {GameUtils} from '../core/game-utils';
import {MobileRatingComponent, RatingStep} from './mobile-rating.component';
import {AnalyticsService} from '../services/analytics.service';
import {ClientGameStatus} from '../../../core/core';

@Component({
	selector: 'app-mobile-widget',
	template: `
		<app-mobile-header [game]="game" *ngIf="game" [showResults]="isGameOverOrLocked(game)"
						   [highlightedTeamIndex]="highlightedTeamIndex"></app-mobile-header>
		<div class="app-mobile__content" *ngIf="source$ | async">
			<app-mobile-prediction [game]="game" *ngIf="game && isGameInPredictionPhase(game)"></app-mobile-prediction>
			<app-mobile-rating #rating [game]="game" *ngIf="isRatingShown"
							   (ratingStepChange)="ratingStepChanged($event)"></app-mobile-rating>
			<app-mobile-post [game]="game" [stats]="stats" *ngIf="game && isGameOverOrLocked(game)"></app-mobile-post>
		</div>
		<app-sponsor [sponsor]="game?.meta_data?.sponsor" *ngIf="game"></app-sponsor>
	`
})
export class MobileWidgetComponent implements OnInit {

	@HostBinding('class') clazz = 'app-mobile';

	game: Game;
	stats: GameStats;
	source$;

	ratingStep = null;

	private ratingComp: MobileRatingComponent;

	@ViewChild('rating') set rating(ratingComp: MobileRatingComponent) {
		this.ratingComp = ratingComp;
	}

	constructor(private route: ActivatedRoute,
				private gameService: GameService,
				private state: State,
				private authService: AuthService,
				private analyticsService: AnalyticsService,
				private translate: TranslateService,
				private snackBarService: SnackBarService) {
		this.state.origin = PredictionOrigin.MOBILE;
		this.authService.initToken(this.state.apiKey);
		this.source$ = this.gameService.gameSource$;
	}

	ngOnInit() {
		combineLatest(this.route.params, this.source$)
			.pipe(first())
			.subscribe(([params, source]) => {
				if (params.gameId) {
					this.analyticsService.trackGamePageView(source, params.gameId, PredictionOrigin.MOBILE);
					this.fetchGame(params.gameId);
					this.fetchStats(params.gameId);
				}
			});
	}

	ratingStepChanged(ratingStep: RatingStep) {
		this.ratingStep = ratingStep;
	}

	get highlightedTeamIndex() {
		if (!this.ratingComp) {
			return null;
		}

		if (this.ratingComp.finished || !this.isRatingShown) {
			return null;
		}

		switch (this.ratingComp.ratingStep) {
			case RatingStep.TeamOne:
				return 0;
			case RatingStep.TeamTwo:
				return 1;
			default:
				return null;
		}
	}

	get isRatingShown() {
		return this.game && this.isGameInRatingPhase(this.game);
	}

	private fetchStats(gameId: number) {
		this.gameService.getStats(gameId)
			.subscribe(stats => {
				this.stats = stats;
			});
	}

	private fetchGame(id: string) {
		this.gameService.getGame(id)
			.subscribe(
				game => {
					this.game = game;
				},
				() => {
					this.snackBarService.error(this.translate.instant('errors.default'));
				});
	}

	isGameInPredictionPhase(game: Game): boolean {
		return GameUtils.getGameStatus(game) === ClientGameStatus.Prediction;
	}

	isGameInRatingPhase(game: Game): boolean {
		return GameUtils.getGameStatus(game) === ClientGameStatus.Rating;
	}

	isGameOverOrLocked(game: Game): boolean {
		const status = GameUtils.getGameStatus(game);
		return status === ClientGameStatus.Over || status === ClientGameStatus.ActivityLocked;
	}
}
