import {AfterViewInit, Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {combineLatest, Subject} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

import {Game, GameSource, GameStats, PredictionOrigin} from '@inscouts/playersvote-models';

import {GameService} from '../services/game.service';
import {SnackBarService} from '../services/snackbar.service';
import {AuthService} from '../services/auth.service';
import {State} from '../core/state';
import {GameUtils} from '../core/game-utils';
import {ApiService} from '../services/api.service';
import {AnimationUtils} from '../core/animation-utils';
import {AnalyticsService} from '../services/analytics.service';
import {WidgetPredictionComponent} from './widget-prediction.component';
import {ClientGameStatus} from '../../../core/core';

declare const window: any;

@Component({
	selector: 'app-widget',
	template: `
		<div class="app-root-box" [class.app-root-box--bottom-padding]="!doesGameHaveSponsor"
			 *ngIf="game && (gameSource$ | async)" [@fadeIn]="'shown'">
			<div class="app-widget__root">
				<ng-template [ngIf]="!quotaExceeded">
					<app-widget-prediction [game]="game" *ngIf="isGameInPredictionPhase(game)"
										   #prediction></app-widget-prediction>
					<app-widget-rating [game]="game" [gameSource]="gameSource$ | async"
									   *ngIf="isGameInRatingPhase(game)"></app-widget-rating>
					<app-widget-post *ngIf="isGameOverOrLocked(game)" [game]="game" [stats]="stats"></app-widget-post>
				</ng-template>


				<div class="app-widget__quota-notice" *ngIf="quotaExceeded">
					{{ 'widget.quota-exceeded' | translate }}
				</div>
			</div>

			<app-sponsor [sponsor]="game?.meta_data?.sponsor"
						 *ngIf="game"></app-sponsor>
			<div class="general-ad" *ngIf="shouldAdmiralBeShown" style="text-align:center;padding-top:8px;">
				<a href="https://www.admiral-bundesliga.at/#tippstar" target="_blank" title="Admiral Bundesliga Sixpack"><img src="/assets/images/ad/admiral-sixpack.jpg" alt="Admiral Sixpack" style="max-width:100%;height:auto" /></a>
			</div>
		</div>
		<app-unauthenticated *ngIf="state.authError$ | async"></app-unauthenticated>

		<app-poll *ngIf="game && game.poll" [poll]="game.poll" style="margin-top: 16px;"></app-poll>
	`,
	animations: [
		trigger('fadeInFlex', [
			state('shown', style({opacity: 1, display: 'flex'})),
			state('hidden', style({opacity: 0, display: 'none'})),
			transition('hidden <=> shown', [
				animate('0.2s ease')
			]),
			transition('void => shown', [
				style({opacity: 0, display: 'none'}),
				animate(300)
			]),
		]),
		AnimationUtils.fadeIn()
	],
})

export class WidgetComponent implements OnInit, AfterViewInit {

	@HostBinding('class') clazz = 'app-widget app-root-height-container';

	game: Game;
	stats: GameStats;
	gameSource$: Subject<GameSource>;
	quotaExceeded = false;
	isDemo = false;

	@ViewChild('prediction') predictionComp: WidgetPredictionComponent;

	constructor(private activatedRoute: ActivatedRoute,
				private snackBarService: SnackBarService,
				private translate: TranslateService,
				private analyticsService: AnalyticsService,
				private authService: AuthService,
				public state: State,
				private gameService: GameService) {
		this.gameSource$ = this.gameService.gameSource$;
		this.authService.initToken(this.state.apiKey);
		this.state.origin = PredictionOrigin.WIDGET;

		this.isDemo = this.gameService.isDemo;

		ApiService.tooManyRequests.subscribe(() => {
			this.quotaExceeded = true;
		});
	}

	ngOnInit() {
		combineLatest(this.activatedRoute.params, this.gameSource$)
			.pipe(first())
			.subscribe(([params, gameSource]) => {
				if (params.gameId) {
					this.analyticsService.trackGamePageView(gameSource, params.gameId, PredictionOrigin.WIDGET);
					this.fetchGame(params.gameId);
					this.fetchStats(params.gameId);
				}
			});
	}

	ngAfterViewInit() {
		const interval = setInterval(() => {
			if (window.parentIFrame) {
				window.parentIFrame.sendMessage('resize');
				clearInterval(interval);
			}
		}, 300);
	}

	get shouldAdmiralBeShown() {
		return this.gameSource$
			.pipe(map(source => {
				return source.account_id === 1; // Sky Sport Austria in production environment
			}))
	}

	get doesGameHaveSponsor() {
		return this.game && this.game.meta_data && this.game.meta_data.sponsor;
	}

	private fetchStats(gameId: number) {
		this.gameService.getStats(gameId)
			.subscribe(stats => {
				this.stats = stats;
			});
	}

	private fetchGame(id: string) {
		this.gameService.getGame(id)
			.subscribe(
				game => {
					this.game = game;
				},
				() => {
					this.snackBarService.error(this.translate.instant('errors.default'));
				});
	}

	isPredictionOnPostScreen() {
		if (this.predictionComp) {
			return GameUtils.isPredictionComplete(this.game) && !!this.predictionComp.stats;
		}

		return false;
	}

	isGameInPredictionPhase(game: Game): boolean {
		return GameUtils.getGameStatus(game) === ClientGameStatus.Prediction;
	}

	isGameInRatingPhase(game: Game): boolean {
		return GameUtils.getGameStatus(game) === ClientGameStatus.Rating;
	}

	isGameOverOrLocked(game: Game): boolean {
		const status = GameUtils.getGameStatus(game);
		return status === ClientGameStatus.Over || status === ClientGameStatus.ActivityLocked;
	}
}
