import {AfterViewInit, Component, HostBinding, OnInit} from '@angular/core';
import {Poll} from '@inscouts/playersvote-models';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {first} from 'rxjs/operators';
import {PollService} from '../services/poll.service';
import {State} from '../core/state';
import {AuthService} from '../services/auth.service';

declare const window: any;

@Component({
	selector: 'app-poll-root',
	template: `
		<div class="app-root-height-container">
			<app-poll [poll]="poll" *ngIf="poll && !loading && !(state.authError$ | async)" [isPreview]="isPreview"></app-poll>
			<app-sponsor *ngIf="poll?.sponsor" [sponsor]="poll?.sponsor"></app-sponsor>
			<mat-spinner *ngIf="loading && !(state.authError$ | async)" class="app-poll-root__spinner"></mat-spinner>
			<app-unauthenticated *ngIf="state.authError$ | async"></app-unauthenticated>

		</div>


	`
})
export class PollRootComponent implements OnInit, AfterViewInit {

	@HostBinding('class') clazz = 'app-poll-root';

	poll: Poll;
	loading = false;
	isPreview = false;

	constructor(private route: ActivatedRoute, private pollService: PollService, public state: State, private authService: AuthService) {
		this.authService.initToken(this.state.apiKey);
	}

	ngOnInit() {
		this.route.queryParams
			.subscribe(params => {
				this.isPreview = params.preview && params.preview === this.state.apiKey;
				}
			);

		this.route.params
			.pipe(first())
			.subscribe(params => {
				const id = params.id;
				if (id) {
					this.loading = true;
					this.pollService.getPoll(id)
						.subscribe(poll => {
							this.poll = poll;
							this.loading = false;
						});
				}
			});
	}

	ngAfterViewInit() {
		const interval = setInterval(() => {
			if (window.parentIFrame) {
				window.parentIFrame.sendMessage('resize');
				clearInterval(interval);
			}
		}, 300);
	}
}
