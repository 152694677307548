import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	Output, SimpleChanges,
	ViewChild
} from '@angular/core';
import {GameUtils} from '../core/game-utils';
import {AnimationUtils} from '../core/animation-utils';
import {DialValue} from '../core/components/multi-dial.component';
import {Player, RatingValue, Team} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-mobile-rate-team',
	template: `
		<div class="app-mobile-bar app-mobile-rate-team__scale-bar" *ngIf="!finished && showTopBar">
			<span class="app-mobile-rate-team__scale-bar-side">{{ 'mobile.rating.scale-low' | translate }}</span>
			<span>{{ 'mobile.rating.team-headline' | translate:{teamName: team.name} }}</span>
			<span class="app-mobile-rate-team__scale-bar-side">{{ 'mobile.rating.scale-high' | translate }}</span>
		</div>
		<app-rating-scale [rating]="rating"
						  (ratingChange)="rateTeam($event)"
						  [finished]="finished"
						  [barValues]="barValues"></app-rating-scale>

		<ng-template [ngIf]="!finished">
			<div class="app-mobile-bar">
				{{ 'mobile.rating.mvp-headline' | translate }}
			</div>
			<select #select required [ngModel]="mvp?.id || ''" (ngModelChange)="selectMvp($event)">
				<option value="" disabled selected hidden>{{ 'widget.rating.mvp-placeholder' | translate }}</option>
				<option *ngFor="let player of team.players" [value]="player.id">{{ getPlayerName(player) }}</option>
			</select>
		</ng-template>

		<ng-template [ngIf]="finished && mvpDialValues">
			<div class="app-mobile-bar" *ngIf="showBottomMvpBar">
				{{ 'general.mvp' | translate }}
			</div>
			<div class="app-mobile-rating__dials">
				<app-multi-dial [values]="topDial" *ngIf="topDial" [@fadeIn]="'shown'" style="margin-bottom: 16px;" class="app-mobile-rating__dials-top"></app-multi-dial>
				<app-multi-dial [values]="bottomDials" [@fadeIn]="'shown'" [largeDialIndex]="null"></app-multi-dial>
			</div>
		</ng-template>

	`,
	animations: [
		AnimationUtils.fadeIn()
	]
})
export class MobileRateTeamComponent implements OnInit, OnChanges {

	@HostBinding('class') clazz = 'app-mobile-rate-team';

	@Input() showTopBar = true;
	@Input() showBottomMvpBar = false;
	@Input() finished = false;
	@Input() headerText: string;
	@Input() barValues: RatingValue[];

	@Input() rating: number;
	@Output() ratingChange = new EventEmitter<number>();

	@Input() mvpDialValues: DialValue[];
	@Input() team: Team;
	@Input() mvp: Player = null;
	@Input() slimMode = false;
	@Input() singleMvpMode = false;
	@Output() mvpChange = new EventEmitter<Player>();

	@ViewChild('select') selectBox: ElementRef;

	topDial: DialValue[];
	bottomDials: DialValue[];

	constructor() {

	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.mvpDialValues && this.mvpDialValues.length) {
			if (this.singleMvpMode) {
				this.bottomDials = this.mvpDialValues.slice(0, 1);
				return;
			}

			this.topDial = this.mvpDialValues.slice(0, 1);

			if (this.slimMode) {
				this.bottomDials = this.mvpDialValues.slice(1, 2);
			} else {
				this.bottomDials = this.mvpDialValues.slice(1, 4);
			}
		}
	}

	rateTeam(rating: number) {
		this.ratingChange.next(rating);
	}

	getPlayerName(player: Player): string {
		return GameUtils.getPlayerDisplayName(player);
	}

	selectMvp(id: string) {
		this.mvp = this.getPlayer(parseInt(id, 10));
		this.mvpChange.next(this.mvp);
	}

	getPlayer(id: number) {
		return this.team.players.find(player => {
			return player.id == id;
		});
	}
}
