import {Component, HostBinding, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
	selector: 'app-unauthenticated',
	template: `
		<div class="app-unauthenticated__inner">
			<p>{{ 'unauthenticated.message' | translate }}</p>
			<button mat-flat-button color="primary" (click)="buttonClicked()">{{ 'unauthenticated.login' | translate }}</button>
		</div>
	`,
})
export class UnauthenticatedComponent implements OnInit {

	@HostBinding('class') clazz = 'app-unauthenticated';

	constructor(private authService: AuthService) {
	}

	ngOnInit() {
	}

	buttonClicked() {
		this.authService.sendAuthTriggerMessage();
	}
}
