import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-multi-dial',
	template: `
		<app-dial *ngFor="let value of sortedValues" [value]="value.value" [title]="value.title"
				  [titleHighlighted]="value.titleHighlighted" [size]="getDialSize(value)"></app-dial>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiDialComponent implements OnInit {

	@HostBinding('class') clazz = 'app-multi-dial';

	@Input() values: DialValue[];
	@Input() largeDialIndex = 0;
	@Input() defaultSize = DialSize.Small;

	sortedValues: DialValue[];

	constructor() {
	}

	ngOnInit() {
		this.sortedValues = this.getSortedValues(this.values);
	}

	getDialSize(value: DialValue): number {
		if (this.largeDialIndex !== null) {
			const index = this.sortedValues.indexOf(value);
			if (index === this.largeDialIndex) {
				return DialSize.Large;
			}
		}

		return this.defaultSize;
	}

	private getSortedValues(values: DialValue[]) {
		if (!this.largeDialIndex) {
			return values;
		}

		const copy = [...values];
		copy.sort((a, b) => {
			return a.value > b.value ? -1 : 1;
		});

		if (copy.length < 2) {
			return copy;
		}

		return copy.map((value, index) => {
			switch (index) {
				case 0:
					return copy[1];
				case 1:
					return copy[0];
				default:
					return copy[index];
			}
		});
	}
}

export interface DialValue {
	title: string;
	titleHighlighted: boolean;
	value: number;
}

export enum DialSize {
	Large = 170,
	Small = 130,
	XSmall = 120,
}
