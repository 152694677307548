import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {GamePoll, GameSource} from '@inscouts/playersvote-models';
import {GameSourceKey} from '../models/game.model';

@Injectable()
export class PollUtils {

	constructor(private translate: TranslateService) {

	}

	static getPollStatus(poll: GamePoll): PollStatus {
		const deadline = new Date(poll.deadline_date);

		if (new Date() > deadline) {
			return PollStatus.Over;
		}

		return PollStatus.Active;
	}

	getPollTitle(source: GameSource) {
		switch (source.key) {
			case GameSourceKey.WordlCup2018:
				return this.translate.instant('polls.world-cup-title');
		}

		return this.translate.instant('polls.default-title');
	}

}

export enum PollStatus {
	Active,
	Over
}
