import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {PlayersvoteGames, PollQuestion, ScaleDisplay} from '@inscouts/playersvote-models';
import {TranslateService} from '@ngx-translate/core';

import {AuthService} from '../../services/auth.service';
import {PollService} from '../../services/poll.service';
import {State} from '../state';

@Component({
	selector: 'app-rating-legend',
	template: `
		<ul class="app-widget-rating__legend" *ngIf="!this.scale">
				<li *ngFor="let rating of ratings">{{ getDisplayTextForNumber(rating) }} = {{ getTextForRating(rating) }}</li>
		</ul>
		<ul class="app-widget-rating__legend" *ngIf="this.scale">
			<li>{{getDisplayFirst()}}</li>
			<li>{{getDisplayLast()}}</li>
		</ul>
	`
})
export class RatingLegendComponent implements OnInit {

	@HostBinding('class') clazz = 'app-rating-legend';

	readonly ratings = PlayersvoteGames.RATING_NUMBERS;

	@Input() question: PollQuestion;

	scale: ScaleDisplay;
	range: string[];

	constructor(private translate: TranslateService, private authService: AuthService, public state: State, private pollService: PollService) {
		this.authService.initToken(this.state.apiKey);
	}

	ngOnInit() {
		if (this.question.scale_id === null) {
			this.pollService.getDefaultScale().subscribe(scale => {
				this.scale = scale;
				this.range = scale.type.split('-');
			});
		} else {
			this.pollService.getScales(this.question.scale_id).subscribe(scale => {
				this.scale = scale;
				this.range = scale.type.split('-');
			});
		}

	}

	getDisplayTextForNumber(rating: number) {
		return PlayersvoteGames.getDisplayTextForRatingNumber(rating, {});
	}

	getTextForRating(rating: number) {
		return this.translate.instant(`widget.rating.rating.${rating}`);
	}

	getDisplayFirst() {
		if (this.scale !== undefined) {
			return this.range[0] + ' = ' + this.scale.first_option_label;
		}


	}
	getDisplayLast() {
		if (this.scale !== undefined) {
			return this.range[1] + ' = ' + this.scale.last_option_label;
		}
	}
}
