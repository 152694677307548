import {GameUtils} from '../game-utils';
import {DialValue} from './multi-dial.component';
import {MvpValue, Player} from '@inscouts/playersvote-models';

export class StatsUtils {


	static getMvpDialValues(values: MvpValue[], selectedMvp: Player): DialValue[] {
		return values.map(value => {
			return {
				title: GameUtils.getShortPlayerDisplayName(value.player),
				value: value.percentage,
				titleHighlighted: selectedMvp ? value.player.id === selectedMvp.id : false
			};
		});
	}

}
