import {Component, OnInit, HostBinding, Input, AfterViewInit} from '@angular/core';

@Component({
	selector: 'app-poll-bar',
	template: ``
})
export class PollBarComponent implements OnInit, AfterViewInit {

	@HostBinding('class') clazz = 'app-poll-bar';

	@HostBinding('style.width.%')
	private get getWidth() {
		if (!this.show) {
			return 0;
		}

		return this.percentage;
	}

	@Input() percentage: number;

	show = false;

	constructor() {
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.show = true;
		}, 200);
	}
}
