import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-header',
	template: `
		<header class="game-header">
			<div class="game-header__main">
				<h1 class="game-header__title">{{ title }}</h1>
				<p class="game-header__subtitle" *ngIf="subtitle">
					{{ subtitle }}
				</p>
			</div>
			<ng-content></ng-content>
		</header>
	`
})

export class HeaderComponent implements OnInit {

	@Input() title: string;
	@Input() subtitle: string;

	constructor() {
	}

	ngOnInit() {
	}
}
