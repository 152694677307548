import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	ViewChild
} from '@angular/core';
import {ResultInputComponent} from '../core/components/result-input.component';
import {Game} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-mobile-prediction-inputs',
	template: `
		<app-result-input [disabled]="disabled" [(result)]="game.prediction.result_team_1"
						  #leftPrediction></app-result-input>
		<app-result-input [disabled]="disabled" [(result)]="game.prediction.result_team_2"></app-result-input>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class MobilePredictionInputsComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() game: Game;
	@Input() disabled = false;
	@Input() small = false;
	@Input() showInputs = true;
	@ViewChild('leftPrediction', { static: true }) leftPrediction: ResultInputComponent;

	@HostBinding('class') clazz = 'app-mobile-prediction-inputs';

	@HostBinding('class.app-mobile-prediction-inputs--small')
	private get isSmall() {
		return this.small;
	}

	constructor() {
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		this.focusFirstInput();
	}

	ngOnChanges() {
		this.focusFirstInput();
	}

	private focusFirstInput() {
		this.leftPrediction.focusInput();
	}
}
