import {take} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import {GameService} from '../services/game.service';
import {ActivatedRoute, Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SnackBarService} from '../services/snackbar.service';
import {Subject} from 'rxjs';
import {Game, GameSource} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-voting',
	template: `
		<div class="main-container">
			<app-rating [game]="game" [gameSource]="gameSource$ | async" *ngIf="game"></app-rating>
		</div>
		<app-sponsor [sponsor]="game?.meta_data?.sponsor" *ngIf="game?.meta_data?.sponsor"></app-sponsor>
	`
})
export class VotingComponent implements OnInit {

	game: Game;
	gameSource$: Subject<GameSource>;

	@HostBinding('class') clazz = 'main-component';

	constructor(private gameService: GameService,
				private activatedRoute: ActivatedRoute,
				private snackBarService: SnackBarService,
				private translate: TranslateService) {
		this.activatedRoute.params.pipe(
			take(1))
			.subscribe((params: Params) => {
				this.fetchGame(params['id']);
				if (params.source) {
					this.gameService.loadSourceInfo(params.source);
					this.gameSource$ = this.gameService.gameSource$;
				}
			});
	}

	ngOnInit() {
	}

	private fetchGame(id: string) {
		this.gameService.getGame(id)
			.subscribe(
				game => {
					this.game = game;
				},
				() => {
					this.snackBarService.error(this.translate.instant('errors.default'));
				});
	}
}
