import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {GameUtils} from '../core/game-utils';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Player, PlayersvoteGames, RatingValue} from '@inscouts/playersvote-models';
import {GameService} from '../services/game.service';

@Component({
	selector: 'app-widget-rate-team',
	template: `
		<div class="box">
			<div class="box__header">
				{{ headerText }}
			</div>
			<div class="box__content">
				<div class="app-widget-rate-team__scale">
					<ul>
						<li class="app-widget-rate-team__scale-item" *ngFor="let number of ratingNumbers"
							[class.app-widget-rate-team__scale-item--selected]="isNumberSelected(number)"
							(click)="rateTeam(number)">
							<span class="app-widget-rate-team__scale-item-text">{{ getDisplayTextForNumber(number) }}</span>

							<app-bar *ngIf="barValues" [percentValue]="getValueForNumber(number)" [maxHeight]="72"
									 [@fadeIn]="animationState"></app-bar>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div *ngIf="singleTeamMode && !finished" class="app-widget-rate-team__mvp-headline">
			{{ 'general.mvp' | translate }}
		</div>

		<select required [ngModel]="mvp?.id || ''" (ngModelChange)="selectMvp($event)">
			<option value="" disabled selected hidden>{{ 'widget.rating.mvp-placeholder' | translate }}</option>
			<option *ngFor="let player of players" [value]="player.id">{{ getPlayerName(player) }}</option>
		</select>
	`,
	animations: [
		trigger('fadeIn', [
			state('shown', style({opacity: 1})),
			state('hidden', style({opacity: 0})),
			transition('hidden => shown', [
				animate('300ms 300ms ease')
			]),
			transition('void => shown', [
				style({opacity: 0}),
				animate('300ms 300ms ease')
			]),
		])
	]
})

export class WidgetRateTeamComponent implements OnInit {

	@HostBinding('class') clazz = 'app-widget-rate-team';

	@HostBinding('class.app-widget-rate-team--finished')
	private get isFinished() {
		return this.finished && this.barValues;
	}

	@Input() finished = false;
	@Input() headerText: string;
	@Input() barValues: RatingValue[];
	@Input() singleTeamMode = false;

	@Input() rating: number;
	@Output() ratingChange = new EventEmitter<number>();

	@Input() players: Player[];
	@Input() mvp: Player = null;
	@Output() mvpChange = new EventEmitter<Player>();

	ratingNumbers = [1, 2, 3, 4, 5, 6];

	constructor(private gameService: GameService) {
	}

	ngOnInit() {
	}

	getDisplayTextForNumber(number: number) {
		return PlayersvoteGames.getDisplayTextForRatingNumber(number, this.gameService.gameSource);
	}

	get animationState() {
		return this.isFinished ? 'shown' : 'hidden';
	}

	isNumberSelected(number: number) {
		return this.rating === number;
	}

	rateTeam(number: number) {
		if (!this.finished) {
			this.rating = number;
			this.ratingChange.emit(this.rating);
		}
	}

	getPlayerName(player: Player): string {
		return GameUtils.getPlayerDisplayName(player);
	}

	selectMvp(id: string) {
		this.mvp = this.getPlayer(parseInt(id, 10));
		this.mvpChange.next(this.mvp);
	}

	getPlayer(id: number) {
		console.log(this.players, id);
		return this.players.find(player => {
			return player.id == id;
		});
	}

	getValueForNumber(number: number) {
		return GameUtils.getValueForRatingNumber(number, this.barValues);
	}
}
