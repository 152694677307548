import {map, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable, ReplaySubject} from 'rxjs';
import {GameService} from './game.service';
import {GamePoll, GamePollAnswer, Player, Team} from '@inscouts/playersvote-models';

@Injectable()
export class GamePollService {

	teams$ = new ReplaySubject<Team[]>(1);

	constructor(private apiService: ApiService, private gameService: GameService) {

	}

	initTeamCache() {
		this.gameService.getTeams()
			.subscribe(teams => {
				this.teams$.next(teams);
			});
	}

	getPlayers(teamId: number): Observable<Player[]> {
		return this.teams$.pipe(
			take(1),
			map(teams => {
				return teams.find(team => {
					return team.id === teamId;
				}).players;
			}),);
	}

	getPoll(id: string): Observable<GamePoll> {
		return this.apiService.get(`/bot/gamepolls/${id}`).pipe(
			map((poll: GamePoll) => {
				for (const question of poll.questions) {
					if (!question.answer) {
						question.answer = {
							game_poll_question_id: question.id
						};
					}
				}

				return poll;
			}));
	}

	savePoll(poll: GamePoll) {
		const answers: GamePollAnswer[] = poll.questions.map(question => {
			question.answer.game_poll_question_id = question.id;
			return question.answer;
		});
		return this.apiService.post(`/bot/gamepolls/${poll.id}/answers`, answers);
	}

}
