import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {Game, Team} from '@inscouts/playersvote-models';
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-mobile-header',
	template: `
		<div class="mobile-header__bar">
			{{ game.start | date: 'EEEE, dd. MMMM YYYY, HH:mm' : '' :  translateService.currentLang }}
		</div>
		<div class="mobile-header__team-wrapper">
			<div class="mobile-header__team" *ngFor="let team of teams" [ngClass]="getClassesForTeam(team)">
				<img src="{{ team.logo_url }}" alt="">
				<div class="mobile-header__team-bottom">
					<span class="mobile-header__name">{{ team.name }}</span>
					<span class="mobile-header__result" *ngIf="showResults">{{ getScoreForTeam(team) }}</span>
				</div>
			</div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class MobileHeaderComponent implements OnInit {

	@Input() game: Game;
	@Input() showResults = false;
	@Input() highlightedTeamIndex = null;
	@HostBinding('class') clazz = 'mobile-header app-background';

	constructor(
		public translateService: TranslateService,
	) {
	}

	ngOnInit() {
	}

	get teams(): Team[] {
		return [this.game.team_1, this.game.team_2];
	}

	getScoreForTeam(team: Team) {
		if (team === this.game.team_1) {
			return this.game.team_1_score;
		}

		return this.game.team_2_score;
	}

	getClassesForTeam(team: Team) {
		if (this.highlightedTeamIndex === null) {
			return [];
		}

		const index = this.teams.indexOf(team);
		if (index === this.highlightedTeamIndex) {
			return ['mobile-header__team--highlighted'];
		} else {
			return ['mobile-header__team--grayscale'];
		}
	}


}
