import {Injectable} from '@angular/core';

import {
	Poll,
	PollJokerSlug,
	PollQuestionAnswerStats,
	PollQuestionAnswerUserStats,
	PollUserInteractionType,
	ScaleDisplay
} from '@inscouts/playersvote-models';
import {Observable} from 'rxjs';

import {ApiService} from './api.service';

@Injectable()
export class PollService {
	constructor(private apiService: ApiService) {}

	getDefaultScale(): Observable<ScaleDisplay> {
		return this.apiService.get(`/bot/scales/default`);
	}

	getScales(id: number): Observable<ScaleDisplay> {
		return this.apiService.get(`/bot/scales/${id}`);
	}

	getPoll(id: number): Observable<Poll> {
		return this.apiService.get(`/bot/polls/${id}`);
	}

	logInteraction(pollId: number, type: PollUserInteractionType): Observable<void> {
		const path = `/bot/polls/${pollId}/interactions`;
		return this.apiService.post<void>(path, {type});
	}

	submitAnswer(answer: SubmitAnswerRequest) {
		const path = `/bot/polls/${answer.pollId}/questions/${answer.questionId}`;

		const submit = this.apiService.post<SubmitAnswerResponse>(path, {
			answer_index: answer.answerIndex,
			consumed_joker_items: answer.consumed_joker_items,
		});

		// submit 'completed' interaction
		if (answer.lastQuestionAnswered) {
			this.logInteraction(answer.pollId, PollUserInteractionType.COMPLETED)
				.subscribe(x => console.log('Completed: log completed', x))
		}

		return submit;
	}

	getAnswerStats(pollId: number, questionId: number) {
		const path = `/bot/polls/${pollId}/questions/${questionId}`;
		return this.apiService.get<PollQuestionAnswerStats[]>(path);
	}

	getPollAnswered(pollId: number) {
		const path = `/bot/polls/${pollId}/answered`;
		return this.apiService.get<{date: string}>(path);
	}

	updateUser(obj: UpdateUserData) {
		return this.apiService.patch('/bot/user', obj);
	}

	getPollQuestionAnswerStats(pollId: number, questionId: number) {
		return this.apiService.get<PollQuestionAnswerUserStats[]>(
			`/bot/polls/${pollId}/questions/${questionId}/answers`
		);
	}
}

export interface UpdateUserData {
	email: string;
	phone: string;
	allowed_data_use: boolean;
	subscribed_newsletter: boolean;
}

export interface SubmitAnswerRequest {
	pollId: number;
	questionId: number;
	answerIndex: number;
	consumed_joker_items: PollJokerSlug;
	lastQuestionAnswered: boolean;
}

export interface SubmitAnswerResponse {
    answers_stats: PollQuestionAnswerUserStats[];
    consumed_joker_items: PollJokerSlug[];
}
