import {Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';

import {
	Battle,
	ClientPoll,
	PollHeadlineTheme,
	PollJokerSlug,
	PollQuestion,
	PollQuestionAnswer,
	PollQuestionAnswerUserStats,
	PollUserInteractionType,
	PredictionOrigin
} from '@inscouts/playersvote-models';

import {TranslateService} from '@ngx-translate/core';
import {State} from '../core/state';
import {StyleUtils} from '../core/style.utils';
import {AnalyticsService} from '../services/analytics.service';

import {PollService} from '../services/poll.service';
import {SnackBarService} from '../services/snackbar.service';
import {PollAdditionalInfoFormComponent} from './poll-additional-info-form.component';
import {PollEmailFormComponent} from './poll-email-form.component';

@Component({
	selector: 'app-poll',
	templateUrl: 'poll.component.html'
})
export class PollComponent implements OnInit {
	@HostBinding('class') clazz = 'app-poll app-root-box';

	@Input() poll: ClientPoll;
	@Input() battle: Battle;
	@Input() isPreview: boolean = false;
	currentQuestion: PollQuestion;
	currentQuestionNumber: number = 0;
	submitting = false;

	selectedAnswer: PollQuestionAnswer;
	selectedAnswers: PollQuestionAnswer[] = [];
	stats: PollQuestionAnswerUserStats[];

	timeOver = false;

	showEmailOptin = false;
	emailSuccess = false;
	emailSubmitting = false;

	showAdditionalInfo = false;
	additionalInfoSuccess = false;
	additionalInfoSubmitting = false;

	isIntro = true;
	interactionStarted = false;

	showOutro = false;
	timeInSeconds = 0;
	private timer: number;
	private questionTimer: number;
	questionTimeSeconds = 0;
	extraTenSeconds = 0;

	hasAvailableJokers: boolean = false;
	showJokers: boolean = false;
	availableJokers: Map<string, boolean> = new Map();
	public jokerTypes = JokerType;
	isUsingAudienceJoker: boolean = false;
	currentActiveJoker = null;
	consumedJokerItems: PollJokerSlug;
	alternativeQuestion: PollQuestionAnswer;
	alternativeQuestionIndex = null;

	@HostBinding('class.app-poll--headline-light')
	get isLight() {
		return (
			this.poll.style &&
			this.poll.style.pollHeadlineTheme === PollHeadlineTheme.Light
		);
	}

	@ViewChild('emailForm') emailForm: PollEmailFormComponent;
	@ViewChild('additionalInfoForm') additionalInfoForm: PollAdditionalInfoFormComponent;

	constructor(
		private pollService: PollService,
		private snackbar: SnackBarService,
		private analyticsService: AnalyticsService,
		private state: State,
		private translate: TranslateService,
		private ref: ElementRef,
	) {
		this.state.origin = PredictionOrigin.WIDGET;
	}

	ngOnInit() {
		if (this.poll.style) {
			StyleUtils.styleForAccountStyle(this.poll.style);
		}

		this.resortQuestions();
		if (this.poll.questions.length <= 1) {
			this.start(this.poll.intro_enabled);
		} else {
			this.isIntro = this.poll.intro_enabled;
			if (!this.isIntro) {
				this.setCurrentQuestion(this.poll.questions[0]);
				this.ref.nativeElement.addEventListener('mouseenter', () => this.startInteraction())
			}
		}

		if (this.battle) {
			this.poll.instant_feedback = this.battle.instant_feedback;
			this.poll.joker = this.battle.joker;
			this.poll.sponsor = this.battle.sponsor;
			this.poll.sponsor_id = this.battle.sponsor_id;
			this.poll.question_countdown_time = this.battle.question_countdown_time;
			this.poll.email_opt_in_enabled = this.battle.email_opt_in_enabled;
		}


		if (this.poll.joker) {
			Object.entries(this.poll.joker)
				.filter(([k, v]) => v.is_selected)
				.forEach(([k, v]) => this.availableJokers.set(k, false));
			if (this.availableJokers.size > 0) {
				this.hasAvailableJokers = true;
			}

		}


		this.analyticsService.trackPollPageView(this.poll.id);
	}

	/**
	 * resort Questions, move alternative question to the end
	 */
	resortQuestions() {
		this.poll.questions.sort((a, b) => {
			if (a.is_alternative_question == true) {
				return 1;
			}
			if (b.is_alternative_question == true) {
				return -1;
			}
			return 0;
		});

	}

	start(isAutoStart?: boolean) {
		if (!isAutoStart) {
			this.analyticsService.trackPollInteraction(this.poll.id);
		}
		this.setCurrentQuestion(this.poll.questions[0]);
		this.isIntro = false;
		this.startInteraction();

		if (this.poll.questions.length >= 1) {

			this.questionTimeSeconds = this.poll.question_countdown_time;
			this.timer = setInterval(() => {
				this.timeInSeconds++;
			}, 1000);

			this.questionTimer = setInterval(() => {


				if (this.questionTimeSeconds < 1) {
					clearInterval(this.questionTimer);
				} else {
					if (!this.showJokers && this.extraTenSeconds < 1) {
						this.questionTimeSeconds -= 1;
					} else {
						this.extraTenSeconds -= 1;
					}
				}
			}, 1000);
		}
		// this.setCurrentQuestion(null);
		// this.showOutro = true;
	}

	restart() {
		this.setCurrentQuestion(null);
		this.showOutro = false;
		this.timeOver = false;

		this.showEmailOptin = false;
		this.emailSuccess = false;
		this.emailSubmitting = false;

		this.showAdditionalInfo = false;
		this.additionalInfoSuccess = false;
		this.additionalInfoSubmitting = false;

		this.alternativeQuestionIndex = null;
		this.selectedAnswers = [];

		if (this.poll.joker) {
			Object.entries(this.poll.joker)
				.filter(([k, v]) => v.is_selected)
				.forEach(([k, v]) => this.availableJokers.set(k, false));
			if (this.availableJokers.size > 0) {
				this.hasAvailableJokers = true;
			}
		}

		this.poll.questions.filter(g => g.question_type === "QUESTION").forEach(q => {
			q.answers.forEach(a => a.disabled = false);
		});

		this.start();
	}

	get pollBackgroundImage() {
		if (this.poll.style && this.poll.style.pollBackgroundImageUrl) {
			return `url(${this.poll.style.pollBackgroundImageUrl})`;
		}
		return null;
	}

	get isPollUnavailable() {
		return !this.isPreview && (this.isPollOver || this.isPollNotAvailableYet);
	}

	get isPollNotAvailableYet() {
		if (this.poll.online && this.poll.available_start) {
			return (
				new Date().getTime() <
				new Date(this.poll.available_start).getTime()
			);
		}

		return !this.poll.online;
	}

	get isPollOver() {
		if (this.poll.available_end) {
			return (
				new Date().getTime() >
				new Date(this.poll.available_end).getTime()
			);
		}

		return false;
	}

	get isNextAvailable() {
		if (this.poll.questions.length <= 1 && !this.poll.email_opt_in_enabled && !this.poll.additional_information_enabled) {
			return false;
		}


		return (
			!this.submitting &&
			(this.stats ||
				this.timeOver ||
				(this.showOutro && (this.poll.email_opt_in_enabled || this.poll.additional_information_enabled)))
		);
	}

	get isRestartButtonShown() {
		return this.showOutro && !this.poll.email_opt_in_enabled && !this.poll.additional_information_enabled;
	}

	get currentQuestionIndex() {
		return this.poll.questions.indexOf(this.currentQuestion);
	}


	get numberOfQuestions() {
		return this.poll.questions.filter( q => !q.is_alternative_question && q.question_type === 'QUESTION').length;
	}

	get numberOfElements(){
		return this.poll.questions.filter( q => !q.is_alternative_question).length;
	}

	get isButtonQuestion() {
		if (this.currentQuestion) {
			return this.currentQuestion.show_answers_as_buttons;
		}

		return false;
	}

	/**
	 * @deprecated
	 */
	get isSponsoredQuestion() {
		return false; // currently always false, as there is no possibility to turn this on/off - JL 20.05.2022
		if (this.currentQuestion) {
			return this.currentQuestion.is_sponsored;
		}

		return false;
	}


	get isLastQuestion() {
		if (this.alternativeQuestion) {
			this.alternativeQuestion = null;
			return this.alternativeQuestionIndex >= this.numberOfElements - 1;
		}
		return this.currentQuestionIndex >= this.numberOfElements - 1;
	}

	selectAnswer(answer: PollQuestionAnswer) {
		this.startInteraction()
		this.analyticsService.trackPollInteraction(this.poll.id);
		this.selectedAnswer = answer;
		if (this.isButtonQuestion) {
			this.submitAnswer(false); // poll interaction already tracked here in 'select'
		}
	}

	nextClicked() {
		if (this.alternativeQuestion) {
			this.setCurrentQuestion(this.poll.questions[this.alternativeQuestionIndex]);
		}
		this.analyticsService.trackPollInteraction(this.poll.id);
		if (this.isLastQuestion) {
			this.showOutro = true;
			clearInterval(this.timer);
			this.setCurrentQuestion(null);
		} else if (this.showOutro) {
			this.showOutro = false;
			if (this.poll.additional_information_enabled) {
				this.showAdditionalInfo = true;
			} else if (this.poll.email_opt_in_enabled) {
				this.showEmailOptin = true;
			}
		} else if (this.showAdditionalInfo && this.poll.email_opt_in_enabled) {
			this.showAdditionalInfo = false;
			this.showEmailOptin = true;
		} else {
			let index = this.currentQuestionIndex;

			do {
			}while (<boolean>this.poll.questions[++index].is_alternative_question === true);
			this.setCurrentQuestion(this.poll.questions[index]);
		}
		clearInterval(this.questionTimer);
		if (this.currentActiveJoker !== null) {
			this.currentActiveJoker = null;
		}
		this.extraTenSeconds = 0;
	}

	setCurrentQuestion(question?: PollQuestion) {

		this.currentQuestion = question;

		if (question  && <boolean>question.is_alternative_question !== true && question.question_type === 'QUESTION') {
			this.currentQuestionNumber++
		}

		this.selectedAnswer = null;
		this.stats = null;
	}

	startInteraction() {
		if (!this.interactionStarted) {
			this.pollService.logInteraction(this.poll.id, PollUserInteractionType.INITIAL)
				.subscribe(() => this.interactionStarted = true)
		}
	}

	submitAnswer(trackInteraction = true) {
		const answerIndex = this.currentQuestion.answers.indexOf(
			this.selectedAnswer
		);
		if (this.currentActiveJoker) {
			this.consumedJokerItems = PollJokerSlug[this.currentActiveJoker.consumed_joker] as PollJokerSlug;
		}

		this.submitting = true;

		this.pollService
			.submitAnswer({
				pollId: this.poll.id,
				questionId: this.currentQuestion.id,
				answerIndex,
				consumed_joker_items: this.consumedJokerItems,
				lastQuestionAnswered: this.isLastQuestion,
			})
			.subscribe(
				(response) => {

					this.stats = response.answers_stats;

					this.submitting = false;
					this.isUsingAudienceJoker = false;
					this.selectedAnswers.push(this.selectedAnswer);
					this.consumedJokerItems = response.consumed_joker_items;
					if (trackInteraction) {
						this.analyticsService.trackPollInteraction(this.poll.id);
					}

					if (<any>this.poll.instant_feedback === 0) {
						this.nextClicked();
					}
				},
				() => {
					this.snackbar.error(
						this.translate.instant('new-polls.fetch-error')
					);
				}
			);
	}

	submitEmail() {
		if (this.emailForm) {
			this.emailForm.submitEmail();
		}
	}

	submitAdditionalInfo() {
		if (this.additionalInfoForm) {
			this.additionalInfoForm.submit(() => {
				if (this.poll.email_opt_in_enabled) {
					this.showAdditionalInfo = false;
					this.showEmailOptin = true;
				}
			});
		}
	}

	showAvailableJokers(show: boolean) {
		this.showJokers = show;
	}

	useJoker(joker: string) {
		if (this.isAvailable(joker) && !this.isUsed(joker)) {
			this.availableJokers.set(joker, true);
			switch (joker) {
				case JokerType.addTenSeconds:
					this.handleAddTenSecondsJoker();
					break;
				case JokerType.alternativeQuestion:
					this.handleAlternativeQuestionJoker();
					break;
				case JokerType.fiftyFifty:
					this.handleFiftyFiftyJoker();
					break;
				case JokerType.skipQuestion:
					this.handleSkipQuestionJoker();
					break;
				case JokerType.audience:
					this.handleAudienceJoker();
					break;
				default:
					//console.log('None');
			}
			this.showJokers = false;
		}
	}

	isAvailable(joker: string) {
		if (joker === JokerType.addTenSeconds && !this.questionTimeSeconds) {
			return false;
		}
		return this.availableJokers.has(joker);
	}

	isUsed(joker: string) {
		return this.availableJokers.get(joker);
	}

	handleAddTenSecondsJoker() {
		this.extraTenSeconds = 10;
		this.currentActiveJoker = ActiveJokerType['Add_ten_seconds'];
	}

	handleAlternativeQuestionJoker() {
		this.alternativeQuestionIndex = this.poll.questions.indexOf(this.currentQuestion);
		this.alternativeQuestion = this.poll.questions.find(
			(q) => q.is_alternative_question
		);
		if (this.alternativeQuestion) {
			//this.currentQuestionNumber = this.currentQuestionIndex + 1;


			clearInterval(this.timer);
			this.setCurrentQuestion(this.alternativeQuestion);
			this.isIntro = false;
			this.questionTimeSeconds = this.poll.question_countdown_time;
		}
		this.currentActiveJoker = ActiveJokerType['Alternative_question'];
	}

	handleFiftyFiftyJoker() {
		const answers = this.currentQuestion.answers;
		answers.map((a) => {
			a.disabled = false;
		});
		const totalAnswerOptions = answers.length;
		let optionsToRemove = Math.floor(totalAnswerOptions / 2);
		while (optionsToRemove > 0) {
			let r = Math.floor(Math.random() * totalAnswerOptions);
			if (!answers[r].correct && !answers[r].disabled) {
				answers[r].disabled = true;
				optionsToRemove--;
			}
		}

		this.currentActiveJoker = ActiveJokerType['Fifty_fifty'];
	}

	handleSkipQuestionJoker() {
		this.currentQuestion.answers.map((option) => {
			if (option.correct) {
				this.selectAnswer(option);
			}
		});
		this.currentActiveJoker = ActiveJokerType['Skip_question'];
		this.submitAnswer();
	}

	handleAudienceJoker() {
		this.pollService
			.getPollQuestionAnswerStats(this.poll.id, this.currentQuestion.id)
			.subscribe((stats) => {
				this.stats = [];
				stats.forEach((s) => {
					this.stats.push({
						percentage: s.percentage,
						total: s.total,
					});
				});
				this.isUsingAudienceJoker = true;
				this.currentActiveJoker = ActiveJokerType['Audience'];
			});
	}

	isAuthRequired() {
		return this.state.externalAuthRequired;
	}

	requestAuth() {
		window.parent.postMessage({
			type: 'trigger-auth',
		}, '*');
	}

}

const ActiveJokerType = {
	Fifty_fifty: {
		title: '50/50',
		color: '#07d1ad',
		icon: 'joker_fifty_fifty_icon.png',
		consumed_joker: 'fiftyFifty',
	},
	Skip_question: {
		title: 'Skip question',
		color: '#e6be0a',
		icon: 'joker_skip_question_icon.png',
		consumed_joker: 'skipQuestion',
	},
	Audience: {
		title: 'Audience',
		color: '#cf5acd',
		icon: 'joker_audience_icon.png',
		consumed_joker: 'audience',
	},
	Add_ten_seconds: {
		title: 'Add 10 seconds',
		color: '#8fc649',
		icon: 'joker_add_ten_seconds_icon.png',
		consumed_joker: 'addTenSeconds',
	},
	Alternative_question: {
		title: 'Alternative question',
		color: '#de9e49',
		icon: 'joker_alternative_question_icon.png',
		consumed_joker: 'alternativeQuestion',
	},
};

export enum JokerType {
	audience = 'audience',
	addTenSeconds = 'add_ten_seconds',
	alternativeQuestion = 'alternative_question',
	fiftyFifty = 'fifty_fifty',
	skipQuestion = 'skip_question',
}
