import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {debounce} from 'lodash';

import {State} from '../core/state';
import { Battle, } from '@inscouts/playersvote-models';

@Component({
	selector: 'battle-rail',
	template: `
		<div class="battle-rail">

			<button
				(click)="scroll(-1)"
				[disabled]="!canScrollLeft"
				class="battle-rail__button battle-rail__button--left"
			>
				<app-icon name="arrow-left"></app-icon>
			</button>
			<div
				#scroller
				class="battle-rail__scroller"
				(scroll)="debouncedScrollHandler()"
				(touchstart)="touchStartHandler($event)"
			>
				<ul *ngIf="battle.polls && battle.polls.length" class="battle-rail__list">
					<li *ngFor="let poll of battle.polls" class="battle-rail__item">
						<battle-poll
							[poll]="poll"
							[isPreview] = "isPreview"
							[battle]="battle"
							(active)="scrollIntoView($event)"
						></battle-poll>
					</li>
				</ul>
			</div>
			<button
				(click)="scroll(1)"
				[disabled]="!canScrollRight"
				class="battle-rail__button battle-rail__button--right"
			>
				<app-icon name="arrow-right"></app-icon>
			</button>
		</div>
	`
})
export class BattleRailComponent {

	@Input() battle: Battle;
	@Input() isPreview: boolean = false;
	canScrollLeft: boolean;
	canScrollRight: boolean;
	scrolled: boolean;
	pos: {left: number, x: number};
	debouncedScrollHandler: () => void;

	@ViewChild('scroller', { static: true }) scroller: ElementRef;

	constructor(
		public state: State,
	) {
		this.debouncedScrollHandler = debounce(this.onScrollHandler, 100);
		this.canScrollLeft = false;
		this.canScrollRight = true;
		this.pos = {left: 0, x: 0};
	}

	ngOnInit() {
		this.battle.polls = this.battle.polls.sort((a, b) => new Date(a.available_start).getTime() - new Date(b.available_start).getTime());
	}


	scroll(direction: number) {
		const el = this.scroller.nativeElement;
		el.scrollTo({
			left: Math.max(0, el.scrollLeft + direction * el.clientWidth * 0.75),
			behavior: 'smooth',
		})
	}
	scrollIntoView(el) {
		if (!this.scrolled) {
			el.scrollIntoView({
				inline: 'center',
				behavior: 'smooth',
			});
			this.scrolled = true;
		}
	}

	onScrollHandler() {
		const el = this.scroller.nativeElement;
		this.canScrollLeft = el.scrollLeft > 0;
		this.canScrollRight = el.scrollLeft + el.clientWidth < el.scrollWidth;
	}

	touchStartHandler(ev: TouchEvent) {
		const el = this.scroller.nativeElement;
		el.style.cursor = 'grabbing';
		el.style.userSelect = 'none';

		this.pos = {
			left: el.scrollLeft,
			x: ev.touches[0].pageX,
		};

		const that = this

		function touchMoveHandler(ev: TouchEvent) {
			const dx = ev.touches[0].pageX - that.pos.x;
			that.scroller.nativeElement.scrollLeft = that.pos.left - dx;
		}

		function touchEndHandler() {
			const el = that.scroller.nativeElement;
			el.style.cursor = 'grab';
			el.style.removeProperty('user-select');

			document.removeEventListener('touchmove', touchMoveHandler);
			document.removeEventListener('mouseup', touchEndHandler);
		}

		document.addEventListener('touchmove', touchMoveHandler);
		document.addEventListener('mouseup', touchEndHandler);
	}
}
