<div class="app-poll__header">
	<div class="app-poll__header-title">{{ 'new-polls.finish-thanks' | translate }}</div>
</div>

<form [formGroup]="form">
	<div class="app-poll-box app-poll__additional-info-box">
		<ng-template [ngIf]="!additionalInfoSuccess">
			<h2 class="app-poll__additional-info-title">{{ poll.additional_information_title }}</h2>
			<mat-form-field>
				<input matInput required formControlName="additional_info">
			</mat-form-field>

			<div>
				<span class="app-poll__additional-info-box__description" [innerHTML]="poll.additional_information_text"></span>
			</div>
		</ng-template>

		<ng-template [ngIf]="additionalInfoSuccess">
			<p style="margin: 32px 0;">
				{{ 'new-polls.additional-info.success' | translate: {title:poll.additional_information_title} }}
			</p>
		</ng-template>
	</div>
</form>
