import {NgModule} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const materialModules = [
	MatSnackBarModule,
	MatDialogModule,
	MatSelectModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatButtonModule,
	MatIconModule,
	MatInputModule,
	MatFormFieldModule,
	MatCheckboxModule,
];

@NgModule({
	imports: materialModules,
	exports: materialModules,
	declarations: []
})
export class MaterialModules {
}
