import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {DialValue} from '../core/components/multi-dial.component';
import {GameStats, Prediction, ResultPercentageValue} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-widget-prediction-stats',
	template: `
		<div class="app-widget-prediction-bars">
			<div class="app-widget-prediction-bars__wrapper app-widget-prediction-bars__wrapper--side">
				<app-bar [title]="'widget.prediction.win' | translate"
						 [percentValue]="stats?.tendency.team_1_win.percentage"></app-bar>
			</div>
			<div class="app-widget-prediction-bars__wrapper app-widget-prediction-bars__wrapper--center" *ngIf="showDraw">
				<app-bar [title]="'widget.prediction.draw' | translate"
						 [percentValue]="stats?.tendency.draw.percentage"></app-bar>
			</div>
			<div class="app-widget-prediction-bars__wrapper app-widget-prediction-bars__wrapper--side">
				<app-bar [title]="'widget.prediction.win' | translate"
						 [percentValue]="stats?.tendency.team_2_win.percentage"></app-bar>
			</div>
		</div>
		<div class="app-widget-prediction-dials" *ngIf="showDials">
			<div class="app-widget-prediction-dials__headline">{{ 'widget.prediction.top-predictions' | translate:{ number: dialValues.length } }}</div>
			<app-multi-dial [values]="dialValues" [largeDialIndex]="1"></app-multi-dial>
		</div>
	`
})
export class WidgetPredictionStatsComponent implements OnInit {

	@HostBinding('class') clazz = 'app-widget-prediction-stats';

	@Input() stats: GameStats;
	@Input() prediction: Prediction;
	@Input() showDraw = true;
	@Input() showDials = true;

	dialValues: DialValue[];

	static isTopResultHighlighted(result: ResultPercentageValue, prediction: Prediction) {
		return prediction.result_team_1 === result.result_team_1 && prediction.result_team_2 === result.result_team_2;
	}

	constructor() {
	}

	ngOnInit() {
		this.dialValues = this.getDialValues();
	}

	private getDialValues(): DialValue[] {
		if (this.stats) {
			return this.stats.top_results.map(value => {
				return {
					title: `${value.result_team_1}:${value.result_team_2}`,
					value: value.percentage,
					titleHighlighted: WidgetPredictionStatsComponent.isTopResultHighlighted(value, this.prediction)
				};
			}).slice(0, 3);
		}

		return [];
	}
}
