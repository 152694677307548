<div class="app-poll__header" *ngIf="question.question_type == 'QUESTION'">
	<div style="width:70px">
		<div class="app-poll__countdown" *ngIf="countdownTime && !timeOver && !stats">
				<span class="app-poll__countdownSeconds">
					{{ countdownTime }}
				</span>
			<span *ngIf="extraTenSeconds > 0" class="app-poll__extraTenSeconds">
				+ {{ extraTenSeconds }}
			</span>
		</div>
	</div>
	<h1 class="app-poll__header-title">{{ question.text }}</h1>
	<div  style="width:70px">
		<div class="app-poll__question-index" *ngIf="isQuestionIndexShown">
			<span>{{ "new-polls.question" | translate }}</span>
			{{ questionNumber }} / {{ numberOfQuestions }}
		</div>
	</div>

</div>

<div class="app-poll-question__content">
	<div class="app-poll-question__media" *ngIf="hasMedia">
		<ng-container *ngIf="fileUrl" style="width: 100%;">
			<ng-container [ngSwitch]="fileType">
				<img [src]="fileUrl" *ngSwitchCase="FileType.Image">
				<audio controls autoplay *ngSwitchCase="FileType.Audio">
					<source [src]="fileUrl"/>
				</audio>
			</ng-container>
		</ng-container>

		<div *ngIf="embedData" style="width: 100%;">

			<ng-container [ngSwitch]="embedData.type">
				<div style="width: 100%;" [innerHTML]="soundCloudHtml$ | async" *ngSwitchCase="EmbedType.Soundcloud"></div>
				<div style="position: relative; padding-bottom: 56.25%; width: 100%;" *ngSwitchCase="EmbedType.Video">
					<iframe [src]="embedData.url | safe" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
				</div>
			</ng-container>
		</div>

	</div>
	<div class="app-poll-box" *ngIf="timeOver && !stats && !submitting" style="padding: 24px; text-align: center;" >
		<h3 style="margin: 0;">
			{{ "new-polls.time-over" | translate }}
		</h3>
	</div>

	<mat-radio-group [(ngModel)]="selectedAnswer" (ngModelChange)="selectedAnswerChanged($event)" class="app-poll-question__choices app-poll-box" *ngIf=" !timeOver || stats || (!showAnswersAsButtons && submitting) ">
		<div *ngIf="countdownTime && !stats && question.question_type == 'QUESTION'" class="app-poll-question__progress-bars" >
			<div [style.width.%]="100 - extraBarMaxWidth">
				<div class="app-poll-question__countdown-bar" [style.animation]="'countdown ' + totalCountdown + 's linear 1'" [style.animationPlayState]="extraTenSeconds > 0 ? 'paused' : 'running'"></div>
			</div>
			<div *ngIf="extraTenSeconds > 0" [style.width.%]="extraBarMaxWidth">
				<div class="app-poll-question__extraSeconds-bar" [style.animation]="'extraSeconds ' + 10 + 's linear 1'"></div>
			</div>
		</div>

		<ng-template [ngIf]="stats || !showAnswersAsButtons">
			<div class="app-poll-question__choice" *ngFor="let answer of question.answers" (click)="selectedAnswerChanged(answer)"
				[class.app-poll-question__choice--disabled]="!showStats() && (!isUsingAudienceJoker && answer.disabled)"
				 [class.app-poll-question__choice--correct]="!isUsingAudienceJoker && isAnswerCorrect(answer)"
				 [class.app-poll-question__choice--incorrect]="!isUsingAudienceJoker && isAnswerSelectedAnswerAndIncorrect(answer)">
				<mat-radio-button [value]="answer" *ngIf="!stats">
					{{ answer.text }}
				</mat-radio-button>

				<div *ngIf="statsForAnswer(answer) as answerStats" class="app-poll-question__stats" >
					<div class="app-poll-question__answer-icon" *ngIf="hasCorrectAnswer" @fade >
						<app-poll-badge *ngIf="!isUsingAudienceJoker && answer === selectedAnswer"[correct]="isAnswerCorrect(answer)"></app-poll-badge>
					</div>
					<mat-radio-button [value]="answer" *ngIf="isUsingAudienceJoker"></mat-radio-button>
					<div class="app-poll-question__answer-text">
						<div>
							<span>{{ answer.text }}</span>
							<app-poll-bar [percentage]="answerStats.percentage"></app-poll-bar>
						</div>
					</div>
					<div class="app-poll-question__percentage" [class.question__percentage--active-joker]="isUsingAudienceJoker" @fade>
						{{ answerStats.percentage | number: "0.2-2" }}%
					</div>
				</div>
			</div>
		</ng-template>

		<ng-template [ngIf]="!stats && showAnswersAsButtons">
			<div class="app-poll-question__button-choices" [class.app-poll-question__button-choices--scale]="question.use_rating_scale" *ngIf="!submitting">
				<button mat-flat-button color="primary" *ngFor="let answer of question.answers" (click)="selectedAnswerChanged(answer)">
					{{ answer.text }}
				</button>
			</div>

			<div *ngIf="isRatingScale && !submitting" style="margin-top: -16px;">
				<app-rating-legend [question]="question"></app-rating-legend>
			</div>

			<mat-spinner *ngIf="submitting" class="app-poll-question__button-spinner"></mat-spinner>
		</ng-template>
	</mat-radio-group>
</div>
