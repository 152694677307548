import {animate, state, style, transition, trigger} from '@angular/animations';
import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {PollQuestion, PollQuestionAnswer, PollQuestionAnswerUserStats} from '@inscouts/playersvote-models';

import {map, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
	selector: "app-poll-question",
	templateUrl: 'poll-question.component.html',
	animations: [
		trigger("fade", [
			state("void", style({ opacity: 0 })),
			state("*", style({ opacity: 1 })),
			transition(":enter", animate("500ms ease-out")),
		]),
	],
})
export class PollQuestionComponent implements OnInit, OnChanges {
	@HostBinding("class") clazz = "app-poll-question";

	@HostBinding("class.app-poll-question--disabled")
	get isDisabled() {
		return this.disabled;
	}

	@Input() question: PollQuestion;
	@Input() questionIndex: number;
	@Input() numberOfQuestions: number;
	@Input() countdownTime: number;
	@Input() extraTenSeconds: number;
	@Input() questionNumber: number;
	@Input() stats: PollQuestionAnswerUserStats[];
	@Input() submitting = false;
	@Input() disabled = false;
	@Input() isUsingJoker = false;
	@Input() isUsingAudienceJoker = false;
	@Output() selectAnswer = new EventEmitter<PollQuestionAnswer>();
	@Output() timeOverChange = new EventEmitter<boolean>();

	embedData: EmbedData;
	soundCloudHtml$: Observable<SafeHtml>;
	selectedAnswer: PollQuestionAnswer;
	timeOver = false;
	extraBarMaxWidth = 0;
	totalCountdown = 0;

	FileType = FileType;
	EmbedType = EmbedType;

	static getYouTubeVideoId(url: string) {
		const regExp =
			/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*)(?:(([?&])start)=(\d+))?.*/;
		const match = url.match(regExp);
		if (match) {
			const id = match[1];
			const start = match[4];
			if (start) {
				return `${id}?start=${start}`;
			}

			return id;
		}

		return null;
	}

	static getVimeoVideoId(url: string) {
		const regExp = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+(#t=\d+)?)(?:|\/\?)/;
		const match = url.match(regExp);
		if (match) {
			return match[4];
		}

		return null;
	}

	static isSoundCloudUrl(url: string): boolean {
		const regExp = /^https?:\/\/(soundcloud\.com|snd\.sc).*$/;
		return !!url.match(regExp);
	}

	static getFileExtension(url: string) {
		if (!url) {
			return null;
		}

		return url.split('.').pop().toLowerCase();
	}

	constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

	ngOnInit() {
		this.totalCountdown = this.countdownTime;
		this.getBarMaxWidth();

	}

	ngOnChanges(changes: SimpleChanges) {

		if (this.totalCountdown && this.countdownTime < 1) {
			this.setTimeOver(true);
		}
		if (changes.question) {
			this.selectedAnswer = null;
			this.setTimeOver(false);

			this.embedData = this.getEmbedData();
			if (this.embedData) {
				this.soundCloudHtml$ = this.getSoundCloudHtml(this.embedData.url)
					.pipe(map(html => this.sanitizer.bypassSecurityTrustHtml(html)))
					.pipe(shareReplay(1));
			}
		}
	}

	get isQuestionIndexShown() {
		return this.numberOfQuestions > 1;
	}

	selectedAnswerChanged(answer: PollQuestionAnswer) {
		if (!this.stats || this.isUsingAudienceJoker) {
			this.selectedAnswer = answer;
			this.selectAnswer.emit(this.selectedAnswer);
		}
	}

	get hasMedia() {

		return this.question.image_url || this.question.video_url;
	}

	get fileUrl() {
		return this.question.image_url || null;
	}

	get fileType(): FileType {
		if (this.fileUrl) {
			const ext = PollQuestionComponent.getFileExtension(this.fileUrl);
			switch (ext) {
				case 'mp3':
				case 'wav':
				case 'mp4':
				case 'aac':
				case 'm4a':
					return FileType.Audio;
				case 'jpg':
				case 'jpeg':
				case 'png':
					return FileType.Image;
			}
		}
	}

	get hasCorrectAnswer() {
		for (const answer of this.question.answers) {
			if (answer.correct) {
				return true;
			}
		}

		return false;
	}

	showStats() {
		return this.stats != null;
	}

	statsForAnswer(answer: PollQuestionAnswer) {
		if (!this.stats) {
			return null;
		}

		const index = this.question.answers.indexOf(answer);
		return this.stats[index];
	}

	isAnswerCorrect(answer: PollQuestionAnswer) {
		return this.stats && answer.correct;
	}

	isAnswerSelectedAnswerAndCorrect(answer: PollQuestionAnswer) {
		if (this.selectedAnswer && this.hasCorrectAnswer) {
			return (
				this.stats &&
				this.selectedAnswer.correct &&
				answer === this.selectedAnswer
			);
		}

		return false;
	}

	isAnswerSelectedAnswerAndIncorrect(answer: PollQuestionAnswer) {
		if (this.selectedAnswer && this.hasCorrectAnswer) {
			return (
				this.stats &&
				!this.selectedAnswer.correct &&
				answer === this.selectedAnswer
			);
		}

		return false;
	}

	getBarMaxWidth() {
		const initialQuestionSeconds = this.countdownTime;
		const addedSeconds = this.extraTenSeconds;

		const result = initialQuestionSeconds + addedSeconds;

		const extraBarMaxWidth = (addedSeconds / result) * 100;
		this.extraBarMaxWidth = extraBarMaxWidth;
	}

	get isRatingScale() {
		return this.question.use_rating_scale;
	}

	get showAnswersAsButtons() {
		return (
			this.question.show_answers_as_buttons ||
			this.question.use_rating_scale
		);
	}

	private getEmbedData(): EmbedData {
		if (this.question.video_url) {
			const youtubeId = PollQuestionComponent.getYouTubeVideoId(
				this.question.video_url
			);
			if (youtubeId) {
				return {
					url: `https://www.youtube.com/embed/${youtubeId}`,
					source: EmbedSource.YouTube,
					type: EmbedType.Video,
				};
			}

			const vimeoId = PollQuestionComponent.getVimeoVideoId(this.question.video_url);
			if (vimeoId) {
				return {
					url: `https://player.vimeo.com/video/${vimeoId}`,
					source: EmbedSource.Vimeo,
					type: EmbedType.Video,
				};
			}

			if (PollQuestionComponent.isSoundCloudUrl(this.question.video_url)) {
				return {
					url: this.question.video_url,
					source: EmbedSource.Soundcloud,
					type: EmbedType.Soundcloud,
				};
			}
		}

		return null;
	}

	getSoundCloudHtml(url: string) {
		return this.http.get('https://soundcloud.com/oembed', {
			params: {
				maxheight: '166',
				show_comments: 'false',
				format: 'json',
				url: url,
			}
		}).pipe(map((response) => {
			return (response as any).html;
		}));
	}

	private setTimeOver(over: boolean) {
		this.timeOver = over;
		this.timeOverChange.emit(this.timeOver);
	}
}

interface EmbedData {
	url: string;
	source: EmbedSource;
	type: EmbedType;
}

enum EmbedSource {
	YouTube,
	Vimeo,
	Soundcloud,
}

enum EmbedType {
	Video,
	Soundcloud,
}

enum FileType {
	Image,
	Audio,
}
