import {Component, OnInit, HostBinding, Input, EventEmitter, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";
import {SnackBarService} from '../services/snackbar.service';
import {PollService, UpdateUserData} from '../services/poll.service';
import {Poll} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-poll-additional-info-form',
	templateUrl: 'poll-additional-info-form.component.html'
})
export class PollAdditionalInfoFormComponent implements OnInit {

	@HostBinding('class') clazz = 'app-poll-email-form';

	@Input() poll: Poll;
	@Output() submittingChange = new EventEmitter<boolean>();
	@Output() success = new EventEmitter<boolean>();

	form: UntypedFormGroup;
	private _success = false;
	private _submitting = false;

	constructor(
		private fb: UntypedFormBuilder,
		private snackbar: SnackBarService,
		private pollService: PollService,
		private translate: TranslateService,
	) {
		this.form = this.fb.group({
			additional_info: ['', Validators.required]
		});
	}

	ngOnInit() {
	}

	get formSubmitting() {
		return this._submitting;
	}

	set formSubmitting(submitting: boolean) {
		this._submitting = submitting;
		this.submittingChange.next(this._submitting);
	}

	get additionalInfoSuccess() {
		return this._success;
	}

	set additionalInfoSuccess(success: boolean) {
		this._success = success;
		this.success.next(this._success);
	}

	submit(done = () => {}) {
		this.form.controls.additional_info.markAsTouched();
		if (this.form.valid) {
			this.formSubmitting = true;
			this.pollService.updateUser(this.form.value as UpdateUserData)
				.subscribe(() => {
					this.additionalInfoSuccess = true;
					done();
				}, () => {
					const message = this.translate.instant(
						'new-polls.additional-info.error',
						{title: this.poll.additional_information_title}
					);
					this.snackbar.error(message);
				}, () => {
					this.formSubmitting = false;
				});
		}
	}
}
