import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Game, GameSource, GameSourceType, GameStatus, Player, RatingValue, SportType, Team} from '@inscouts/playersvote-models';
import {GameSourceKey, TeamNumber} from '../models/game.model';
import {ClientGameStatus} from '../../../core/core';

@Injectable()
export class GameUtils {

	static RATING_NUMBERS = [1, 2, 3, 4, 5, 6];

	constructor(private translate: TranslateService) {
	}

	static getSingleTeamNumber(mainTeam: Team, game: Game): TeamNumber {
		if (mainTeam) {
			switch (mainTeam.opta_id) {
				case game.team_1.opta_id:
					return TeamNumber.One;
				case game.team_2.opta_id:
					return TeamNumber.Two;
			}

			return null;
		}
	}

	static getGameStatus(game: Game) {
		const time = Date.now();
		const start = new Date(game.start).getTime();
		const end = new Date(game.meta_data.rating_end).getTime();

		if (time > start) {
			if (end && time > end) {
				return ClientGameStatus.Over;
			} else if (game.status === GameStatus.PLAYING || game.status === GameStatus.PLAYED) {
				if (game.meta_data && game.meta_data.featured) {
					return ClientGameStatus.Rating;
				}

				return ClientGameStatus.Over;
			}
		} else {
			return ClientGameStatus.Prediction;
		}

		return ClientGameStatus.ActivityLocked;
	}

	static getMainTeamForGameSource(game: Game, gameSource: GameSource) {
		if (gameSource.type === GameSourceType.TEAM) {
			if (gameSource.team_opta_id === game.team_1.opta_id) {
				return game.team_1;
			}

			return game.team_2;
		}

		return game.team_1;
	}

	static getPlayerDisplayName(player: Player) {
		if (!player) {
			return;
		}

		if (player.known_name) {
			return player.known_name;
		}

		return `${player.firstname.substring(0, 1)}. ${player.lastname}`;
	}

	static getShortPlayerDisplayName(player: Player) {
		if (!player) {
			return;
		}

		return player.lastname;
	}

	static getValueForRatingNumber(rating: number, values: RatingValue[]) {
		if (values) {
			const found = values.find(value => {
				return value.rating === rating;
			});

			if (found) {
				return found.percentage;
			}

			return 0;
		}
	}

	static isTeam1RatingComplete(game: Game): boolean {
		return !!game.prediction.rating_team_1 && game.prediction.mvp_team_1 !== null && game.prediction.mvp_team_1 !== undefined;
	}

	static isRatingComplete(game: Game, gameSource: GameSource) {
		const team1Complete = this.isTeam1RatingComplete(game);
		const team2Complete = !!game.prediction.rating_team_2 && game.prediction.mvp_team_2 !== null && game.prediction.mvp_team_2 !== undefined;

		if (game.team_1.opta_id === gameSource.team_opta_id) {
			return team1Complete;
		}

		if (game.team_2.opta_id === gameSource.team_opta_id) {
			return team2Complete;
		}

		return team1Complete && team2Complete;
	}

	static isPredictionComplete(game: Game) {
		const values = game.prediction.result_team_1 !== null
					&& game.prediction.result_team_2 !== null;

		if (!values) {
			return false;
		}

		if (!this.canGameEndInDraw(game)) {
			return game.prediction.result_team_1 !== game.prediction.result_team_2;
		}

		return true;
	}

	static canGameEndInDraw(game: Game) {
		return game.sport_type !== SportType.Basketball && game.sport_type !== SportType.IceHockey;
	}

	static isGameHighScoringGame(game: Game) {
		return game.sport_type === SportType.Basketball;
	}

	getLeagueAndRoundText(game: Game, source: GameSourceKey | string) {
		if (!game) {
			return '';
		}

		switch (source) {
			case GameSourceKey.Bundesliga:
			case GameSourceKey.Bundesliga1819:
			case GameSourceKey.Bundesliga2021:
				return this.translate.instant('general.league-round', {league: game.league_name, round: game.week});
			case GameSourceKey.AustrianFriendlies:
				return this.translate.instant('general.friendly-game');
			case GameSourceKey.WordlCup2018:
				if (game.series) {
					return game.series;
				} else if (game.stage) {
					return game.stage;
				}

				return 'WM 2018';
		}


	}
}
