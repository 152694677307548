import {Component, OnInit, HostBinding, Input} from '@angular/core';

@Component({
	selector: 'app-poll-badge',
	template: `
		<mat-icon *ngIf="correct">check_mark</mat-icon>
		<mat-icon *ngIf="!correct">close</mat-icon>
	`
})
export class PollBadgeComponent implements OnInit {

	@HostBinding('class') clazz = 'app-poll-badge';

	@HostBinding('class.app-poll-badge--incorrect')
	private get isIncorrect() {
		return !this.correct;
	}

	@Input() correct = true;

	constructor() {
	}

	ngOnInit() {
	}
}
