import {take} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import {GameService} from '../services/game.service';
import {Subject} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import {GamePollService} from '../services/game-poll.service';
import {TranslateService} from '@ngx-translate/core';
import {WebviewsOverlayComponent} from '../webviews/webviews-overlay.component';
import {ModalService} from '../services/modal.service';
import {PollStatus, PollUtils} from '../core/poll-utils';
import {SnackBarService} from '../services/snackbar.service';
import {GamePoll, GameSource} from '@inscouts/playersvote-models';

@Component({
	selector: 'app-game-poll',
	template: `
		<div class="main-container" *ngIf="gameSource$ | async">
			<app-header [title]="title" style="margin-top: 8px; margin-bottom: 16px;"></app-header>

			<ng-template [ngIf]="poll">
				<app-game-poll-question [header]="currentQuestionHeader" [question]="currentQuestion" [disabled]="isPollOver"></app-game-poll-question>

				<button class="button" (click)="next()" [disabled]="isButtonDisabled()" [class.button--red]="isPollOver">
					<ng-template [ngIf]="!saving && !isPollOver">
						<span *ngIf="isFinalQuestion && isCurrentQuestionComplete"
							  class="button__text">{{ 'polls.finish' | translate }}</span>
						<span *ngIf="!isFinalQuestion && isCurrentQuestionComplete"
							  class="button__text">{{ 'general.next' | translate }}</span>
						<span *ngIf="!isCurrentQuestionComplete"
							  class="button__text button__text--small">{{ 'polls.missing-answer' | translate }}</span>
					</ng-template>
					<span *ngIf="isPollOver" class="button__text button__text--small">{{ 'polls.poll-over' | translate }}</span>
					<span *ngIf="saving" class="button__text">{{ 'general.saving' | translate }}</span>
				</button>
			</ng-template>
		</div>
		<app-sponsor [sponsor]="(gameSource$ | async)?.meta_data?.sponsor" *ngIf="gameSource$ | async"></app-sponsor>
	`
})

export class GamePollComponent implements OnInit {

	gameSource$: Subject<GameSource>;
	gameSource: GameSource;
	poll: GamePoll;
	saving = false;
	private currentQuestionIndex = 0;

	@HostBinding('class') clazz = 'main-component';

	constructor(private gameService: GameService,
				private modalService: ModalService,
				private snackBarService: SnackBarService,
				private translate: TranslateService,
				private pollService: GamePollService,
				private pollUtils: PollUtils,
				private route: ActivatedRoute) {
		this.route.params.subscribe((params: Params) => {
			this.gameService.loadSourceInfo(params.source);

			if (params.id) {
				this.fetchPoll(params.id);
			}
		});

		this.pollService.initTeamCache();
		this.gameSource$ = this.gameService.gameSource$;
		this.gameSource$.pipe(take(1)).subscribe((source) => {
			this.gameSource = source;
		});
	}

	ngOnInit() {
	}

	get currentQuestionHeader(): string {
		return this.translate.instant('polls.question-header', {
			current: this.currentQuestionIndex + 1,
			total: this.poll.questions.length
		});
	}

	get currentQuestion() {
		if (this.poll) {
			return this.poll.questions[this.currentQuestionIndex];
		}
	}

	isButtonDisabled() {
		return this.saving || !this.isCurrentQuestionComplete || this.isPollOver;
	}

	next() {
		if (!this.isFinalQuestion) {
			this.currentQuestionIndex++;
		} else {
			this.save();
		}
	}

	get title() {
		if (this.gameSource) {
			return this.pollUtils.getPollTitle(this.gameSource);
		}

		return '';
	}

	get isPollOver() {
		return PollUtils.getPollStatus(this.poll) === PollStatus.Over;
	}

	get isCurrentQuestionComplete() {
		const answer = this.poll.questions[this.currentQuestionIndex].answer;
		if (answer) {
			return !!answer.team_id || !!answer.player_id;
		}

		return false;
	}

	get isFinalQuestion() {
		return this.currentQuestionIndex === (this.poll.questions.length - 1);
	}

	private save() {
		this.saving = true;
		this.pollService.savePoll(this.poll)
			.subscribe(
				() => {
					this.saving = false;
					const ref = this.modalService.create(WebviewsOverlayComponent, {
						parameters: {
							headerText: this.pollUtils.getPollTitle(this.gameSource),
							title: this.translate.instant(`polls.success-title`),
							subTitle: this.translate.instant(`polls.success-text`),
							onClickOk: () => {
								ref.close();
							}
						}
					});
				},
				() => {
					this.saving = false;
					this.snackBarService.error(this.translate.instant('polls.error'));
				});
	}

	private fetchPoll(id: string) {
		this.pollService.getPoll(id)
			.subscribe(poll => {
				this.poll = poll;
			});
	}
}
