import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameUtils} from '../core/game-utils';
import {Player, PlayersvoteGames} from '@inscouts/playersvote-models';
import {GameService} from '../services/game.service';

@Component({
	selector: 'app-rate-team',
	templateUrl: 'rate-team.component.html'
})
export class RateTeamComponent implements OnInit {

	@Input() ratingDisabled: boolean;
	@Input() headerText: string;
	@Input() players: Player[];
	@Input() rating: number;
	@Output() ratingChange = new EventEmitter<number>();

	@Input() mvp: Player;
	@Output() mvpChange = new EventEmitter<Player>();

	ratingNumbers = [1, 2, 3, 4, 5, 6];
	isListOpen = false;

	constructor(private gameService: GameService) {
	}

	ngOnInit() {
		// Debug only
		/*if (!this.players.length) {
			this.players = [{
				id: '123',
				firstname: 'Alexander super langer name oida heast noch länger noch zu kurz',
				lastname: 'Ott'
			}];
		}*/
	}

	getDisplayTextForNumber(number: number) {
		return PlayersvoteGames.getDisplayTextForRatingNumber(number, this.gameService.gameSource);
	}

	toggleList() {
		this.isListOpen = !this.isListOpen;
	}

	selectPlayer(player: Player) {
		this.toggleList();
		this.mvp = player;
		this.mvpChange.emit(player);
	}

	isPlayerSelected(player: Player): boolean {
		return this.mvp === player;
	}

	isNumberSelected(number: number) {
		return this.rating === number;
	}

	rateTeam(number: number) {
		this.rating = number;
		this.ratingChange.emit(this.rating);
	}

	getPlayerName(player: Player): string {
		return GameUtils.getPlayerDisplayName(player);
	}
}
