export enum ServiceErrorCode {
	ValidationError = 'VALIDATION_ERROR',
	Unauthorized = 'UNAUTHORIZED',
	ServiceUnavailable = 'SERVICE_UNAVAILABLE',
	Unexpected = 'UNEXPECTED_SERVER_ERROR',
	Forbidden = 'FORBIDDEN',
	NotFound = 'NOT_FOUND',
	TooManyRequests = 'TOO_MANY_REQUESTS'
}

export class ServiceError {
	code: ServiceErrorCode;
	message?: string;
	data?: any;
}
