import {map, switchMap, take} from 'rxjs/operators';
import {ApiService} from './api.service';
import {Observable, ReplaySubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {chain} from 'lodash';
import {State} from '../core/state';
import {StyleUtils} from '../core/style.utils';
import {Game, GameSource, GameSourceType, GameStats, GameStatus, Prediction, SportType, Team} from '@inscouts/playersvote-models';

@Injectable()
export class GameService {

	isDemo = false;
	gameSource$ = new ReplaySubject<GameSource>();
	gameSource: GameSource;

	stage: string;
	series: string;

	private pathGames = '/bot/games';
	private pathSources = '/bot/sources';

	static fakeGameToPredictionState(game: Game) {
		const future = new Date();
		future.setHours(future.getHours() + 1);
		game.start = future;
		game.status = GameStatus.FIXTURE;
		game.prediction = null;
	}

	static fakeGameToHighScoringGame(game: Game): Game {
		game.sport_type = SportType.Basketball;
		return game;
	}

	static fakeGametoPredictionStatsState(game: Game) {
		game.prediction = {
			result_team_1: 1,
			result_team_2: 2,
			/*rating_team_1: 5,
			rating_team_2: 2,
			mvp_team_1: {
				id: 1
			},
			mvp_team_2: {
				id: 2
			}*/
		};
		//console.log(game);
	}

	static fakeGameInRatingPhase(game: Game) {
		this.fakeGametoPredictionStatsState(game);

		/*game.prediction.rating_team_1 = 5;
		game.prediction.rating_team_2 = 2;
		game.prediction.mvp_team_1 = {
			id: 1
		};
		game.prediction.mvp_team_2 = {
			id: 2
		};*/

		const past = new Date();
		past.setHours(past.getHours() - 1);
		game.start = past;
		game.status = GameStatus.PLAYING;
		game.meta_data = {
			featured: true
		};
	}

	static fakeGameIsOver(game: Game) {
		this.fakeGameInRatingPhase(game);
		game.status = GameStatus.PLAYED;
		game.meta_data.rating_end = game.start;
	}

	static getTeamPlayers(game: Game) {
		if (!game.players.length) {
			return {
				team1: [],
				team2: []
			};
		}

		const playerList = chain(game.players)
			.groupBy(player => {
				return player.team_id;
			})
			.map((players) => (players))
			.value();


		if (game.team_1.id === playerList[0][0].team_id) {
			return {
				team1: playerList[0],
				team2: playerList[1]
			};
		} else {
			return {
				team1: playerList[1],
				team2: playerList[0]
			};
		}
	}

	constructor(private apiService: ApiService, private state: State) {
		this.gameSource$
			.subscribe(source => this.gameSource = source);
	}

	getUpcomingGames(source: GameSource): Observable<Game[]> {
		const path = `${this.pathSources}/${source.key}/games/upcoming`;

		const params: any = {};
		if (source.type === GameSourceType.TEAM) {
			params.limit = 1;
		}

		if (this.stage) {
			params.stage = this.stage;
		}

		if (this.series) {
			params.series = this.series;
		}

		return this.apiService.get<Game[]>(path, params).pipe(
			map(games => {
				for (const game of games) {
					if (!game.prediction) {
						game.prediction = {
							game_id: game.id,
							result_team_1: null,
							result_team_2: null
						};
					}

					// debug
					/*if (!game.sponsor) {
						game.sponsor = {
							url: 'https://google.com',
							imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/1/1f/73970c892d748c7507db8e10d71535b0-apple-logo-icon-by-vexels.png'
						};
					}*/
				}

				return games;
			}));
	}

	private getSourceInfo(sourceKey: string) {
		return this.apiService.get<GameSource>(`${this.pathSources}/${sourceKey}/info`)
			.pipe(map(source => {
				// source.type = GameSourceType.TEAM;
				// source.teamOptaId = '84gk4ytf9vr8oml51cqyit78w';
				return source;
			}));
	}

	loadSourceInfo(sourceKey: string) {
		this.getSourceInfo(sourceKey).pipe(
			take(1))
			.subscribe((info: GameSource) => {
				if (!info.style) {
					info.style = {
						primaryColor: '#c62b2b'
					};
				}
				StyleUtils.styleForSource(info.style);
				this.gameSource$.next(info);
			});
	}

	getGame(id: string): Observable<Game> {
		return this.apiService.get<Game>(this.apiService.getRestEntityPath(this.pathGames, id)).pipe(
			map(game => {
				const defaultPrediction = {
					game_id: game.id,
					result_team_1: null,
					result_team_2: null,
					rating_team_1: null,
					rating_team_2: null,
					mvp_team_1: null,
					mvp_team_2: null,
				};

				if (game.prediction) {
					game.prediction = Object.assign(defaultPrediction, game.prediction);
				} else {
					game.prediction = defaultPrediction;
				}

				return game;
			}));
	}

	savePredictions(predictions: Prediction[]): Observable<any> {
		const params: any = {};
		if (this.state.origin) {
			params.origin = this.state.origin;
		}

		return this.apiService.post(`${this.pathGames}/results`, predictions, params);
	}

	getStats(gameId: number): Observable<GameStats> {
		return this.apiService.get<GameStats>(`${this.pathGames}/${gameId}/stats`)
			.pipe(map(stats => {
				/*stats.bounded_results_team_1 = [
					{
						percentage: 63,
						lower_bound: 100,
					},
					{
						percentage: 26,
						lower_bound: 115,
					},
					{
						percentage: 8,
						lower_bound: 130,
					},
					{
						percentage: 8,
						lower_bound: 130,
					},
					{
						percentage: 8,
						lower_bound: 130,
					}
				];

				stats.bounded_results_team_2 = [
					{
						percentage: 42,
						lower_bound: 100,
					},
					{
						percentage: 38,
						lower_bound: 115,
					},
					{
						percentage: 16,
						lower_bound: 130,
					},
					{
						percentage: 8,
						lower_bound: 130,
					},
					{
						percentage: 8,
						lower_bound: 130,
					}
				];*/

				/*const player = {
					firstname: 'Alex',
					id: 1,
					lastname: 'Ott'
				};
				stats.mvp.team_1 = [
					{
						percentage: 68,
						player
					},
					{
						percentage: 26,
						player
					},
					{
						percentage: 14,
						player
					},
					{
						percentage: 8,
						player
					}
				];

				stats.mvp.team_2 = [
					{
						percentage: 68,
						player
					},
					{
						percentage: 26,
						player
					},
					{
						percentage: 14,
						player
					},
					{
						percentage: 8,
						player
					}
				];*/

				return stats;
			}));
	}

	rateGame(id: number, prediction: Prediction) {
		const params: any = {};
		if (this.state.origin) {
			params.origin = this.state.origin;
		}

		return this.apiService.post(`${this.pathGames}/${id}/ratings`, prediction, params);
	}


	getTeams(): Observable<Team[]> {
		return this.gameSource$.pipe(
			switchMap((source) => {
				return this.apiService.get<Team[]>(`/bot/sources/${source.key}/teams`);
			}));
	}
}
