import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/operators';
import {addDays, addHours, endOfDay, startOfDay, subDays} from 'date-fns/fp'

import {Battle, Poll} from '@inscouts/playersvote-models';

@Injectable()
export class FakeBattleService {

	constructor() {
	}

	getBattle(battleId: string): Observable<Battle> {
		const poll = this.getMockBattle();
		return of(poll).pipe(delay(200));
	}

	getMockBattle(): Battle {
		const pollsNb = 10;

		const battleStart = startOfDay(subDays(pollsNb / 2, new Date()))
		const battleEnd = endOfDay(addDays(pollsNb, battleStart))
		const polls: Poll[] = Array.from({length: pollsNb}).map((_, index) => {
			return {
				id: index,
				name: 'Game Day',
				available_start: addDays(index, addHours(8, battleStart)),
				available_end: addDays(index, addHours(22, battleStart)),
			}
		})
		return {
			id: 123,
			name: 'Test Battle',
			available_start: battleStart,
			available_end: battleEnd,
			polls,
		}
	}
}
