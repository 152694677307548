<div class="app-poll__root" [style.background-image]="pollBackgroundImage">
	<div class="app-poll-box app-poll__intro" *ngIf="isIntro">
		<img [src]="poll.intro_image_url" *ngIf="poll.intro_image_url" />

		<div *ngIf="isPollUnavailable" class="app-poll__unavailable-bar" >
			<ng-template [ngIf]="isPollOver">{{ "new-polls.poll-over" | translate }}</ng-template>
			<ng-template [ngIf]="isPollNotAvailableYet">{{ "new-polls.poll-not-yet-available" | translate }}</ng-template>
		</div>
		<div class="app-poll__intro-content" [class.app-poll__intro-content--disabled]="isPollUnavailable">
			<div class="app-poll__title">
				{{ poll.name }}
			</div>
			<div class="app-poll__subtitle" *ngIf="poll.subtitle">
				{{ poll.subtitle }}
			</div>

			<button mat-flat-button color="primary" (click)="start()" class="app-poll-primary-button" [disabled]="isPollUnavailable" >
				{{ "new-polls.start" | translate }}
			</button>
		</div>
	</div>

	<div *ngIf="isAuthRequired()">
		<div class="app-poll__header">
			<h1 class="app-poll__header-title">{{ 'new-polls.no-auth.title' | translate }}</h1>
		</div>

		<div class="app-poll-question__content" style="background: white; padding: 10px; display: flex; flex-direction: column; align-items: center">
			<span>{{ 'new-polls.no-auth.text' | translate }}</span>
			<button mat-flat-button color="primary" (click)="requestAuth()" style="margin: 20px 0">
				{{ 'new-polls.no-auth.button' | translate }}
			</button>
		</div>

		<div class="app-poll__footer">
			<!--<div class="app-poll__sponsored-notice" *ngIf="isSponsoredQuestion">
				{{ 'new-polls.sponsored-question' | translate }}
			</div>-->
			<img class="app-poll__logo" src="assets/images/playersvote-logo-accent.svg" *ngIf="!isLight">
			<img class="app-poll__logo" src="assets/images/playersvote-logo-dark.svg" *ngIf="isLight">
		</div>
	</div>

	<ng-container *ngIf="!isAuthRequired()">
		<div *ngIf="isPollUnavailable && !isIntro" class="app-poll__unavailable-bar app-poll__unavailable-bar--single" >
			<ng-template [ngIf]="isPollOver">{{ "new-polls.poll-over" | translate }}</ng-template>
			<ng-template [ngIf]="isPollNotAvailableYet">{{ "new-polls.poll-not-yet-available" | translate }}</ng-template>
		</div>
		<app-poll-question
			[question]="currentQuestion"
			[questionIndex]="currentQuestionIndex"
			[questionNumber]="currentQuestionNumber"
			[numberOfQuestions]="numberOfQuestions"
			[countdownTime]="questionTimeSeconds"
			[extraTenSeconds]="extraTenSeconds"
			[stats]="stats"
			[submitting]="submitting"
			[disabled]="isPollUnavailable"
			(selectAnswer)="selectAnswer($event)"
			(timeOverChange)="timeOver = $event"
			[isUsingJoker]="showJokers"
			[isUsingAudienceJoker]="isUsingAudienceJoker"
			*ngIf="currentQuestion && !showJokers"
		></app-poll-question>

		<div *ngIf="currentQuestion && showJokers" class="app-poll-jokers">
			<div class="app-poll-jokers__icon-container">
				<mat-icon class="app-poll-jokers__close-icon" (click)="showAvailableJokers(false)"> close </mat-icon>
			</div>
			<div class="app-poll-jokers__jokers-wrapper">
				<ng-container *ngFor="let joker of jokerTypes | keyvalue">
					<div *ngIf="isAvailable(joker.value)" (click)="useJoker(joker.value)" class="app-poll-jokers__joker app-poll-jokers__joker-{{joker.value}}"
						[class.used]=" isUsed(joker.value) || (joker.value === jokerTypes.fiftyFifty && currentQuestion.answers.length > 5) ">
						<div class="app-poll-jokers__joker-label-wrapper">
							<img src="assets/images/joker_{{ joker.value }}_icon.png" alt="" width="29" class="app-poll-jokers__joker-icon" />
							<span class="app-poll-jokers__joker-label">
									{{ "polls.jokers." + joker.value | translate }}
							</span>
						</div>
					</div>
				</ng-container>
			</div>
		</div>

		<app-poll-outro [poll]="poll" [selectedAnswers]="selectedAnswers" [seconds]="timeInSeconds" [alternativeQuestionIndex]="alternativeQuestionIndex" *ngIf="showOutro"></app-poll-outro>

		<app-poll-additional-info-form *ngIf="showAdditionalInfo" [poll]="poll" #additionalInfoForm (success)="additionalInfoSuccess = $event" (submittingChange)="additionalInfoSubmitting = $event"></app-poll-additional-info-form>

		<app-poll-email-form *ngIf="showEmailOptin"  [poll]="poll"  #emailForm  (success)="emailSuccess = $event" (submittingChange)="emailSubmitting = $event"></app-poll-email-form>

		<div class="app-poll__footer" [ngStyle]="{ 'background-color': currentActiveJoker ? currentActiveJoker.color : 'transparent' }" >
			<div *ngIf="currentActiveJoker" class="current-active-joker-footer" >
				<img src="assets/images/{{ currentActiveJoker.icon }}" alt="" />
				<span class="current-active-joker-title">{{ currentActiveJoker.title }}</span>
			</div>

			<div *ngIf=" hasAvailableJokers && !currentActiveJoker && currentQuestion && !currentQuestion.is_alternative_question && !stats && currentQuestion.question_type == 'QUESTION'" (click)="showAvailableJokers(true)" class="show-jokers"
				[ngClass]="{ 'show-jokers--active-available': showJokers }" >
				<div class="show-jokers__titleWrapper">
					<img src="assets/images/joker-icon.png" class="show-jokers__icon" alt="joker-icon" />
					<span class="show-jokers__label"> {{ "polls.jokers.title" | translate }} </span>
				</div>
				<div class="show-jokers__underline" [ngClass]="{ 'show-jokers__underline--active-jokers': showJokers }">
					<ng-container *ngFor="let joker of jokerTypes | keyvalue">
						<div class="show-jokers__underline-{{joker.value}}" ></div>
					</ng-container>
				</div>
			</div>

			<div class="app-poll__button-wrapper" *ngIf="!isIntro && !showJokers">

				<button mat-flat-button color="primary" (click)="submitAnswer()" class="app-poll-primary-button"
						[ngStyle]="{ background: currentActiveJoker ? 'white' : '', color: currentActiveJoker ? currentActiveJoker.color : '' }"
						*ngIf="(currentQuestion?.question_type ==='QUESTION') && (isUsingAudienceJoker || (!stats && !isButtonQuestion && (!timeOver || submitting) && !showEmailOptin && !showAdditionalInfo && !showOutro))"
						[disabled]=" (submitting || !selectedAnswer)  && (currentQuestion?.question_type ==='QUESTION')">
					<ng-template [ngIf]="!submitting">{{ 'new-polls.submit' | translate }}</ng-template>
					<ng-template [ngIf]="submitting">{{ 'new-polls.submitting' | translate }}</ng-template>
				</button>

				<button mat-flat-button color="primary" *ngIf="currentQuestion?.question_type === 'INLINE_COVER' || (!isUsingAudienceJoker && isNextAvailable)" class="app-poll-primary-button" (click)="nextClicked()">
					{{ 'new-polls.next' | translate }}
				</button>

				<button mat-flat-button color="primary" *ngIf="isRestartButtonShown" (click)="restart()" class="app-poll-primary-button">
					{{ 'new-polls.restart' | translate }}
				</button>

				<button mat-flat-button color="primary" type="submit" *ngIf="showEmailOptin && !emailSuccess" class="app-poll-primary-button" (click)="submitEmail()">
					<ng-template [ngIf]="!emailSubmitting">{{ 'new-polls.email-submit' | translate }}</ng-template>
					<ng-template [ngIf]="emailSubmitting">{{ 'new-polls.submitting' | translate }}</ng-template>
				</button>

				<button mat-flat-button color="primary" type="submit" *ngIf="showAdditionalInfo && !additionalInfoSuccess" class="app-poll-primary-button" (click)="submitAdditionalInfo()">
					<ng-template [ngIf]="!additionalInfoSubmitting">{{ 'new-polls.additional-info.submit' | translate }}</ng-template>
					<ng-template [ngIf]="additionalInfoSubmitting">{{ 'new-polls.submitting' | translate }}</ng-template>
				</button>
			</div>

			<img class="app-poll__logo" src="assets/images/playersvote-logo-accent.svg" *ngIf="!isLight">
			<img class="app-poll__logo" src="assets/images/playersvote-logo-dark.svg" *ngIf="isLight">
		</div>
	</ng-container>
</div>
