import {Component, HostBinding, Input} from '@angular/core';
import {
	UserRank,
} from '@inscouts/playersvote-models';

@Component({
	selector: 'leaderboard-table',
	template: `
		<table class="leaderboard-table">
			<colgroup>
				<col class="leaderboard-table__col-rank">
				<col class="leaderboard-table__col-user">
				<col class="leaderboard-table__col-score">
				<col class="leaderboard-table__col-score">
			</colgroup>
			<thead class="leaderboard-table__table-head">
				<tr>
					<th class="leaderboard-table__cell leaderboard-table__cell--head leaderboard-table__cell--rank" scope="col">
						#
					</th>
					<th class="leaderboard-table__cell leaderboard-table__cell--head leaderboard-table__cell--user" scope="col">
						{{ 'leaderboards.user' | translate }}
					</th>
					<th class="leaderboard-table__cell leaderboard-table__cell--head leaderboard-table__cell--score" scope="col">
						{{ 'leaderboards.last-match-day-score' | translate }}
					</th>
					<th class="leaderboard-table__cell leaderboard-table__cell--head leaderboard-table__cell--score" scope="col">
						{{ 'leaderboards.score' | translate }}
					</th>
				</tr>
			</thead>
			<tbody class="leaderboard-table__table-body">
				<tr *ngFor="let rank of userRanks">
					<td class="leaderboard-table__cell leaderboard-table__cell--rank">#{{ rank.rank }}</td>
					<td class="leaderboard-table__cell leaderboard-table__cell--user">
						<div class="leaderboard-table__user-name">{{ rank.name }}</div>
					</td>
					<td class="leaderboard-table__cell leaderboard-table__cell--score">{{ rank.last_match_day_score }}</td>
					<td class="leaderboard-table__cell leaderboard-table__cell--score">
						<div class="leaderboard-table__score">
							{{ rank.total_score }}
							<app-icon *ngIf="rank.tendency < 0" name="trend-down"></app-icon>
							<app-icon *ngIf="rank.tendency == 0" name="trend-flat"></app-icon>
							<app-icon *ngIf="rank.tendency > 0" name="trend-up"></app-icon>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	`
})
export class LeaderboardTableComponent{

	@HostBinding('class') clazz = 'leaderboard-table';

	@Input() userRanks: UserRank[];
}
