import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ClientPoll, Poll, PollQuestion, PollQuestionAnswerUserStats, SportType} from '@inscouts/playersvote-models';
import {delay} from 'rxjs/operators';
import {FakeGameService, parseDemoStateString} from './fake-game.service';
import {DemoPollType} from '../../../core/core';

const lang = (locale: string, en: string, de: string, es?: string, fr?: string, ko?: string) => {
	switch (locale) {
		case 'de':
			return de;
		case 'en':
			return en;
		case 'fr':
			return fr || en;
		case 'es':
			return es || en;
		case 'ko':
			return ko || en;
	}

	return en;
};

@Injectable()
export class FakePollService {

	constructor() {
	}

	getPoll(id: string): Observable<ClientPoll> {
		const poll = this.getPollSync(id);
		return of(poll).pipe(delay(200));
	}

	getPollAnswered(pollId: number) {
		return of({
			date: null
		}).pipe(delay(200));
	}

	getPollSync(id: string): ClientPoll {
		const state = parseDemoStateString(id);

		let poll: ClientPoll;
		switch (state.pollType) {
			case DemoPollType.Single:
				poll = this.getSinglePoll(state.locale, state.sportType);
				break;
			case DemoPollType.SinglePlus:
				poll = this.getSinglePlusPoll(state.locale, state.sportType);
				break;
			case DemoPollType.Multi:
				poll = this.getMultiPoll(state.locale, state.sportType);
				break;
			case DemoPollType.MultiPlus:
				poll = this.getMultiPlusPoll(state.locale, state.sportType);
				break;
			case DemoPollType.Rating:
				poll = this.getRatingPoll(state.locale, state.sportType);
				break;
			case DemoPollType.RatingPlus:
				poll = this.getRatingPlusPoll(state.locale, state.sportType);
				break;
		}

		poll.id = id as any;
		poll.sponsor = FakeGameService.getSponsor(state.sponsor);

		let index = 1;
		for (const question of poll.questions) {
			question.id = index;
			index++;
		}

		return poll;
	}

	getSinglePoll(locale: string, sportType: SportType): ClientPoll {
		let text: string;

		switch (sportType) {
			case SportType.Soccer:
				text = lang(locale, 'Was the red card against Anthony Modeste justified?', 'War die rote Karte gegen Anthony Modeste gerechtfertigt?', '¿Fue justa la expulsión de Anthony Modeste?', 'Le carton rouge contre Anthony Modeste était-il justifié?', 'Anthony Modeste에 대한 레드카드가 정당했나요?');
				break;
			case SportType.Basketball:
				text = lang(locale, 'WAS THE GAME SUSPENSION AGAINST KYRIE IRVING JUSTIFIED?', 'WAR DIE SUSPENSION VON KYRIE IRVING GERECHTFERTIGT?', '¿Es justa la suspesión Kyrie Irving?', 'LA SUSPENSION DU MATCH POUR KYRIE IRVING ÉTAIT-ELLE JUSTIFIÉE ?', 'KYRIE IRVING에 대한 경기중단이 정당한가요?');
				break;
			case SportType.IceHockey:
				text = lang(locale, 'IS THE 2 GAMES SUSPENSION AGAINST HYMAN JUSTIFIED?', 'IST DIE 2-SPIELE-SPERRE VON HYMAN GERECHTFERTIGT?', '¿Fue justa la suspensión de dos partidos de Hyman?', 'LA SUSPENSION DE 2 MATCHS POUR HYMAN EST-ELLE JUSTIFIÉE?', 'HYMAN에 대한 2개의 경기중단이 정당한가요?');
				break;
		}


		return {
			/* style: {
				primaryColor: 'red',
			},
			email_opt_in_enabled: true,
			email_opt_in_data_use: true, */
			questions: [
				{
					text: text,
					answers: [
						{
							text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예'),
						},
						{
							text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')
						},
					]
				}
			]
		};
	}

	getSinglePlusPoll(locale: string, sportType: SportType): Poll {
		let image: string;
		let text: string;

		switch (sportType) {
			case SportType.Soccer:
				image = 'https://media.giphy.com/media/HdXLpOtieY1ZS/giphy.gif';
				text = lang(locale, 'Was this a penalty?', 'War der Elfer gerechtfertigt?', '¿Fue penalty?', 'Était-ce une pénalité?', '이것은 패널티였나요?');
				break;
			case SportType.Basketball:
				image = 'https://media.giphy.com/media/UwJ054gAqTp4Y/giphy.gif';
				text = lang(locale, 'WAS THIS AN OFFENSIVE FOUL?', 'WAR DAS EIN OFFENSIVFOUL?', '¿Fue una falta ofensiva?', 'ÉTAIT-CE UNE FAUTE OFFENSIVE?', '이것은 공격자반칙이었습니까?');
				break;
			case SportType.IceHockey:
				image = 'https://media.giphy.com/media/rghI0azQp1MTC/giphy.gif';
				text = lang(locale, 'IS THIS A BOARDING PENALTY?', 'IST DAS EINE BOARDING STRAFE?', '¿Fue un Boarding Penalty?', 'S’AGIT-IL D\'UNE PEINE D\'EMBARQUEMENT?', '이것은 보딩 패널티인가요?');
				break;
		}

		return {
			questions: [
				{
					image_url: image,
					text: text,
					answers: [
						{
							text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예'),
						},
						{
							text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')
						},
					]
				}
			]
		};
	}

	getMultiPoll(locale: string, sportType: SportType): Poll {
		const poll = this.getBaseMultiPoll(locale);

		switch (sportType) {
			case SportType.Soccer:
				poll.questions = this.getMultiPollSoccer(locale);
				break;
			case SportType.Basketball:
				poll.questions = this.getMultiPollBasketball(locale);
				break;
			case SportType.IceHockey:
				poll.questions = this.getMultiPollIceHockey(locale);
				break;
		}

		return poll;
	}

	getBaseMultiPoll(locale: string) {
		return {
			email_opt_in_enabled: true,
			email_opt_in_terms_url: 'https://inscouts.com',
			email_opt_in_text: lang(locale, 'Enter your Email address and have the chance to win the Heineken Night!', 'Gib deine E-Mail Adresse ein und sichere dir deine Chance auf einen der 10 REWE Einkaufsgutscheine!', 'Enter your Email address and have the chance to win one of 10 REWE vouchers!', 'Entrez votre adresse mail et courez la chance de gagner un week-end avec la nouvelle Kia Sportage Essence !', '이메일 주소를 입력하시고 경품에 응모하세요!'),
			name: lang(locale, 'How will we perform today?', 'Wie werden wir heute performen?', 'How will we perform today?', 'Comment allons-nous performer aujourd’hui?', '오늘의 경기를 예측해 보세요?'),
			subtitle: lang(locale, 'Play the game, and win the Heineken Night!', 'Spiel mit, und gewinne einen von 10 Rewe Einkaufsgutscheinen!', 'Play the game, and win one of 10 REWE vouchers', 'Joue le jeu, et gagne un week-end avec la nouvelle Kia Sportage Essence !', '오늘 우리는 어떤 경기를 펼치게 될까요?'),
			intro_image_url: lang(locale, 'assets/images/demo/heineken-night.jpg', 'assets/images/demo/rewe.jpg', 'assets/images/demo/rewe.jpg', 'assets/images/demo/heineken-night.jpg', 'assets/images/demo/heineken-night.jpg'),
			questions: []
		};
	}

	getMultiPollIceHockey(locale: string): PollQuestion[] {
		return [
			{
				text: lang(locale, 'Who will win?', 'Wer gewinnt?', '¿Quién Ganara?', 'Qui va gagner?', '오늘의 승자는 누구일까요?'),
				answers: [
					{text: lang(locale, 'Maple Leafs (regular)', 'Maple Leafs (regulär)', 'Maple Leafs (regular)', 'Maple Leafs (regular)', 'Maple Leafs (레귤러)')},
					{text: lang(locale, 'Rangers (regular)', 'Rangers (regulär)', 'Rangers (regular)', 'Rangers (regular)', 'Rangers (레귤러)')},
					{text: lang(locale, 'Maple Leafs (in OT)', 'Maple Leafs (in OT)', 'Maple Leafs (TE)', 'Maple Leafs (in OT)', 'Maple Leafs (시간초과)')},
					{text: lang(locale, 'Rangers (in OT)', 'Rangers (in OT)', 'Rangers (TE)', 'Rangers (in OT)', 'Rangers (시간초과)')},
					{text: lang(locale, 'Maple Leafs (in SO)', 'Maple Leafs (in SO)', 'Maple Leafs (SO)', 'Maple Leafs (in SO)', 'Maple Leafs (승부차기)')},
					{text: lang(locale, 'Rangers (in SO)', 'Rangers (in SO)', 'Rangers (SO)', 'Rangers (in SO)', 'Rangers (승부차기)')},
				]
			},
			{
				text: lang(locale, 'Will our winning streak be continued?', 'Geht unsere Siegesserie weiter?', '¿Seguiremos con nuestra racha ganadora?', 'Notre série de victoires se poursuivra-t-elle?', '우리팀의 연승행진이 계속될까요?'),
				answers: [
					{text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예')},
					{text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')},
				]
			},
			{
				text: lang(locale, 'When did Heineken celebrate its 150th anniversary?', 'Wie heißt der hauseigene Energy Drink von REWE?', '¿Cómo se llama la bebida energízante de REWE?', 'Quelle est la durée de la garantie offerte par Kia?', 'Heineken의 150주년 기념일은 언제였나요?'),
				answers:
					[
						{text: lang(locale, '2019', 'Minimal G', 'Minimal G', '2 ans', '2019')},
						{text: lang(locale, '2014', 'Maximal G', 'Maximal G', '7 ans', '2014'), correct: true},
						{text: lang(locale, '2012', 'Maximal C', 'Maximal C', '5 ans', '2012')},
					],
				is_sponsored: true,
			},
			{
				image_url: 'https://s.yimg.com/ny/api/res/1.2/XUG9rNwabPISPdVpDKQUXQ--~A/YXBwaWQ9aGlnaGxhbmRlcjtzbT0xO3c9ODAw/http://media.zenfs.com/en/homerun/feed_manager_auto_publish_494/55e74d946b403a6dcbdf96302a6127eb',
				text: lang(locale, 'WILL JOHN TAVATES MAKE AN ASSIST OR A GOAL?', 'MACHT JOHN TAVARES EINEN SCORERPUNKT (ASSIST OD. TOR)?', '¿Hara John Tavates una asistencía o gol?', 'JOHN TAVATES FERA-T-IL UNE AIDE OU UN BUT?', 'JOHN TAVATES가 어시스트를 할까요, 골을 넣을까요?'),
				answers: [
					{text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예')},
					{text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')},
				]
			},
			{
				text: lang(locale, 'WILL THERE BE A SELL-OUT CROWD TONIGHT AT THE SCOTIABANK ARENA?', 'WIRD DAS SPIEL HEUTE AUSVERKAUFT SEIN?', '¿Habra lleno total en Scotiabank Arena?', 'L’ARÉNA DE LA BANQUE SCOTIA SERA-T-ELLE COMPLET CE SOIR?', '오늘밤 SCOTIABANK 경기장의 관중석 티켓은 매진될까요?'),
				answers: [
					{text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예')},
					{text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')},
				]
			},
			{
				text: lang(locale, 'Which of the three is the longest sponsoring partnership of Heineken?', 'Welcher Social Media Kanal wird von REWE nicht genutzt?', '¿Cual de estas redes sociales no usa REWE?', 'Combien de personnes La Kia Sportage peut-elle accueillir?', '다음 중 Heineken의 가장 오랜 파트너쉽은 어디인가요?'),
				answers:
					[
						{
							text: lang(locale, 'UEFA Champions League', 'Snapchat', 'Snapchat', '5', 'UEFA Champions League'),
							correct: true
						},
						{text: lang(locale, 'Formula 1', 'Twitter', 'Twitter', '4', 'Formula 1')},
						{text: lang(locale, 'Formula E', 'Instagram', 'Instagram', '2', 'Formula E')},
					]
			},
			{
				text: lang(locale, 'WILL THERE BE ANY PENALTY SHOTS IN THE GAME?', 'GIBT ES EINEN ODER MEHRERE PENALTY SHOTS IM SPIEL?', '¿Habra penales en el partido?', 'Y AURA-T-IL DES TIRS DE PÉNALITÉ DURANT CE MATCH ?', '이 경기에서 페널티킥이 있을까요?'),
				answers: [
					{text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예')},
					{text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')},
				]
			},

		];
	}

	getMultiPollBasketball(locale: string): PollQuestion[] {
		return [
			{
				text: lang(locale, 'Who will win?', 'Wer gewinnt?', '¿Quién ganara?', 'Qui va gagner?', '오늘의 승자는 누구일까요?'),
				answers: [
					{text: lang(locale, 'Celtics (regular)', 'Celtics (regulär)', 'Celtics (regular)', 'Celtics (regular)', 'Celtics (레귤러)')},
					{text: lang(locale, 'Lakers (regular)', 'Lakers (regulär)', 'Lakers (regular)', 'Lakers (regular)', 'Lakers (레귤러)')},
					{text: lang(locale, 'Celtics (in OT)', 'Celtics (in OT)', 'Celtics (TE)', 'Celtics (in OT)', 'Celtics (in 시간초과)')},
					{text: lang(locale, 'Lakers (in OT)', 'Lakers (in OT)', 'Lakers (TE)', 'Lakers (in OT)', 'Lakers (in 시간초과)')},
				]
			},
			{
				text: lang(locale, 'Will our winning streak be continued?', 'Geht unsere Siegesserie weiter?', '¿Seguiremos con nuestra racha ganadora?', 'Notre série de victoires se poursuivra-t-elle ?', '우리팀의 연승행진이 계속될까요?'),
				answers: [
					{text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예')},
					{text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')},
				]
			},
			{
				text: lang(locale, 'When did Heineken celebrate its 150th anniversary?', 'Wie heißt der hauseigene Energy Drink von REWE?', '¿Cómo se llama la bebida energízante de REWE?', 'Quelle est la durée de la garantie offerte par Kia?', 'Heineken의 150주년 기념일은 언제였나요?'),
				answers:
					[
						{text: lang(locale, '2019', 'Minimal G', 'Minimal G', '2 ans', '2019')},
						{text: lang(locale, '2014', 'Maximal G', 'Maximal G', '7 ans', '2014'), correct: true},
						{text: lang(locale, '2012', 'Maximal C', 'Maximal C', '5 ans', '2012')},
					]
			}
			,
			{
				text: lang(locale, 'WILL THERE BY ANY GAME SUSPENSION?', 'WIRD ES EINE ODER MEHRERE GAME SUSPENSIONS GEBEN?', '¿Habra suspensión del partido?', 'Y AURA-T-IL DES SUSPENSIONS LORS DU MATCH?', '경기중단이 있을까요?'),
				answers:
					[
						{text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예')},
						{text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')},
					]
			}
			,
			{
				text: lang(locale, 'HOW MANY POINTS WILL AL HORFORD SCORE TONIGHT?', 'WIE VIELE PUNKTE MACHT AL HOFORD HEUTE?', '¿Cuantos puntos anotara Al Horford esta noche?', 'COMBIEN DE POINTS AL HORFORD MARQUERA-T-IL CE SOIR ?', 'AL HORFORD는 오늘 몇 골을 득점하게 될까요?'),
				answers:
					[
						{text: '< 10'},
						{text: '11-20'},
						{text: '21-30'},
						{text: '30+'},
					]
			}
			,
			{
				text: lang(locale, 'Which of the three is the longest sponsoring partnership of Heineken?', 'Welcher Social Media Kanal wird von REWE nicht genutzt?', '¿Cual de estas redes sociales no usa REWE?', 'Combien de personnes La Kia Sportage peut-elle accueillir?', '다음 중 Heineken의 가장 오랜 파트너쉽은 어디인가요?'),
				answers:
					[
						{
							text: lang(locale, 'UEFA Champions League', 'Snapchat', 'Snapchat', '5', 'UEFA Champions League'),
							correct: true
						},
						{text: lang(locale, 'Formula 1', 'Twitter', 'Twitter', '4', 'Formula 1')},
						{text: lang(locale, 'Formula E', 'Instagram', 'Instagram', '2', 'Formula E')},
					]
			}
			,
			{
				text: lang(locale, 'WILL THERE BE A SELL-OUT CROWD TONIGHT AT TD GARDEN?', 'WIRD DAS SPIEL HEUTE IM TD GARDEN AUSVERKAUFT SEIN?', '¿Habra lleno total en el TD Garden?', 'LE TD GARDEN SERA-T-IL COMPLET CE SOIR?', '오늘밤 TD GARDEN 경기장의 관중석 티켓은 매진될까요?'),
				answers:
					[
						{text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예')},
						{text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')},
					]
			}
			,
		]
			;
	}

	getMultiPollSoccer(locale: string): PollQuestion[] {
		return [
			{
				text: lang(locale, 'Who will win?', 'Wer gewinnt?', '¿Quién ganara?', 'Qui va gagner?', '오늘의 승자는 누구일까요?'),
				answers: [
					{text: '1. FC Köln'},
					{text: 'Vfl Wolfsburg'},
					{text: lang(locale, 'Draw', 'Unentschieden', 'Empate', 'Draw', '무승부')}
				]
			},
			{
				text: lang(locale, 'Will our winning streak be continued?', 'Geht unsere Siegesserie weiter?', '¿Seguiremos con nuestra racha ganadora?', 'Notre série de victoires se poursuivra-t-elle?', '우리팀의 연승행진이 계속될까요?'),
				answers: [
					{text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예')},
					{text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')},
				]
			},
			{
				text: lang(locale, 'When did Heineken celebrate its 150th anniversary?', 'Wie heißt der hauseigene Energy Drink von REWE?', '¿Cómo se llama la bebida energízante de REWE?', 'Quelle est la durée de la garantie offerte par Kia?', 'Heineken의 150주년 기념일은 언제였나요?'),
				answers:
					[
						{text: lang(locale, '2019', 'Minimal G', 'Minimal G', '2 ans', '2019')},
						{text: lang(locale, '2014', 'Maximal G', 'Maximal G', '7 ans', '2014'), correct: true},
						{text: lang(locale, '2012', 'Maximal C', 'Maximal C', '5 ans', '2012')},
					]
			},
			{
				image_url: 'https://mobil.express.de/image/31775752/2x1/600/300/b7df5c2a9af9497723eb8d0857a23474/Kq/louis-schaub-jubel.jpg',
				text:
					lang(locale, 'Will Louis Schaub make an assist or goal?', 'Macht Louis Schaub einen Scorepunkt (Assist od. Tor)?', '¿Louis Schaub marcara o asistira?', 'Louis Schaub fera-t-il une assistance ou un but?', 'Louis Schaub는 어시스트를 할까요, 골을 넣을까요?'),
				answers:
					[
						{text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예')},
						{text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')},
					]
			}
			,
			{
				text: lang(locale, 'How many cards will the referee show?', 'Wie viele Karten werden im Spiel vom Referee verteilt?', '¿Cuantas tarjetas sacara el arbitro?', 'Combien de cartes l\'arbitre montrera - t - il?', '심판은 몇 장의 카드를 꺼내게 될까요?'),
				answers: [
					{text: lang(locale, 'None', 'Keine', 'Ninguna', 'Aucun', '0')},
					{text: '1-2'},
					{text: '3-4'},
					{text: '5+'},
				]
			}
			,
			{
				text: lang(locale, 'Which of the three is the longest sponsoring partnership of Heineken?', 'Welcher Social Media Kanal wird von REWE nicht genutzt?', '¿Cual de estas redes sociales no usa REWE?', 'Combien de personnes La Kia Sportage peut-elle accueillir?', '다음 중 Heineken의 가장 오랜 파트너쉽은 어디인가요?'),
				answers:
					[
						{
							text: lang(locale, 'UEFA Champions League', 'Snapchat', 'Snapchat', '5', 'UEFA Champions League'),
							correct: true
						},
						{text: lang(locale, 'Formula 1', 'Twitter', 'Twitter', '4', 'Formula 1')},
						{text: lang(locale, 'Formula E', 'Instagram', 'Instagram', '2', 'Formula E')},
					]
			},
			{
				text: lang(locale, 'Will there be any penalty shots in the game?', 'Gibt es einen oder mehrere Elfmeter im Spiel?', '¿Habra penales en el partido?', 'Y aura-t-il des tirs de pénalité durant le match?', '이 경기에서 페널티킥이 있을까요?'),
				answers: [
					{text: lang(locale, 'Yes', 'Ja', 'Si', 'Oui', '\'예')},
					{text: lang(locale, 'No', 'Nein', 'No', 'Non', '\'아니오')},
				]
			},
		];
	}

	getMultiPlusPoll(locale: string, sportType: SportType) {
		const multi = this.getMultiPoll(locale, sportType);
		multi.question_countdown_time = 10;

		return multi;
	}

	getRatingPoll(locale: string, sportType: SportType): Poll {
		let text: string;

		switch (sportType) {
			case SportType.Soccer:
				text = lang(locale, 'Rate the performance of Jonas Hector against the VfL Wolfsburg!', 'Bewerte die Leistung von Jonas Hector im Spiel gegen den VfL Wolfsburg!', 'Califica el partido de Jonas Hector contra VFL Wolfsburg', 'Évalue la performance de Jonas Hector contre le VfL Wolfsburg !', ' VFL Wolfsburg과의 경기에서 Jonas Hector가 보여준 활약에 대해 평가해 주세요!');
				break;
			case SportType.Basketball:
				text = lang(locale, 'RATE KYRIE IRVING\'S PERFORMANCE VS. THE LAKERS!', 'BEWERTE DIE LEISTUNG VON KYRIE IRVING IM SPIEL GEGEN DIE LAKERS!', 'Califica el partido de Kyrie Irving vs Lakers', 'ÉVALUE LA PERFORMANCE DE KYRIE IRVING CONTRE LES LAKERS !', 'THE LAKERS와의 경기에서 KYRIE IRVING이 보여준 활약에 대해 평가해 주세요!');
				break;
			case SportType.IceHockey:
				text = lang(locale, 'RATE JOHN TAVARES\' PERFORMANCE VS. THE NY RANGERS!', 'BEWERTE DIE LEISTUNG VON JOHN TAVARES IM SPIEL GEGEN DIE NY RANGERS!', '¡Califica el partido de John Tavares vs NY Rangers!', 'ÉVALUE LA PERFORMANCE DE JOHN TAVARES VS. CELLE DES RANGERS !', 'THE NY RANGERS와의 경기에서 JOHN TAVARES가 보여준 활약에 대해 평가해 주세요!');
				break;
		}


		return {
			questions: [
				{
					text: text,
					use_rating_scale: true,
					show_answers_as_buttons: true,
					answers: [
						{text: '1'},
						{text: '2'},
						{text: '3'},
						{text: '4'},
						{text: '5'},
						{text: '6'},
					]
				}
			]
		};
	}

	getRatingPlusPoll(locale: string, sportType: SportType): Poll {
		let video: string;
		let image: string;
		let text: string;

		switch (sportType) {
			case SportType.Soccer:
				video = 'https://www.youtube.com/watch?v=lsb5FhXlL6c';
				text = lang(locale, 'Rate this goal!', 'Bewerte dieses Tor!', '¡Califica este gol!', 'Note ce but !', '이 골을 평가해 주세요!');
				break;
			case SportType.Basketball:
				image = 'https://media.giphy.com/media/3oEdv9kR4Jsl05gS4w/giphy.gif';
				text = lang(locale, 'RATE THIS LAYUP!', 'BEWERTE DIESEN LAYUP!', 'Califica este Layup', 'NOTE CE LAYUP !', '이 레이업을 평가해 주세요!');
				break;
			case SportType.IceHockey:
				image = 'https://media.giphy.com/media/3ohzdNgTbS0GEIagEw/giphy.gif';
				text = lang(locale, 'RATE THIS FAN REACTION!', 'BEWERTE DIES FAN-REAKTION!', '¡Califica la reacción de este hincha!', 'ÉVALUE LA RÉACTION DE CE FAN !', '이 팬 리액션을 평가해 주세요!');
				break;
		}

		return {
			questions: [
				{
					image_url: image ? image : null,
					video_url: video ? video : null,
					text: text,
					use_rating_scale: true,
					show_answers_as_buttons: true,
					answers: [
						{text: '1'},
						{text: '2'},
						{text: '3'},
						{text: '4'},
						{text: '5'},
						{text: '6'},
					]
				}
			]
		};
	}

	submitAnswer(pollId: string, questionId: number, answerIndex: number): Observable<PollQuestionAnswerUserStats[]> {
		const poll: Poll = this.getPollSync(pollId);
		const question = poll.questions.find(q => q.id === questionId);


		let values = [32, 24, 64, 78, 11, 19];
		values = values.slice(0, question.answers.length);

		let percent = FakeGameService.createPercentValues(values);

		percent = percent.map(value => {
			value.percentage = Math.round(value.percentage);
			return value;
		});


		return of(percent).pipe(delay(500));
	}

	updateUser(email: string) {
		return of({}).pipe(delay(1000));
	}
}


