import {Routes} from '@angular/router';
import {RoundPredictionComponent} from './webviews/round-prediction.component';
import {VotingComponent} from './webviews/voting.component';
import {GamePollComponent} from './game-polls/game-poll.component';
import {WidgetComponent} from './widget/widget.component';
import {WebviewsComponent} from './webviews/webviews.component';
import {MobileWidgetComponent} from './mobile-widget/mobile-widget.component';
import {PollRootComponent} from './polls/poll-root.component';
import {LeaderboardRootComponent} from './leaderboards/leaderboard-root.component';
import {BattleRootComponent} from './battles/battle-root.component';
import {BattleOverlayComponent} from './battles/battle-overlay.component'

export const routes: Routes = [
	{ path: 'widget/:source/:gameId', component: WidgetComponent },
	{ path: 'mobile/:source/:gameId', component: MobileWidgetComponent },
	{ path: 'polls/:id', component: PollRootComponent },
	{ path: 'battles/:battleId', component: BattleRootComponent },
	{ path: 'battles/:battleId/polls/:pollId', component: BattleOverlayComponent },
	{ path: 'leaderboard/:competitionId', component: LeaderboardRootComponent },
	{ path: 'leaderboard/group/:groupId', component: LeaderboardRootComponent },
	{
		path: 'webviews',
		component: WebviewsComponent,
		children: [
			{path: 'rating/:source/:id', component: VotingComponent},
			{path: 'prediction/:source', component: RoundPredictionComponent},
			{path: 'poll/:source/:id', component: GamePollComponent}
		]
	},

];
