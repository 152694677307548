import {animate, state, style, transition, trigger} from '@angular/animations';

export class AnimationUtils {

	static fadeIn() {
		return trigger('fadeIn', [
			state('shown', style({opacity: 1})),
			state('hidden', style({opacity: 0})),
			transition('hidden <=> shown', [
				animate('0.2s ease')
			]),
			transition('void => shown', [
				style({opacity: 0}),
				animate('300ms 300ms')
			]),
		])
	}

}
