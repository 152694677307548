import {Component, Input, OnInit} from '@angular/core';
import {GameUtils} from '../core/game-utils';
import {Game, GameSource} from '@inscouts/playersvote-models';
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-game-header',
	template: `
		<app-header [title]="title" [subtitle]="game.start | date: 'EEEE, dd. MMMM YYYY, HH:mm' : '' :  translateService.currentLang ">
			<ng-content></ng-content>
		</app-header>
	`
})
export class GameHeaderComponent implements OnInit {

	@Input() game: Game;
	@Input() gameSource: GameSource;

	constructor(
		private gameUtils: GameUtils,
		public translateService: TranslateService,
	) {
	}

	ngOnInit() {
	}

	get title(): string {
		return this.gameUtils.getLeagueAndRoundText(this.game, this.gameSource.key);
	}
}
