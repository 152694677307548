<div class="app-poll__header">
	<div class="app-poll__header-title">{{ 'new-polls.finish-thanks' | translate }}</div>
</div>

<form [formGroup]="emailForm">
	<div class="app-poll-box app-poll__email-box">
		<ng-template [ngIf]="!emailSuccess">
			<h2 class="app-poll__email-title">{{ poll.email_opt_in_text }}</h2>
			<mat-form-field>
				<mat-label>{{ 'new-polls.email' | translate }}</mat-label>
				<input matInput type="email" required formControlName="email">
			</mat-form-field>
			<mat-form-field *ngIf="poll.email_opt_in_phone">
				<mat-label>{{ 'new-polls.phone' | translate }}</mat-label>
				<input matInput type="text" required formControlName="phone">
			</mat-form-field>
			<div>
				<mat-checkbox formControlName="terms">
						<span
							[innerHTML]="'new-polls.accept-terms' | translate: { link: poll.email_opt_in_terms_url }"></span>
				</mat-checkbox>
				<mat-error *ngIf="emailForm.controls.terms.invalid && emailForm.controls.terms.touched">
					{{ 'new-polls.accept-terms-error' | translate }}
				</mat-error>
				<mat-checkbox style="display:block" *ngIf="poll.email_opt_in_data_use" formControlName="allowed_data_use">{{ 'new-polls.allow-data-use' | translate }}</mat-checkbox>
				<mat-checkbox style="display:block" *ngIf="poll.email_opt_in_newsletter" formControlName="subscribed_newsletter">{{ 'new-polls.subscribe-to-newsletter' | translate }}</mat-checkbox>
			</div>
		</ng-template>

		<ng-template [ngIf]="emailSuccess">
			<p style="margin: 32px 0;">
				{{ 'new-polls.email-success' | translate }}
			</p>
		</ng-template>
	</div>
</form>
