
import {throwError as observableThrowError, Observable, of, Subject} from 'rxjs';

import {catchError, switchMap, map, take} from 'rxjs/operators';
import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';


import {ServiceError, ServiceErrorCode} from '../core/service-error';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {State} from '../core/state';

declare let window: any;

@Injectable()
export class ApiService {

	static onUnauthorized: EventEmitter<String> = new EventEmitter<String>();
	static tooManyRequests = new Subject<void>();

	private baseUrl: string;

	constructor(private httpClient: HttpClient, private state: State) {
		this.baseUrl = environment.apiUrl;
	}

	get<T>(url: string, data?: any) {
		let params = new HttpParams();
		if (data) {
			for (const key of Object.keys(data)) {
				params = params.append(key, data[key]);
			}
		}

		return this.getDefaultHttpOptions(params).pipe(switchMap(options => {
			return this.httpClient.get<T>(this.constructApiUrl(url), options).pipe(
				catchError(this.handleError));
		}));
	}

	post<T>(url: string, data: any, params?: {[key: string]: string|number}): Observable<any> {
		return this.getDefaultHttpOptions(this.makeHttpParams(params)).pipe(switchMap(options => {
			return this.httpClient.post<T>(this.constructApiUrl(url), data, options);
		}));
	}

	postUnauthenticated<T>(url: string, data: any) {
		return this.getDefaultHttpOptions(null, false).pipe(switchMap(options => {
			return this.httpClient.post<T>(this.constructApiUrl(url), data, options);
		}));
	}

	put<T>(url: string, data: any): Observable<any> {
		return this.getDefaultHttpOptions().pipe(switchMap(options => {
			return this.httpClient.put<T>(this.constructApiUrl(url), data, options);
		}));
	}

	patch<T>(url: string, data: any): Observable<any> {
		return this.getDefaultHttpOptions().pipe(switchMap(options => {
			return this.httpClient.patch<T>(this.constructApiUrl(url), data, options);
		}));
	}

	delete<T>(url: string): Observable<any> {
		return this.getDefaultHttpOptions().pipe(switchMap(options => {
			return this.httpClient.delete<T>(this.constructApiUrl(url), options);
		}));
	}


	getDefaultHttpOptions(params?: HttpParams, authenticated = true) {
		if (authenticated) {
			return this.state.token$.pipe(take(1), map(token => {
				let headers = new HttpHeaders();
				headers = headers.append('Authorization', `Bearer ${token}`);

				return {
					headers,
					params
				};
			}));
		}

		return of({
			params
		});
	}

	private makeHttpParams(data) {
		let params = new HttpParams();
		if (data) {
			for (const key of Object.keys(data)) {
				params = params.append(key, data[key]);
			}
		}

		return params;
	}

	private handleError(error: any) {
		let body = null;
		try {
			body = JSON.parse(error._body);
		} catch (error) {
		}

		let errCode = error.status;

		switch (error.status) {
			case 400:
				errCode = ServiceErrorCode.ValidationError;
				break;
			case 401:
				errCode = ServiceErrorCode.Unauthorized;
				ApiService.onUnauthorized.emit();
				break;
			case 403:
				errCode = ServiceErrorCode.Forbidden;
				break;
			case 404:
				errCode = ServiceErrorCode.NotFound;
				break;
			case 429:
				errCode = ServiceErrorCode.TooManyRequests;
				ApiService.tooManyRequests.next();
				break;
			case 503:
				errCode = ServiceErrorCode.ServiceUnavailable;
				break;
			default:
				errCode = ServiceErrorCode.Unexpected;
				break;
		}

		return observableThrowError({
			code: errCode,
			message: body ? body.message : null,
			data: body ? body.errors : null
		} as ServiceError);

	}

	constructApiUrl(url: string) {
		return this.baseUrl + url;
	}

	getRestEntityPath(path: string, id: string) {
		return `${path}/${id}`;
	}

}

export interface AuthData {
	api_key: string;
	external_user_id?: string;
	external_verify_token?: string;
	facebook_id?: string;
}

export interface Paging {
	start?: number;
	limit?:number;
}
