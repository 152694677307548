import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Battle} from '@inscouts/playersvote-models';
import {ApiService} from './api.service';

@Injectable()
export class BattleService {

	constructor(private apiService: ApiService) {
	}

	getBattle(battleId: number): Observable<Battle> {
		return this.apiService.get(`/bot/battles/${battleId}`);
	}

}
