import {AfterViewInit, Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {LeaderboardService} from '../services/leaderboard.service';
import {State} from '../core/state';
import {AuthService} from '../services/auth.service';
import { UserRank, Group, PredictionOrigin, } from '@inscouts/playersvote-models';
import { SnackBarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

declare const window: any;

@Component({
	selector: 'app-leaderboard-root',
	template: `
		<div class="app-root-height-container">
			<div *ngIf="!(state.authError$ | async)" class="app-leaderboard  app-root-box">
				<leaderboard-group *ngIf="enableCustomGroups"
					(groupJoined)="onGroupJoin($event)"
					(groupLeft)="onGroupLeave($event)"
					(setGroup)="setGroup($event)"
					(removedMember)="onRemovedMember()"
					[group]="group"
					[groups]="groups"
				></leaderboard-group>
				<leaderboard-table *ngIf="userRanks && !loading" [userRanks]="userRanks"></leaderboard-table>
				<mat-spinner *ngIf="loading" class="app-leaderboard-root__spinner"></mat-spinner>
			</div>
			<div *ngIf="state.authError$ | async" >Error</div>
		</div>

		<app-unauthenticated *ngIf="state.authError$ | async"></app-unauthenticated>
	`
})
export class LeaderboardRootComponent implements OnInit, AfterViewInit {

	@HostBinding('class') clazz = 'app-leaderboard-root';

	loading = false;
	competitionId: string;
	userRanks: UserRank[];
	enableCustomGroups = false;
	groups: Group[];
	group: Group;

	constructor(
		private route: ActivatedRoute,
		public state: State,
		private authService: AuthService,
		private translate: TranslateService,
		private snackbar: SnackBarService,
		private leaderboardService: LeaderboardService,
	) {
		this.state.origin = PredictionOrigin.WIDGET;
		this.authService.initToken(this.state.apiKey);
		this.route.queryParams.subscribe(queryParams => {
			this.enableCustomGroups = queryParams.custom_groups === 'true';
		})
	}

	ngOnInit() {
		this.route.params
			.pipe(first())
			.subscribe(params => {
				this.competitionId = params.competitionId
				if(params.groupId && this.enableCustomGroups) {
					this.loadGroup(params.groupId);
				} else {
					this.loadRanking();
				}
			});
		if (this.enableCustomGroups && !!this.state.externalUserId) {
			this.loadCustomGroups();
		}
	}

	ngAfterViewInit() {
		const interval = setInterval(() => {
			if (window.parentIFrame) {
				window.parentIFrame.sendMessage('resize');
				clearInterval(interval);
			}
		}, 300);
	}

	loadRanking(groupKey?: string) {
		if (this.competitionId || groupKey) {
			this.loading = true;

			const ranksObserve = (this.enableCustomGroups && typeof groupKey === 'string')
				? this.leaderboardService.getGroupRankings(groupKey)
				: this.leaderboardService.getUserRanks(this.competitionId);
			ranksObserve.subscribe(ranks => {
					this.userRanks = ranks;
					this.loading = false;
				}, () => {
					this.loading = false;
					this.userRanks = [];
					this.snackbar.error(this.translate.instant('leaderboards.ranking-load-error'));
				});
			}
	}

	// loads groups the current user is already member of
	loadCustomGroups() {
		this.leaderboardService.getCustomGroups().subscribe(groups => {
			this.groups = groups;
		})
	}

	// loads a group the current user is invited to
	loadGroup(groupKey: string) {
		this.leaderboardService.getCustomGroup(groupKey).subscribe(group => {
			this.setGroup(group);
		})
	}

	onGroupJoin(group: Group) {
		if(this.groups) {
			this.groups = [...this.groups, group];
		} else {
			this.groups = [group];
		}
		this.setGroup(group)
	}

	onGroupLeave(group: Group) {
		this.groups = this.groups.filter(({key}) => key !== group.key)
		this.setGroup(null)
	}

	onRemovedMember(removedUserId: number) {
		this.userRanks = this.userRanks.filter(({user_id}) => user_id !== removedUserId)
	}

	setGroup(group: Group) {
		this.group = group;
		this.loadRanking(group ? group.key : null);
	}
}
